import React, { useCallback } from 'react';
import { ResizablePanelGroup, ResizablePanel, ResizableHandle } from '../ui/resizable';
import { EmailDetail } from '../submissions/EmailDetail';
import { X } from 'lucide-react';

interface EmailPreviewSliderProps {
  isOpen: boolean;
  onClose: () => void;
  emailHtml: string | null;
  highlightRange?: { start: number; end: number } | null;
  highlightRanges?: Array<{ start: number; end: number }> | null;
  headers?: Record<string, string>;
  isLoading: boolean;
}

export const EmailPreviewSlider: React.FC<EmailPreviewSliderProps> = ({
  isOpen,
  onClose,
  emailHtml,
  highlightRange,
  highlightRanges,
  headers,
  isLoading = false,
}) => {
  const handleOverlayClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  }, [onClose]);

  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 z-50 bg-black bg-opacity-30"
      onClick={handleOverlayClick}
    >
      {isLoading ? (
        <div className="flex items-center justify-center h-full">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-white" />
        </div>
      ) : (
        <div className="absolute inset-y-0 right-0 w-full flex justify-end">
          <ResizablePanelGroup direction="horizontal" className="w-[800px]">
            <ResizablePanel
              className="bg-transparent cursor-pointer"
              collapsible={false}
              minSize={25}
              onClick={onClose}
            />
            <ResizableHandle withHandle />
            <ResizablePanel className="bg-white shadow-xl" minSize={40.5}>
              <div className="relative h-full w-full">
                <button
                  onClick={onClose}
                  className="absolute top-4 right-4 z-10 text-black hover:text-gray-700 focus:outline-none transition-colors bg-white/20 backdrop-blur-sm rounded-full p-1.5"
                  aria-label="Close email preview"
                >
                  <X className="h-5 w-5" />
                </button>
                {emailHtml ? (
                  <EmailDetail
                    emailBody={emailHtml}
                    highlightRanges={highlightRanges ?? (highlightRange ? [highlightRange] : [])}
                    isEmbedded={true}
                    emailComponentTitle={headers?.["subject"] || "Email Content"}
                    emailHeaders={headers}
                  />
                ) : (
                  <div className="p-4">No email content available.</div>
                )}
              </div>
            </ResizablePanel>
          </ResizablePanelGroup>
        </div>
      )}
    </div>
  );
};
