import { useState } from "react";
import { useNodeApi } from "../../../hooks/useNodeApi";
import { GenericTable } from "./GenericTable";
import { Citation, TableResponse } from "../../../types/table";
import { toast } from "react-hot-toast";
import {
  WithLoggedInAuthInfoProps,
  withRequiredAuthInfo,
} from "@propelauth/react";

interface TableContainerBaseProps {
  tableData: TableResponse;
  className?: string;
  filteredRows?: number[];
  isSimplifiedView?: boolean;
}

type TableContainerProps = TableContainerBaseProps & WithLoggedInAuthInfoProps;

export const TableContainer = withRequiredAuthInfo(
  ({
    accessToken,
    tableData,
    className,
    filteredRows,
    isSimplifiedView = false,
  }: TableContainerProps) => {
    const [currentPdfUrl, setCurrentPdfUrl] = useState<string | null>(null);
    const [currentCitationDocument, setCurrentCitationDocument] = useState<
      string | null
    >(null);
    const { getPdfUrl } = useNodeApi(accessToken);

    const handleCitationClick = async (
      citations: Citation[],
      citationIndex = 0
    ) => {
      if (!citations || citations.length === 0) return;

      const citation = citations[citationIndex];
      if (!citation) return;

      if (currentCitationDocument === citation.document) {
        setCurrentPdfUrl(null);
        setCurrentCitationDocument(null);
        return;
      }

      try {
        const url = await getPdfUrl(citation.document);
        setCurrentPdfUrl(url);
        setCurrentCitationDocument(citation.document);
      } catch (error) {
        console.error("Failed to fetch PDF URL:", error);
        toast.error("Failed to load PDF preview");
      }
    };

    return (
      <div className={className}>
        <GenericTable
          headers={tableData.result.headers}
          rows={tableData.result.rows}
          onCitationClick={handleCitationClick}
          pdfUrl={currentPdfUrl}
          filteredRows={filteredRows}
          title={tableData.metadata?.title}
          metadata={{
            ...tableData.metadata,
            _id: tableData._id,
          }}
          accessToken={accessToken}
          isSimplifiedView={isSimplifiedView}
        />
      </div>
    );
  }
);
