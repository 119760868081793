import { Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import "./styles/globals.css";

import { NoMatch } from "./pages/NoMatch";
import { Playground } from "./components/playground/Playground";
import { Voice } from "./components/voice/Voice";
import { VoiceDetail } from "./components/voice/VoiceDetail";
import { Compare } from "./components/compare/Compare";
import { CompareDetail } from "./components/compare/CompareDetail";
import { FillExcel } from "./components/fillexcel/FillExcel";
import { FillExcelDetail } from "./components/fillexcel/FillExcelDetail";
import { TemplateBuild } from "./components/TemplateBuild";
import { Integrations } from "./components/integrations/Integrations";
import { Submissions } from "./components/submissions/Submissions";
import { SubmissionDetail } from "./components/submissions/SubmissionDetail";
import { UwAudit } from "./components/uwaudit/UwAudit";
import { UwAuditDetail } from "./components/uwaudit/UwAuditDetail";
import { Extract } from "./components/extract/Extract";
import { ExtractDetail } from "./components/extract/ExtractDetail";
import { WorkflowLinear } from "./components/submissions/Workflow";
// import { WorkflowV1 } from "./components/workflow/WorkflowV1";
import { WorkflowV1 } from "./components/interact-demo/InteractWorkflowV1";
import { Toaster } from "react-hot-toast";
import { enableMapSet } from "immer";
import { GuidelinesDetail } from "./components/uwaudit/GuidelinesDetail";
import { WorkflowOverview } from "./components/workflow/WorkflowOverview";
import { MainLayoutV1 } from "./components/MainLayoutV1";
import { Dashboard } from "./components/metrics/Dashboard";

import { LandingPage } from "./components/interact-demo/LandingPage";
import InteractPage from "./components/interact-demo/InteractPage";
import { Vault } from "./components/vault/Vault";
enableMapSet();

function App() {
  return (
    <>
      <Routes>
        <Route element={<MainLayoutV1 className="bg-white" />}>
          <Route
            path="/workflow-execution/:executionId"
            element={<WorkflowV1 />}
          />
        </Route>
        <Route element={<MainLayoutV1 />}>
          <Route path="/" element={<Navigate to="/home" replace />} />
          <Route path="/q-and-a" element={<Playground />} />
          <Route
            path="/audit/guidelines/:executionId"
            element={<GuidelinesDetail />}
          />
          <Route path="/audit/logs/:id" element={<UwAuditDetail />} />
          <Route path="/call" element={<Voice />} />
          <Route path="/call/logs/:id" element={<VoiceDetail />} />
          <Route path="/compare" element={<Compare />} />
          <Route path="/compare/logs/:id" element={<CompareDetail />} />
          <Route
            path="/compare/create-template"
            element={<TemplateBuild category="compare" />}
          />
          <Route path="/excel" element={<FillExcel />} />
          <Route path="/excel/logs/:id" element={<FillExcelDetail />} />
          <Route path="/integrations" element={<Integrations />} />
          <Route path="/submissions" element={<Submissions />} />
          <Route path="/submission/email/:id" element={<SubmissionDetail />} />
          <Route path="/audit" element={<UwAudit />} />
          <Route path="/extract" element={<Extract />} />
          <Route path="/extract/logs/:id" element={<ExtractDetail />} />
          <Route
            path="/extract/create-template"
            element={<TemplateBuild category="extract" />}
          />
          <Route path="/submission/workflow/:id" element={<WorkflowLinear />} />
          <Route path="/workflows/:workflowId" element={<WorkflowOverview />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/home" element={<LandingPage />} />
          <Route path="/chat" element={<InteractPage />} />
          <Route path="/vault" element={<Vault />} />
        </Route>
        <Route path="*" element={<NoMatch />} />
      </Routes>
      <Toaster position="top-right" />
    </>
  );
}

export default App;
