import { useMemo } from "react";
import { UserMetricResponse, MetricCategory } from "../../services/api";
import { MetricType } from "./Dashboard";
import { cn } from "../../lib/utils";

interface RecentUsageProps {
  metrics: UserMetricResponse | null;
  isLoading: boolean;
  error: string | null;
  selectedMetricType: MetricType;
  onMetricTypeSelect: (type: MetricType) => void;
}

export const RecentUsage = ({
  metrics,
  isLoading,
  error,
  selectedMetricType,
  onMetricTypeSelect,
}: RecentUsageProps) => {
  const metricsData = useMemo(() => {
    if (!metrics)
      return {
        extract: { count: 0, documents: 0 },
        compare: { count: 0, documents: 0 },
        workflow: { count: 0, documents: 0 },
        submissionIntake: { count: 0, documents: 0 },
        policyCheck: { count: 0, documents: 0 },
        audit: { count: 0, documents: 0 },
        uwAudit: { count: 0, documents: 0 },
        qaAudit: { count: 0, documents: 0 },
        excel: { count: 0, documents: 0 },
        sovExcel: { count: 0, documents: 0 },
        lossRunExcel: { count: 0, documents: 0 },
      };

    const extractMetrics = metrics.metrics.filter(
      (m) => m.category === MetricCategory.EXTRACT
    );
    const compareMetrics = metrics.metrics.filter(
      (m) => m.category === MetricCategory.COMPARE
    );
    const workflowMetrics = metrics.metrics.filter(
      (m) => m.category === MetricCategory.WORKFLOW
    );
    const submissionIntakeMetrics = workflowMetrics.filter(
      (m) => m.metadata?.workflow_type === "submission_intake"
    );
    const policyCheckMetrics = workflowMetrics.filter(
      (m) => m.metadata?.workflow_type === "policy_check"
    );
    const auditMetrics = metrics.metrics.filter(
      (m) => m.category === MetricCategory.AUDIT
    );
    const uwAuditMetrics = auditMetrics.filter(
      (m) => m.metadata?.audit_type === "uw_audit"
    );
    const qaAuditMetrics = auditMetrics.filter(
      (m) => m.metadata?.audit_type === "qa_audit"
    );
    const excelMetrics = metrics.metrics.filter(
      (m) => m.category === MetricCategory.EXCEL
    );
    const sovExcelMetrics = excelMetrics.filter(
      (m) => m.metadata?.excel_type === "sov"
    );
    const lossRunExcelMetrics = excelMetrics.filter(
      (m) => m.metadata?.excel_type === "loss_run"
    );

    return {
      extract: {
        count: extractMetrics.length,
        documents: extractMetrics.reduce((acc, m) => acc + m.document_count, 0),
      },
      compare: {
        count: compareMetrics.length,
        documents: compareMetrics.reduce((acc, m) => acc + m.document_count, 0),
      },
      workflow: {
        count: workflowMetrics.length,
        documents: workflowMetrics.reduce(
          (acc, m) => acc + m.document_count,
          0
        ),
      },
      submissionIntake: {
        count: submissionIntakeMetrics.length,
        documents: submissionIntakeMetrics.reduce(
          (acc, m) => acc + m.document_count,
          0
        ),
      },
      policyCheck: {
        count: policyCheckMetrics.length,
        documents: policyCheckMetrics.reduce(
          (acc, m) => acc + m.document_count,
          0
        ),
      },
      audit: {
        count: auditMetrics.length,
        documents: auditMetrics.reduce((acc, m) => acc + m.document_count, 0),
      },
      uwAudit: {
        count: uwAuditMetrics.length,
        documents: uwAuditMetrics.reduce((acc, m) => acc + m.document_count, 0),
      },
      qaAudit: {
        count: qaAuditMetrics.length,
        documents: qaAuditMetrics.reduce((acc, m) => acc + m.document_count, 0),
      },
      excel: {
        count: excelMetrics.length,
        documents: excelMetrics.reduce((acc, m) => acc + m.document_count, 0),
      },
      sovExcel: {
        count: sovExcelMetrics.length,
        documents: sovExcelMetrics.reduce(
          (acc, m) => acc + m.document_count,
          0
        ),
      },
      lossRunExcel: {
        count: lossRunExcelMetrics.length,
        documents: lossRunExcelMetrics.reduce(
          (acc, m) => acc + m.document_count,
          0
        ),
      },
    };
  }, [metrics]);

  if (isLoading) {
    return (
      <div className="space-y-8">
        {[1, 2, 3, 4].map((i) => (
          <div key={i} className="flex items-center">
            <div className="space-y-1">
              <div className="h-4 w-24 bg-gray-200 rounded animate-pulse"></div>
              <div className="h-4 w-48 bg-gray-200 rounded animate-pulse"></div>
            </div>
            <div className="ml-auto">
              <div className="h-4 w-12 bg-gray-200 rounded animate-pulse"></div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  const MetricRow = ({
    type,
    title,
    documents,
    count,
  }: {
    type: MetricType;
    title: string;
    documents: number;
    count: number;
  }) => (
    <div
      className={cn(
        "flex items-center p-2 -mx-2 rounded cursor-pointer transition-colors hover:bg-gray-100",
        selectedMetricType === type && "bg-gray-50"
      )}
      onClick={() =>
        onMetricTypeSelect(selectedMetricType === type ? "all" : type)
      }
    >
      <div className="space-y-1">
        <p
          className={cn(
            "text-sm font-medium leading-none transition-colors",
            selectedMetricType === type ? "text-primary" : "text-foreground"
          )}
        >
          {title}
        </p>
        <p className="text-sm text-muted-foreground">
          Processed {documents} document(s)
        </p>
      </div>
      <div className="ml-auto font-medium">{count}</div>
    </div>
  );

  return (
    <div className="space-y-4">
      <div>
        <div className="flex items-center justify-between mb-2">
          <span className="text-xs font-medium text-gray-500">Feature</span>
          <span className="text-xs font-medium text-gray-500">Request(s)</span>
        </div>
        <MetricRow
          type="compare"
          title="Compare"
          documents={metricsData.compare.documents}
          count={metricsData.compare.count}
        />
      </div>

      <div>
        <MetricRow
          type="extract"
          title="Extract"
          documents={metricsData.extract.documents}
          count={metricsData.extract.count}
        />
      </div>

      <div className="space-y-2">
        <MetricRow
          type="workflow"
          title="Workflow"
          documents={metricsData.workflow.documents}
          count={metricsData.workflow.count}
        />

        <div className="flex items-center pl-4 border-l-2 border-gray-200">
          <div className="space-y-1">
            <p className="text-sm font-medium leading-none text-muted-foreground">
              Submission Intake
            </p>
            <p className="text-sm text-muted-foreground">
              Processed {metricsData.submissionIntake.documents} document(s)
            </p>
          </div>
          <div className="ml-auto font-medium text-muted-foreground">
            {metricsData.submissionIntake.count}
          </div>
        </div>

        <div className="flex items-center pl-4 border-l-2 border-gray-200">
          <div className="space-y-1">
            <p className="text-sm font-medium leading-none text-muted-foreground">
              Policy Check
            </p>
            <p className="text-sm text-muted-foreground">
              Processed {metricsData.policyCheck.documents} document(s)
            </p>
          </div>
          <div className="ml-auto font-medium text-muted-foreground">
            {metricsData.policyCheck.count}
          </div>
        </div>
      </div>

      <div className="space-y-2">
        <MetricRow
          type="audit"
          title="Audit"
          documents={metricsData.audit.documents}
          count={metricsData.audit.count}
        />

        <div className="flex items-center pl-4 border-l-2 border-gray-200">
          <div className="space-y-1">
            <p className="text-sm font-medium leading-none text-muted-foreground">
              UW Audit
            </p>
            <p className="text-sm text-muted-foreground">
              Processed {metricsData.uwAudit.documents} document(s)
            </p>
          </div>
          <div className="ml-auto font-medium text-muted-foreground">
            {metricsData.uwAudit.count}
          </div>
        </div>

        <div className="flex items-center pl-4 border-l-2 border-gray-200">
          <div className="space-y-1">
            <p className="text-sm font-medium leading-none text-muted-foreground">
              UW Audit Q&A
            </p>
            <p className="text-sm text-muted-foreground">
              Processed {metricsData.qaAudit.documents} document(s)
            </p>
          </div>
          <div className="ml-auto font-medium text-muted-foreground">
            {metricsData.qaAudit.count}
          </div>
        </div>
      </div>

      <div className="space-y-2">
        <MetricRow
          type="excel"
          title="Excel"
          documents={metricsData.excel.documents}
          count={metricsData.excel.count}
        />

        <div className="flex items-center pl-4 border-l-2 border-gray-200">
          <div className="space-y-1">
            <p className="text-sm font-medium leading-none text-muted-foreground">
              SOV
            </p>
            <p className="text-sm text-muted-foreground">
              Processed {metricsData.sovExcel.documents} document(s)
            </p>
          </div>
          <div className="ml-auto font-medium text-muted-foreground">
            {metricsData.sovExcel.count}
          </div>
        </div>

        <div className="flex items-center pl-4 border-l-2 border-gray-200">
          <div className="space-y-1">
            <p className="text-sm font-medium leading-none text-muted-foreground">
              Loss Run
            </p>
            <p className="text-sm text-muted-foreground">
              Processed {metricsData.lossRunExcel.documents} document(s)
            </p>
          </div>
          <div className="ml-auto font-medium text-muted-foreground">
            {metricsData.lossRunExcel.count}
          </div>
        </div>
      </div>
    </div>
  );
};
