import { useState, useEffect, useCallback, useRef } from "react";
import { withRequiredAuthInfo } from "@propelauth/react";
import { useNodeApi } from "../../hooks/useNodeApi";
import { Card, CardContent, CardHeader, CardTitle } from "../ui/card";
import { Overview } from "./Overview";
import { RecentUsage } from "./RecentUsage";
import { UserMetricResponse, MetricCategory } from "../../services/api";
import { DatePickerWithRange } from "../ui/datepicker";
import { DateRange } from "react-day-picker";
import { isWithinInterval, parseISO } from "date-fns";
import { Interval } from "date-fns";

export type MetricType =
  | "all"
  | "compare"
  | "extract"
  | "workflow"
  | "audit"
  | "excel";

export const Dashboard = withRequiredAuthInfo(({ accessToken, user }) => {
  const { getUserMetricsHistory } = useNodeApi(accessToken);
  const [metrics, setMetrics] = useState<UserMetricResponse | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [dateRange, setDateRange] = useState<DateRange | undefined>();
  const [selectedMetricType, setSelectedMetricType] =
    useState<MetricType>("all");
  const REFRESH_INTERVAL = 30000;

  const lastResponseRef = useRef<string>("");

  const memoizedGetMetrics = useCallback(
    (category: MetricCategory, days: number) => {
      return getUserMetricsHistory(category, days);
    },
    [getUserMetricsHistory]
  );

  const fetchMetrics = useCallback(async () => {
    if (!accessToken) return;

    try {
      const [extractMetrics, compareMetrics, workflowMetrics, auditMetrics] =
        await Promise.all([
          memoizedGetMetrics(MetricCategory.EXTRACT, 30),
          memoizedGetMetrics(MetricCategory.COMPARE, 30),
          memoizedGetMetrics(MetricCategory.WORKFLOW, 30),
          memoizedGetMetrics(MetricCategory.AUDIT, 30),
        ]);

      const currentResponse = JSON.stringify([
        extractMetrics,
        compareMetrics,
        workflowMetrics,
        auditMetrics,
      ]);

      if (currentResponse !== lastResponseRef.current) {
        setIsLoading(true);
        setError(null);

        const allMetrics = [
          ...extractMetrics.metrics,
          ...compareMetrics.metrics,
          ...workflowMetrics.metrics,
          ...auditMetrics.metrics,
        ];

        const filteredMetrics = {
          metrics:
            dateRange?.from && dateRange?.to
              ? allMetrics.filter((metric) =>
                  isWithinInterval(parseISO(metric.created_at), {
                    start: dateRange.from,
                    end: dateRange.to,
                  } as Interval)
                )
              : allMetrics,
          total_documents:
            extractMetrics.total_documents +
            compareMetrics.total_documents +
            workflowMetrics.total_documents +
            auditMetrics.total_documents,
          total_requests:
            extractMetrics.total_requests +
            compareMetrics.total_requests +
            workflowMetrics.total_requests +
            auditMetrics.total_requests,
          period_start: extractMetrics.period_start,
          period_end: extractMetrics.period_end,
        };

        setMetrics(filteredMetrics);
        lastResponseRef.current = currentResponse;
      }
    } catch (err) {
      console.error("Error fetching metrics:", err);
      setError("Failed to load metrics");
    } finally {
      setIsLoading(false);
    }
  }, [accessToken, dateRange, memoizedGetMetrics]);

  const fetchMetricsRef = useRef(fetchMetrics);

  useEffect(() => {
    fetchMetricsRef.current = fetchMetrics;
  }, [fetchMetrics]);

  useEffect(() => {
    if (!accessToken) return;

    fetchMetricsRef.current();

    const intervalId = setInterval(() => {
      fetchMetricsRef.current();
    }, REFRESH_INTERVAL);

    return () => {
      clearInterval(intervalId);
    };
  }, [accessToken]);

  const userName = user?.firstName || user?.email?.split("@")[0] || "User";

  return (
    <div className="flex-grow flex flex-col h-full relative">
      <div className="max-w-6xl mx-auto px-2 py-8">
        <div className="mb-6">
          <h1 className="text-xl font-semibold">Welcome back, {userName}!</h1>
          <p className="text-sm text-muted-foreground mt-1">
            Here is your updated monthly usage data.
          </p>
        </div>

        <div className="flex justify-end mb-4 gap-4">
          <DatePickerWithRange
            value={dateRange}
            onChange={(newDateRange) => {
              if (JSON.stringify(newDateRange) !== JSON.stringify(dateRange)) {
                setDateRange(newDateRange);
              }
            }}
          />
        </div>

        <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-7">
          <Card className="col-span-4 bg-white border border-gray-200 shadow-sm">
            <CardHeader>
              <CardTitle>Overview</CardTitle>
            </CardHeader>
            <CardContent className="pl-2">
              <Overview
                metrics={metrics}
                selectedMetricType={selectedMetricType}
              />
            </CardContent>
          </Card>

          <Card className="col-span-3 bg-white border border-gray-200 shadow-sm">
            <CardHeader>
              <CardTitle>Recent Usage</CardTitle>
            </CardHeader>
            <CardContent>
              <RecentUsage
                metrics={metrics}
                isLoading={isLoading}
                error={error}
                selectedMetricType={selectedMetricType}
                onMetricTypeSelect={setSelectedMetricType}
              />
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
});
