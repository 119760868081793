import React, { useRef, useEffect, useState } from 'react';
import { X, Languages, Search, ChevronUp, ChevronDown } from 'lucide-react';
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { ResizablePanelGroup, ResizablePanel, ResizableHandle } from "./ui/resizable";
import { searchPlugin } from '@react-pdf-viewer/search';
import { cn } from "../lib/utils";

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/search/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';

interface DocumentPreviewDialogProps {
  isOpen: boolean;
  onClose: () => void;
  previewUrl: string;
  fileType: 'pdf' | 'image' | 'excel';
  originalPdfUrl?: string | null;
}

const DocumentPreviewDialog: React.FC<DocumentPreviewDialogProps> = ({
  isOpen,
  onClose,
  previewUrl,
  fileType,
  originalPdfUrl,
}) => {
  const dialogRef = useRef<HTMLDivElement>(null);
  const [showTranslation, setShowTranslation] = useState(true);
  const [showSearch, setShowSearch] = useState(false);

  const searchPluginInstance = searchPlugin();
  const { Search: SearchComponent, clearHighlights } = searchPluginInstance;

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (dialogRef.current && !dialogRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isOpen]);  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!showSearch) {
      clearHighlights();
    }
  }, [showSearch]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && showSearch) {
        setShowSearch(false);
      }
    };

    document.addEventListener('keydown', handleEscapeKey);
    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [showSearch]); // eslint-disable-line react-hooks/exhaustive-deps


  if (!isOpen) return null;

  const currentUrl = showTranslation ? previewUrl : (originalPdfUrl || previewUrl);

  const getPreviewContent = () => {
    switch (fileType) {
      case 'pdf':
        return (
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
            <div className="relative h-full w-full">
              <div className="absolute top-0 left-4 z-10 flex items-center gap-2">
                <button
                  onClick={() => setShowSearch(!showSearch)}
                  className="text-black hover:text-gray-700 focus:outline-none transition-colors bg-gray-400/90 backdrop-blur-sm rounded-full p-1.5"
                  aria-label="Toggle search"
                >
                  <Search className="h-5 w-5" />
                </button>
              </div>
              {showSearch && (
                <div className="absolute top-14 left-4 z-10">
                  <SearchComponent>
                    {(props) => (
                      <div className="rpv-search__container bg-gray-600/90 backdrop-blur-sm rounded-lg p-2">
                        <div className="flex items-center gap-2">
                          <div className="rpv-search__input relative flex-1">
                            <input
                              placeholder="Search..."
                              type="text"
                              value={props.keyword}
                              onChange={(e) => {
                                props.setKeyword(e.target.value);
                                props.search();
                              }}
                              className="rpv-search__input bg-white/30 backdrop-blur-sm rounded-lg px-3 py-1.5 text-black placeholder-gray-600 w-full"
                            />
                            {props.keyword && (
                              <div className="absolute right-2 top-1/2 -translate-y-1/2 text-xs text-gray-600">
                                {props.numberOfMatches > 0
                                  ? `${Math.min(props.currentMatch + 1, props.numberOfMatches)}/${props.numberOfMatches}`
                                  : '0/0'}
                              </div>
                            )}
                          </div>
                          <div className="h-7 w-px bg-gray-300/30" />
                          <div className="flex gap-1">
                            <button
                              className="p-1.5 bg-gray-400/90 hover:bg-white/30 backdrop-blur-sm rounded-lg transition-colors"
                              onClick={() => props.jumpToPreviousMatch()}
                              aria-label="Previous match"
                            >
                              <ChevronUp className="h-4 w-4 text-black" />
                            </button>
                            <button
                              className="p-1.5 bg-gray-400/90 hover:bg-white/30 backdrop-blur-sm rounded-lg transition-colors"
                              onClick={() => props.jumpToNextMatch()}
                              aria-label="Next match"
                            >
                              <ChevronDown className="h-4 w-4 text-black" />
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </SearchComponent>
                </div>
              )}
              <Viewer
                fileUrl={currentUrl}
                plugins={[searchPluginInstance]}
                defaultScale={SpecialZoomLevel.PageFit}
                theme="dark"
              />
            </div>
          </Worker>
        );
      case 'image':
        return (
          <img
            src={previewUrl}
            alt="Document Preview"
            className="max-w-full max-h-full object-contain"
          />
        );
      case 'excel': {
        const encodedUrl = encodeURIComponent(previewUrl);
        const viewerUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${encodedUrl}`;
        return (
          <iframe
            src={viewerUrl}
            className="w-full h-full border-0"
            title="Excel Preview"
          />
        );
      }
      default:
        return <div>Unsupported file type</div>;
    }
  };

  return (
    <div className="fixed inset-0 z-50 overflow-hidden bg-[#1a1a1a] bg-opacity-30">
      <ResizablePanelGroup direction="horizontal">
        <ResizablePanel className="bg-transparent" collapsible={false} minSize={25}>
        </ResizablePanel>
        <ResizableHandle withHandle />
        <ResizablePanel className="bg-[#1a1a1a] shadow-xl" defaultSize={40.5} minSize={40.5}>
          <div
            ref={dialogRef}
            className="relative h-full w-full"
          >
            {originalPdfUrl && fileType === 'pdf' && (
              <div className="absolute top-4 right-14 z-10">
                <button
                  onClick={() => setShowTranslation(!showTranslation)}
                  className={cn(
                    "px-3 py-1.5 text-sm font-medium rounded-full transition-all duration-200",
                    "flex items-center gap-1.5",
                    "bg-gray-400/90 backdrop-blur-sm hover:bg-white/30",
                    "text-black hover:text-black",
                    "border border-white/20",
                    "shadow-lg"
                  )}
                >
                  <span className="text-xs">
                    {showTranslation ? "Translated" : "Original"}
                  </span>
                  <Languages className="w-3 h-3" />
                </button>
              </div>
            )}
            <button
              onClick={onClose}
              className="absolute top-4 right-4 z-10 text-black hover:text-gray-700 focus:outline-none transition-colors bg-white/20 backdrop-blur-sm rounded-full p-1.5"
              aria-label="Close preview"
            >
              <X className="h-5 w-5" />
            </button>
            <div className="w-full h-full relative overflow-auto p-4">
              {getPreviewContent()}
            </div>
          </div>
        </ResizablePanel>
      </ResizablePanelGroup>
    </div>
  );
};

export default DocumentPreviewDialog;
