import { useMemo } from "react";
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
} from "recharts";
import { UserMetricResponse, MetricCategory } from "../../services/api";
import { Card } from "../ui/card";
import { MetricType } from "./Dashboard";

const categoryMap: Record<Exclude<MetricType, "all">, MetricCategory> = {
  compare: MetricCategory.COMPARE,
  extract: MetricCategory.EXTRACT,
  workflow: MetricCategory.WORKFLOW,
  audit: MetricCategory.AUDIT,
  excel: MetricCategory.EXCEL,
};

const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

interface OverviewProps {
  metrics: UserMetricResponse | null;
  selectedMetricType: MetricType;
}

export function Overview({ metrics, selectedMetricType }: OverviewProps) {
  const chartData = useMemo(() => {
    if (!metrics) return MONTHS.map((name) => ({ name, total: 0 }));

    const dataByMonth: Record<string, number> = {};

    MONTHS.forEach((month) => {
      dataByMonth[month] = 0;
    });

    const filteredMetrics = metrics.metrics.filter((metric) => {
      if (selectedMetricType === "all") return true;
      return metric.category === categoryMap[selectedMetricType];
    });

    filteredMetrics.forEach((metric) => {
      const date = new Date(metric.created_at + "Z");
      const monthKey = MONTHS[date.getMonth()];
      dataByMonth[monthKey] =
        (dataByMonth[monthKey] || 0) + metric.document_count;
    });

    return MONTHS.map((month) => ({
      name: month,
      total: dataByMonth[month],
    }));
  }, [metrics, selectedMetricType]);

  const totalDocuments = useMemo(() => {
    if (!metrics) return 0;
    if (selectedMetricType === "all") return metrics.total_documents;

    return metrics.metrics
      .filter((metric) => metric.category === categoryMap[selectedMetricType])
      .reduce((sum, metric) => sum + metric.document_count, 0);
  }, [metrics, selectedMetricType]);

  const totalRequests = useMemo(() => {
    if (!metrics) return 0;
    if (selectedMetricType === "all") return metrics.total_requests;

    return metrics.metrics.filter(
      (metric) => metric.category === categoryMap[selectedMetricType]
    ).length;
  }, [metrics, selectedMetricType]);

  return (
    <div className="space-y-4">
      <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
        <Card className="p-4">
          <div className="text-sm font-medium text-muted-foreground">
            Total Documents
          </div>
          <div className="text-2xl font-bold">{totalDocuments}</div>
        </Card>
        <Card className="p-4">
          <div className="text-sm font-medium text-muted-foreground">
            Total Requests
          </div>
          <div className="text-2xl font-bold">{totalRequests}</div>
        </Card>
      </div>

      <ResponsiveContainer width="100%" height={350}>
        <BarChart data={chartData}>
          <CartesianGrid
            horizontal={true}
            vertical={false}
            strokeDasharray="3 3"
            stroke="#E5E7EB"
          />
          <XAxis
            dataKey="name"
            stroke="#888888"
            fontSize={12}
            tickLine={false}
            axisLine={false}
          />
          <YAxis
            stroke="#888888"
            fontSize={12}
            tickLine={false}
            axisLine={false}
            tickFormatter={(value) => `${value}`}
          />
          <Bar
            dataKey="total"
            fill="currentColor"
            radius={[4, 4, 0, 0]}
            className="fill-primary"
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
