import { useState, useRef, useEffect } from "react";
import faiLogo from "../../assets/fai-logo-short.svg";
import { motion, AnimatePresence } from "framer-motion";
import {
  Upload,
  ArrowRight,
  Loader2,
  CloudUpload,
  X,
  FileText,
  Plus,
} from "lucide-react";
import { Button } from "../ui/button";
import toast from "react-hot-toast";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import sharepointimg from "../../assets/icons/sharepoint.svg";
import { Vault } from "../vault/Vault";

const PageLinkIcon = () => (
  <span
    className="inline-block relative"
    style={{
      width: "1.15em",
      height: "1.15em",
      verticalAlign: "text-bottom",
      marginRight: "3px",
    }}
  >
    <svg
      aria-hidden="true"
      role="graphics-symbol"
      viewBox="0 0 16 16"
      style={{
        width: "100%",
        height: "100%",
        display: "block",
        fill: "rgba(71, 70, 68, 0.6)",
      }}
      className="page"
    >
      <path d="M4.35645 15.4678H11.6367C13.0996 15.4678 13.8584 14.6953 13.8584 13.2256V7.02539C13.8584 6.0752 13.7354 5.6377 13.1406 5.03613L9.55176 1.38574C8.97754 0.804688 8.50586 0.667969 7.65137 0.667969H4.35645C2.89355 0.667969 2.13477 1.44043 2.13477 2.91016V13.2256C2.13477 14.7021 2.89355 15.4678 4.35645 15.4678ZM4.46582 14.1279C3.80273 14.1279 3.47461 13.7793 3.47461 13.1436V2.99219C3.47461 2.36328 3.80273 2.00781 4.46582 2.00781H7.37793V5.75391C7.37793 6.73145 7.86328 7.20312 8.83398 7.20312H12.5186V13.1436C12.5186 13.7793 12.1836 14.1279 11.5205 14.1279H4.46582ZM8.95703 6.02734C8.67676 6.02734 8.56055 5.9043 8.56055 5.62402V2.19238L12.334 6.02734H8.95703ZM10.4336 9.00098H5.42969C5.16992 9.00098 4.98535 9.19238 4.98535 9.43164C4.98535 9.67773 5.16992 9.86914 5.42969 9.86914H10.4336C10.6797 9.86914 10.8643 9.67773 10.8643 9.43164C10.8643 9.19238 10.6797 9.00098 10.4336 9.00098ZM10.4336 11.2979H5.42969C5.16992 11.2979 4.98535 11.4893 4.98535 11.7354C4.98535 11.9746 5.16992 12.1592 5.42969 12.1592H10.4336C10.6797 12.1592 10.8643 11.9746 10.8643 11.7354C10.8643 11.4893 10.6797 11.2979 10.4336 11.2979Z"></path>
    </svg>
    <svg
      aria-hidden="true"
      role="graphics-symbol"
      viewBox="0 0 13 13"
      style={{
        width: "0.65em",
        height: "0.65em",
        display: "block",
        fill: "rgba(55, 53, 47, 1)",
        position: "absolute",
        right: "-0.1em",
        bottom: "0",
        stroke: "white",
        strokeWidth: "1.5",
      }}
      className="pageLinkIndicator"
    >
      <path
        d="M6.30826 4.43292L1.76184 8.98454C1.76176 8.98462 1.76169 8.9847 1.76161 8.98477C1.76158 8.9848 1.76156 8.98482 1.76154 8.98484C1.46068 9.28584 1.25 9.6914 1.25 10.1565C1.25 10.6117 1.45865 11.0119 1.73417 11.2886C2.01014 11.5658 2.41107 11.7773 2.87078 11.7773C3.34169 11.7773 3.73758 11.5617 4.03477 11.2733L4.03482 11.2734L4.04244 11.2657L8.58864 6.72474V8.667C8.58864 9.51956 9.22729 10.2935 10.1521 10.2935C11.0528 10.2935 11.75 9.54534 11.75 8.66127V2.92671C11.75 2.48722 11.5981 2.06381 11.2838 1.74808C10.9689 1.43182 10.5446 1.27728 10.1006 1.27728H4.36028C3.46161 1.27728 2.72804 1.97749 2.72804 2.86942C2.72804 3.79734 3.51104 4.43292 4.35455 4.43292H6.30826Z"
        fill="#3E3C38"
        stroke="white"
        strokeWidth="1.5"
      ></path>
    </svg>
  </span>
);

// Interactive Vault preview component that renders the actual Vault component
const VaultPreview = ({ vaultName: _vaultName }: { vaultName: string }) => {
  const [isReady, setIsReady] = useState(false);

  // Small delay to prevent flickering on accidental hover
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsReady(true);
    }, 150);

    return () => clearTimeout(timer);
  }, []);

  if (!isReady) return null;

  return (
    <div className="absolute top-full left-1/2 -translate-x-1/2 mt-1 z-[1000] w-[450px] max-w-[90vw] h-[280px] bg-white shadow-lg rounded-lg overflow-hidden border border-gray-200">
      <div className="w-full h-full overflow-hidden relative">
        <div className="transform scale-[0.6] origin-top-left w-[846px] h-[538px] overflow-auto">
          <Vault />
        </div>
        {/* Gradient blur overlay at bottom */}
        <div className="absolute bottom-0 left-0 right-0 h-20 bg-gradient-to-t from-white to-transparent pointer-events-none"></div>
      </div>
    </div>
  );
};

const SharePointDropdownContent = ({
  onUploadFromSharePoint,
  isSharePointAvailable,
  isSharePointLoading,
  isUploading,
  onUploadFromComputer,
}: {
  onUploadFromSharePoint?: () => void;
  isSharePointAvailable?: boolean;
  isSharePointLoading?: boolean;
  isUploading?: boolean;
  onUploadFromComputer: () => void;
}) => (
  <DropdownMenuContent align="center" className="w-[240px] p-1 bg-white">
    {onUploadFromSharePoint && isSharePointAvailable && (
      <>
        <DropdownMenuItem
          onSelect={onUploadFromSharePoint}
          disabled={isSharePointLoading || isUploading}
        >
          <div className="flex items-center">
            <img
              src={sharepointimg}
              alt="SharePoint"
              className="h-4 w-4 mr-2"
            />
            <span>
              {isSharePointLoading ? "Uploading..." : "Upload from SharePoint"}
            </span>
          </div>
        </DropdownMenuItem>
        <DropdownMenuSeparator />
      </>
    )}
    <DropdownMenuItem onClick={onUploadFromComputer}>
      <div className="flex items-center">
        <CloudUpload className="h-4 w-4 mr-2" />
        <span>Upload from computer</span>
      </div>
    </DropdownMenuItem>
  </DropdownMenuContent>
);

interface DocumentInfo {
  id: string;
  name: string;
  fileType?: string;
}

interface WelcomeSectionProps {
  workflowTitle?: string;
  customPrompt?: string;
  onFileUpload: (file: File) => Promise<string | undefined>;
  existingDocuments?: DocumentInfo[];
  onDocumentClick?: (documentId: string, fileType?: string) => void;
  onDocumentRemove?: (documentId: string) => void;
  workflowStatus?: string;
  workflowId?: string;
  onResumeWorkflow?: () => Promise<void>;
  onUploadFromSharePoint?: () => void;
  isSharePointLoading?: boolean;
  isSharePointAvailable?: boolean;
  uploadedDocumentsCount?: number;
  isNoDocumentUpload?: boolean;
  onVaultClick?: () => void;
  vaultName?: string;
}

export const WelcomeSection = ({
  workflowTitle,
  customPrompt,
  onFileUpload,
  existingDocuments = [],
  onDocumentClick: _onDocumentClick,
  onDocumentRemove,
  workflowStatus,
  workflowId,
  onResumeWorkflow,
  onUploadFromSharePoint,
  isSharePointLoading = false,
  isSharePointAvailable = false,
  uploadedDocumentsCount = 0,
  isNoDocumentUpload = false,
  onVaultClick = () => {
    /* no-op */
  },
  vaultName = "Vault",
}: WelcomeSectionProps) => {
  const ASK_AI_WORKFLOW_ID = process.env.REACT_APP_ASK_AI_WORKFLOW_ID;

  const isAskAIWorkflow = workflowId === ASK_AI_WORKFLOW_ID;

  const showUpload =
    !isAskAIWorkflow &&
    (workflowStatus === "not_started" || existingDocuments.length === 0);
  const [isDragging, setIsDragging] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isResumingWorkflow, setIsResumingWorkflow] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const dragCounter = useRef(0);

  const isReadyToResume =
    uploadedDocumentsCount > 0 && workflowStatus === "not_started";

  useEffect(() => {
    if (workflowStatus !== "not_started") {
      setIsResumingWorkflow(false);
    }
  }, [workflowStatus]);

  const hasWorkflowTitle = !!workflowTitle;
  const prompt =
    customPrompt ||
    (hasWorkflowTitle
      ? `Welcome to ${workflowTitle} workflow. Upload the documents to get started`
      : "");

  const validateFile = (file: File): boolean => {
    const fileType = file.type.toLowerCase();
    const fileName = file.name.toLowerCase();
    const isValidFile =
      fileType === "application/pdf" ||
      fileType ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      fileType ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      fileType === "message/rfc822" ||
      fileType === "application/vnd.ms-outlook" ||
      fileType === "application/zip" ||
      fileType === "application/x-zip-compressed" ||
      fileType === "image/jpeg" ||
      fileType === "image/png" ||
      fileName.endsWith(".eml") ||
      fileName.endsWith(".msg") ||
      fileName.endsWith(".zip");

    if (!isValidFile) {
      toast.error(`Unsupported file type: ${file.name}`);
      return false;
    }
    return true;
  };

  const processFiles = async (files: FileList | File[]) => {
    const validFiles = Array.from(files).filter(validateFile);

    if (validFiles.length === 0) return;

    setIsUploading(true);

    for (const file of validFiles) {
      try {
        await onFileUpload(file);
      } catch (error) {
        console.error(`Failed to upload file ${file.name}:`, error);
      }
    }

    setIsUploading(false);

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragEnter = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();

    dragCounter.current += 1;

    if (!isDragging) {
      setIsDragging(true);
    }
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();

    dragCounter.current -= 1;

    if (dragCounter.current < 0) {
      dragCounter.current = 0;
    }

    if (dragCounter.current === 0) {
      setIsDragging(false);
    }
  };

  const handleDrop = async (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();

    dragCounter.current = 0;
    setIsDragging(false);

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      await processFiles(e.dataTransfer.files);
    }
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      await processFiles(e.target.files);
    }
  };

  const handleResumeWorkflow = async () => {
    if (onResumeWorkflow) {
      setIsResumingWorkflow(true);
      try {
        await onResumeWorkflow();
      } catch (error) {
        console.error("Failed to resume workflow:", error);
        toast.error("Failed to resume workflow");
        setIsResumingWorkflow(false);
      }
    }
  };

  // Get file icon based on file type - using consistent purple color for all file types
  const getFileIcon = () => {
    return (
      <div className="w-7 h-7 rounded-lg bg-purple-50 flex-shrink-0 flex items-center justify-center">
        <FileText className="w-4 h-4 text-purple-600" />
      </div>
    );
  };

  // Truncate filename if it's too long
  const truncateFilename = (filename: string, maxLength = 50) => {
    if (filename.length <= maxLength) return filename;

    const extension = filename.includes(".") ? filename.split(".").pop() : "";

    const nameWithoutExtension = extension
      ? filename.substring(0, filename.length - extension.length - 1)
      : filename;

    if (nameWithoutExtension.length <= maxLength - 5) {
      return filename;
    }

    return `${nameWithoutExtension.substring(0, maxLength - 5)}...${
      extension ? "." + extension : ""
    }`;
  };

  if (isAskAIWorkflow || !hasWorkflowTitle || !prompt) {
    return null;
  }

  return (
    <div className="space-y-3 mb-4">
      <div className="space-y-1 mb-8">
        <div className="flex flex-col">
          <div className="flex justify-start">
            <div className="min-w-[24px] min-h-[24px] w-6 h-6 flex-shrink-0 flex items-center justify-center mr-2">
              <img
                src={faiLogo}
                alt="AI"
                className="w-full h-full object-contain"
              />
            </div>
            <div className="rounded-xl max-w-[720px] px-2">
              <div className="break-words">
                {isNoDocumentUpload ? (
                  <p className="leading-normal">
                    Hey! I can help you with your questions on top of the{" "}
                    <span className="relative group inline-flex align-bottom">
                      <button
                        onClick={onVaultClick}
                        className="inline-flex items-center align-bottom font-medium text-gray-700 hover:text-gray-900 transition-colors py-0"
                      >
                        <PageLinkIcon />
                        <span className="font-medium group-hover:text-gray-900 transition-colors duration-200">
                          {vaultName}
                        </span>
                      </button>
                      <div className="hidden group-hover:block">
                        <VaultPreview vaultName={vaultName} />
                      </div>
                    </span>
                  </p>
                ) : (
                  <p>{prompt}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <AnimatePresence mode="wait">
        {isResumingWorkflow && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="flex justify-center mt-4 mb-2"
          >
            <div className="flex space-x-2 mt-2">
              <div className="h-2 w-2 bg-gray-400 rounded-full animate-pulse"></div>
              <div
                className="h-2 w-2 bg-gray-400 rounded-full animate-pulse"
                style={{ animationDelay: "300ms" }}
              ></div>
              <div
                className="h-2 w-2 bg-gray-400 rounded-full animate-pulse"
                style={{ animationDelay: "600ms" }}
              ></div>
            </div>
          </motion.div>
        )}

        {showUpload && !isResumingWorkflow && (
          <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -20, opacity: 0 }}
            className={`border-2 border-dashed rounded-lg p-6 mb-4 ${
              isDragging ? "border-blue-500 bg-blue-50" : "border-gray-300"
            }`}
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            <div className="flex flex-col items-center">
              {uploadedDocumentsCount === 0 ? (
                <>
                  <div className="mb-3">
                    <Upload className="w-7 h-7 text-gray-500 mx-auto mb-2" />
                    <p className="text-lg font-medium text-center">
                      Drag and drop documents
                    </p>
                  </div>

                  <p className="text-gray-500 text-center text-sm mb-4">
                    Supported file types: Email, Excel, Image, PDF, Word, Zip
                  </p>
                </>
              ) : (
                <div className="w-full mb-4">
                  <div className="flex flex-col gap-3 mb-4">
                    <div className="flex flex-col gap-3 w-full">
                      {existingDocuments.map((doc) => (
                        <div
                          key={doc.id}
                          className={`border border-gray-200 rounded-lg p-3 flex items-center justify-between bg-white shadow-sm transition-colors duration-150 ${
                            _onDocumentClick
                              ? "cursor-pointer hover:bg-gray-50"
                              : ""
                          }`}
                          onClick={() =>
                            _onDocumentClick &&
                            _onDocumentClick(doc.id, doc.fileType)
                          }
                        >
                          <div className="flex items-center gap-3 min-w-0 flex-1 overflow-hidden">
                            {getFileIcon()}
                            <div className="min-w-0 flex-1">
                              <p className="text-sm font-medium truncate text-gray-800">
                                {truncateFilename(doc.name)}
                              </p>
                            </div>
                          </div>
                          <div
                            className="cursor-pointer flex-shrink-0"
                            onClick={(e) => {
                              e.stopPropagation();
                              if (onDocumentRemove) {
                                onDocumentRemove(doc.id);
                              }
                            }}
                          >
                            <X className="h-4 w-4 text-gray-400 hover:text-gray-600" />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}

              <div className="relative w-full">
                <div
                  className={
                    uploadedDocumentsCount === 0
                      ? "flex justify-center items-center"
                      : "flex justify-start items-center"
                  }
                >
                  <input
                    id="file-upload"
                    type="file"
                    className="hidden"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    multiple
                    accept=".eml,.xls,.xlsx,.pdf,.docx,.zip,.msg,.jpg,.jpeg,.png"
                  />

                  {uploadedDocumentsCount === 0 ? (
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button
                          variant="default"
                          size="sm"
                          type="button"
                          disabled={isUploading || isSharePointLoading}
                        >
                          {isUploading || isSharePointLoading ? (
                            <>
                              <span className="h-4 w-4 mr-2 animate-spin rounded-full border-2 border-gray-300 border-t-white" />
                              {isSharePointLoading
                                ? "SharePoint..."
                                : "Uploading..."}
                            </>
                          ) : (
                            <>Choose files</>
                          )}
                        </Button>
                      </DropdownMenuTrigger>
                      <SharePointDropdownContent
                        onUploadFromSharePoint={onUploadFromSharePoint}
                        isSharePointAvailable={isSharePointAvailable}
                        isSharePointLoading={isSharePointLoading}
                        isUploading={isUploading}
                        onUploadFromComputer={() =>
                          document.getElementById("file-upload")?.click()
                        }
                      />
                    </DropdownMenu>
                  ) : (
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button
                          variant="outline"
                          size="sm"
                          className="h-9 px-3 text-sm font-normal border-gray-200"
                          disabled={isUploading || isSharePointLoading}
                        >
                          {isUploading || isSharePointLoading ? (
                            <>
                              <span className="h-4 w-4 mr-2 animate-spin rounded-full border-2 border-gray-300 border-t-blue-600" />
                              <span className="text-gray-900">
                                Uploading...
                              </span>
                            </>
                          ) : (
                            <>
                              <Plus className="h-4 w-4 mr-2" />
                              Add more
                            </>
                          )}
                        </Button>
                      </DropdownMenuTrigger>
                      <SharePointDropdownContent
                        onUploadFromSharePoint={onUploadFromSharePoint}
                        isSharePointAvailable={isSharePointAvailable}
                        isSharePointLoading={isSharePointLoading}
                        isUploading={isUploading}
                        onUploadFromComputer={() =>
                          document.getElementById("file-upload")?.click()
                        }
                      />
                    </DropdownMenu>
                  )}
                </div>

                {isReadyToResume && (
                  <motion.div
                    initial={{ opacity: 0, scale: 0.9 }}
                    animate={{ opacity: 1, scale: 1 }}
                    className="absolute right-0 top-0"
                  >
                    <Button
                      variant="default"
                      size="sm"
                      className="text-white rounded-full w-10 h-10 p-0 flex items-center justify-center shadow-md"
                      onClick={handleResumeWorkflow}
                      disabled={isResumingWorkflow}
                      title="Start workflow"
                    >
                      {isResumingWorkflow ? (
                        <Loader2 className="w-5 h-5 animate-spin" />
                      ) : (
                        <ArrowRight className="w-5 h-5" />
                      )}
                    </Button>
                  </motion.div>
                )}
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
