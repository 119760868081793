import React, { useState } from "react";
import { ScrollArea, ScrollBar } from "../ui/scroll-area";
import {
  FileText,
  ChevronDown,
  ChevronRight,
  PanelLeftClose,
  PanelLeftOpen,
} from "lucide-react";
import DocumentPreviewDialog from "../DocumentPreviewDialog";
import { useNodeApi } from "../../hooks/useNodeApi";
import {
  WithLoggedInAuthInfoProps,
  withRequiredAuthInfo,
} from "@propelauth/react";
import { cn } from "../../lib/utils";

interface EmailDetailProps {
  emailBody: string;
  isEmbedded?: boolean;
  emailComponentTitle?: string;
  highlightRanges?: Array<{ start: number; end: number }>;
  attachments?: Array<{ user_document_id: string; filename: string }>;
  emailHeaders?: Record<string, string>;
}

class EmailContentErrorBoundary extends React.Component<
  { children: React.ReactNode },
  { hasError: boolean }
> {
  constructor(props: { children: React.ReactNode }) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return <div className="text-red-500">Error rendering email content</div>;
    }
    return this.props.children;
  }
}

function mergeRanges(ranges: Array<{ start: number; end: number }>) {
  if (!ranges || ranges.length === 0) return [];

  // Sort by start index
  const sorted = [...ranges].sort((a, b) => a.start - b.start);

  const merged: Array<{ start: number; end: number }> = [];
  let current = sorted[0];

  for (let i = 1; i < sorted.length; i++) {
    const next = sorted[i];
    if (next.start <= current.end) {
      // Overlapping or contiguous
      current.end = Math.max(current.end, next.end);
    } else {
      merged.push(current);
      current = next;
    }
  }
  merged.push(current);

  return merged;
}

/**
 * Highlights text inside the DOM by wrapping the specified start/end offsets.
 * We assume "offset" means the 0-based index in the entire text content of the HTML.
 */
function highlightByOffsets(
  container: HTMLElement,
  ranges: Array<{ start: number; end: number }>
) {
  if (!ranges || ranges.length === 0) return;

  const mergedRanges = mergeRanges(ranges);
  let currentOffset = 0; // global text offset across all text nodes

  /**
   * We recursively traverse every node; if it's a text node, we split it if needed.
   */
  function traverseNodes(node: Node) {
    if (node.nodeType === Node.TEXT_NODE && node.nodeValue) {
      const textLength = node.nodeValue.length;
      const textEnd = currentOffset + textLength;

      // Check if any highlight range intersects [currentOffset, textEnd).
      // We'll collect all segments in this node that should be wrapped.
      const relevantRanges = mergedRanges.filter(
        (r) => r.start < textEnd && r.end > currentOffset
      );

      if (relevantRanges.length > 0) {
        // We'll create a fragment to replace the original text node.
        const fragment = document.createDocumentFragment();
        let currentNodePos = currentOffset; // track where we are in this text node
        let nodeText = node.nodeValue;

        for (let i = 0; i < relevantRanges.length; i++) {
          const range = relevantRanges[i];
          // range.start & range.end might be outside node's text range, so clamp them
          const localRangeStart = Math.max(range.start, currentNodePos);
          const localRangeEnd = Math.min(range.end, textEnd);

          // Offsets inside this text node
          const startIndex = localRangeStart - currentNodePos; // index within nodeText
          const endIndex = localRangeEnd - currentNodePos;

          // The text before the highlight range
          if (startIndex > 0) {
            const beforeText = nodeText.slice(0, startIndex);
            fragment.appendChild(document.createTextNode(beforeText));
          }

          // The highlighted text
          if (endIndex > startIndex) {
            const highlightText = nodeText.slice(startIndex, endIndex);
            const highlightSpan = document.createElement("span");
            highlightSpan.className = "email-highlight";
            highlightSpan.textContent = highlightText;
            fragment.appendChild(highlightSpan);
          }

          // Update nodeText to remove the part we've processed
          nodeText = nodeText.slice(endIndex);

          // Move currentNodePos forward
          currentNodePos += endIndex;
        }

        // If there's any text left after the last highlight range
        if (nodeText.length > 0) {
          fragment.appendChild(document.createTextNode(nodeText));
        }

        // Finally, replace the text node with our new fragment
        node.parentNode?.replaceChild(fragment, node);
        // Adjust the global offset
        currentOffset += textLength;
      } else {
        // no highlights in this text node, just move the offset
        currentOffset += textLength;
      }
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      // traverse children
      Array.from(node.childNodes).forEach(traverseNodes);
    }
  }

  traverseNodes(container);
}

const sanitizeAndRenderHTML = (
  content: string,
  highlightRanges?: Array<{ start: number; end: number }>
) => {
  if (!content) return null;

  try {
    // 1) Decode HTML entities from whatever your backend sends
    // NOTE: This is NOP now.
    const decodedContent = content;

    // 2) Create a temporary container for the HTML
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = decodedContent;

    // 3) Extract styles to inject them in a "scoped" manner
    const styleContents = Array.from(tempDiv.getElementsByTagName("style"))
      .map((style) => style.textContent)
      .filter(Boolean);

    // remove the style tags from the DOM
    tempDiv.querySelectorAll("style").forEach((el) => el.remove());

    // 4) Sanitize the container's HTML
    // NOTE: This is NOP now.
    const sanitizedContent = tempDiv.innerHTML;

    if (!sanitizedContent.trim()) {
      return null;
    }

    // 5) Strip out embedded "cid:" images or invalid data
    const processedContent = sanitizedContent
      .replace(/<img[^>]+src="cid:[^>]+>/g, "")
      .replace(/<img[^>]+src="[^"]*"[^>]*>/g, (match) => {
        return match.includes("http://") || match.includes("https://")
          ? match
          : "";
      });

    // 6) Now place the processed HTML into a container to do further manipulations
    const container = document.createElement("div");
    container.innerHTML = processedContent;

    // 7) If highlight ranges exist, apply them
    if (highlightRanges && highlightRanges.length > 0) {
      highlightByOffsets(container, highlightRanges);
    }

    // 8) Prepare "scoped" styles from the original <style> tags
    const scopedStyles = styleContents
      .map((style) =>
        style?.replace(/([^{}]*){([^{}]*)}/g, ".email-content-scope $1{$2}")
      )
      .join("\n");

    // 9) Return the final HTML
    return (
      <>
        <style>{`
          /* Base container */
          .email-content-scope {
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
            max-width: 100%;
            overflow-wrap: break-word;
            word-wrap: break-word;
            word-break: break-word;
            line-height: 1.6;
            color: #000;
            font-size: 16px;
            border: 1px solid #e0e0e0;
            border-radius: 4px;
            padding: 20px;
          }

          /* Reset potential conflicts */
          .email-content-scope * {
            font-family: sans-serif;
            word-wrap: break-word;
            word-break: break-word;
            max-width: 100%;
          }

          .email-content-scope pre,
          .email-content-scope [style*="white-space: pre"] {
            white-space: pre-wrap;
          }

          /* Force text colors */
          .email-content-scope,
          .email-content-scope p,
          .email-content-scope div,
          .email-content-scope span,
          .email-content-scope td,
          .email-content-scope li {
            color: #000 !important;
          }

          /* Headings */
          .email-content-scope h1 {
            font-size: 2em;
            margin: 0.67em 0;
            font-weight: 600;
          }
          .email-content-scope h2 {
            font-size: 1.5em;
            margin: 0.83em 0;
            font-weight: 600;
          }
          .email-content-scope h3 {
            font-size: 1.17em;
            margin: 1em 0;
            font-weight: 600;
          }

          /* Lists */
          .email-content-scope ul,
          .email-content-scope ol {
            padding-left: 2em;
            margin: 0.5em 0;
          }
          .email-content-scope ul {
            list-style-type: disc;
          }
          .email-content-scope ul ul {
            list-style-type: circle;
          }

          /* Links */
          .email-content-scope a {
            color: #0366d6 !important;
            text-decoration: none;
          }
          .email-content-scope a:hover {
            text-decoration: underline;
          }

          /* Blockquotes */
          .email-content-scope blockquote {
            margin: 1em 0;
            padding-left: 1em;
            color: #6a737d;
            border-left: 0.25em solid #dfe2e5;
          }

          /* Force minimum text contrast */
          .email-content-scope [style*="color: #fff"],
          .email-content-scope [style*="color: white"],
          .email-content-scope [style*="color: rgb(255, 255, 255)"] {
            color: #000 !important;
          }

          /* Highlight style */
          .email-content-scope .email-highlight {
            background-color: rgba(0, 255, 0, 0.3);
            padding: 2px 0;
            border-radius: 2px;
          }

          /* Inline scoped styles from the email */
          ${scopedStyles}
        `}</style>
        <div className="email-content-scope">
          {/* Render the final HTML (with highlights) */}
          <div dangerouslySetInnerHTML={{ __html: container.innerHTML }} />
        </div>
      </>
    );
  } catch (error) {
    console.error("HTML sanitization failed:", error);
    return <div className="text-red-500">Error rendering content</div>;
  }
};

const EmailDetailComponent = ({
  emailBody,
  isEmbedded = false,
  emailComponentTitle = "Email Content",
  highlightRanges,
  attachments,
  emailHeaders: headers,
  accessToken,
}: WithLoggedInAuthInfoProps & EmailDetailProps) => {
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [previewUrl, setPreviewUrl] = useState("");
  const [previewFileType, setPreviewFileType] = useState("");
  const [isHeaderExpanded, setIsHeaderExpanded] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const { getPdfUrl } = useNodeApi(accessToken);

  const handleAttachmentClick = async (
    attachmentId: string,
    filename: string
  ) => {
    if (attachmentId) {
      try {
        const fileExtension = filename.split(".").pop()?.toLowerCase() || "";

        if (["pdf"].includes(fileExtension)) {
          const url = await getPdfUrl(attachmentId);
          setPreviewFileType("pdf");
          setPreviewUrl(url);
          setIsPreviewOpen(true);
        } else if (
          ["jpg", "jpeg", "png", "gif", "bmp", "webp"].includes(fileExtension)
        ) {
          const url = await getPdfUrl(attachmentId); // test for bmp and webp
          setPreviewFileType("image");
          setPreviewUrl(url);
          setIsPreviewOpen(true);
        } else if (["xlsx"].includes(fileExtension)) {
          const url = await getPdfUrl(attachmentId); // Test for xls and csv once
          setPreviewFileType("excel");
          setPreviewUrl(url);
          setIsPreviewOpen(true);
        } else {
          const url = await getPdfUrl(attachmentId);
          const link = document.createElement("a");
          link.href = url;
          link.download = filename;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (error) {
        console.error("Failed to handle attachment:", error);
      }
    }
  };

  return (
    <div
      className={`flex-1 overflow-auto ${
        isEmbedded ? "h-full" : "min-h-screen"
      } bg-white`}
    >
      <div className="mx-auto">
        <div
          className={cn(
            "bg-white h-full",
            isFullScreen
              ? "fixed inset-0 z-50 p-6 overflow-y-auto"
              : "overflow-y-auto"
          )}
        >
          <div className="">
            <div className="flex items-center text-md pt-8 px-8">
              <button
                onClick={() => setIsFullScreen(!isFullScreen)}
                className="p-1 hover:bg-gray-100 transition-colors duration-200 mr-2"
                title={isFullScreen ? "Collapse view" : "Expand view"}
              >
                {isFullScreen ? (
                  <PanelLeftOpen className="h-5 w-5 text-black" />
                ) : (
                  <PanelLeftClose className="h-5 w-5 text-black" />
                )}
              </button>
              <div className="h-5 w-px bg-gray-500/60 mr-2" />
              <h3 className="text-lg font-semibold mb-4 pt-4">
                {headers?.["subject"] || emailComponentTitle}
              </h3>
            </div>
            <div className="px-8 mb-4 text-sm text-gray-800">
              {headers?.["from"] && (
                <div className="mb-1">
                  <div className="flex items-center -mx-2 px-2">
                    <span className="font-medium">From: </span>
                    <span className="ml-1">{headers["from"]}</span>
                    <div
                      className="cursor-pointer hover:bg-gray-100 rounded p-1 ml-1"
                      onClick={() => setIsHeaderExpanded(!isHeaderExpanded)}
                    >
                      {isHeaderExpanded ? (
                        <ChevronDown className="h-4 w-4 text-gray-500" />
                      ) : (
                        <ChevronRight className="h-4 w-4 text-gray-500" />
                      )}
                    </div>
                  </div>
                </div>
              )}
              {isHeaderExpanded && (
                <>
                  {headers?.["to"] && (
                    <div className="mb-1">
                      <span className="font-medium">To: </span>
                      <span className="ml-1">{headers["to"]}</span>
                    </div>
                  )}
                  {headers?.["cc"] && (
                    <div className="mb-1">
                      <span className="font-medium">Cc: </span>
                      <span className="ml-1">{headers["cc"]}</span>
                    </div>
                  )}
                  {headers?.["date"] && (
                    <div>
                      <span className="font-medium">Date: </span>
                      <span className="ml-1">
                        {headers["date"]
                          ? new Date(headers["date"]).toLocaleString("en-US", {
                              weekday: "short",
                              month: "short",
                              day: "numeric",
                              year: "numeric",
                              hour: "numeric",
                              minute: "2-digit",
                              hour12: true,
                            })
                          : ""}
                      </span>
                    </div>
                  )}
                </>
              )}
            </div>
            {attachments && attachments.length > 0 && (
              <div className="mb-6 px-8">
                <ScrollArea className="w-full">
                  <div className="flex gap-4 py-2">
                    {attachments
                      .slice()
                      .filter((attachment) => {
                        // TODO: Temporary fix - filter out files with pattern like image0xy.* (any extension)
                        return !attachment.filename.match(
                          /^image0\d+\.[a-z]+$/i
                        );
                      })
                      .sort((a, b) => {
                        const extA =
                          a.filename.split(".").pop()?.toLowerCase() || "";
                        const extB =
                          b.filename.split(".").pop()?.toLowerCase() || "";

                        // Create priority order: pdf = 1, xlsx = 2, others = 3
                        const getPriority = (ext: string) =>
                          ext === "pdf" ? 1 : ext === "xlsx" ? 2 : 3;

                        return getPriority(extA) - getPriority(extB);
                      })
                      .map((attachment) => (
                        <div
                          key={attachment.user_document_id}
                          onClick={() =>
                            handleAttachmentClick(
                              attachment.user_document_id,
                              attachment.filename
                            )
                          }
                          className="flex items-center p-4 rounded-lg border border-gray-200 bg-white hover:bg-gray-50 cursor-pointer min-w-[200px] max-w-[200px] flex-shrink-0"
                        >
                          <div className="w-8 h-8 rounded-full bg-purple-100 flex items-center justify-center mr-3 flex-shrink-0">
                            <FileText className="w-4 h-4 text-purple-600" />
                          </div>
                          <div className="flex flex-col overflow-hidden">
                            <span
                              className="text-sm font-medium text-gray-900 truncate"
                              title={attachment.filename}
                            >
                              {attachment.filename}
                            </span>
                          </div>
                        </div>
                      ))}
                  </div>
                  <ScrollBar orientation="horizontal" />
                </ScrollArea>
              </div>
            )}

            <div className="px-8 pb-2">
              <div className="email-wrapper max-w-full">
                <EmailContentErrorBoundary>
                  {sanitizeAndRenderHTML(emailBody, highlightRanges)}
                </EmailContentErrorBoundary>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DocumentPreviewDialog
        isOpen={isPreviewOpen}
        onClose={() => {
          setPreviewUrl("");
          setIsPreviewOpen(false);
        }}
        previewUrl={previewUrl}
        fileType={previewFileType as "pdf" | "image" | "excel"}
      />
    </div>
  );
};

export const EmailDetail = withRequiredAuthInfo(EmailDetailComponent) as (
  props: EmailDetailProps
) => JSX.Element;
