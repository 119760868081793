import { useState, useRef, useEffect } from "react";
import { Button } from "../ui/button";
import {
  TableProperties as VaultIcon,
  MoreVertical,
  Plus,
  Trash2,
  Loader2,
  ChevronRight,
} from "lucide-react";
import toast from "react-hot-toast";
import { withRequiredAuthInfo, UserClass } from "@propelauth/react";
import { TableContainer } from "../common/table/TableContainer";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { useNodeApi } from "../../hooks/useNodeApi";
import { VaultData, TableLogResponse } from "../../services/api";
import { NoMatch } from "../../pages/NoMatch";
import { useLocation, useNavigate } from "react-router-dom";

// Create the main component
function VaultMain({
  accessToken,
  userClass,
}: {
  accessToken: string;
  userClass: UserClass;
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const initialVaultId = location.state?.selectedVaultId;
  const sourceExecutionId = location.state?.executionId;
  const workflowTitle = location.state?.workflowTitle || "Workflow";
  const cameFromWorkflow = !!sourceExecutionId;
  const topComponentRef = useRef<HTMLDivElement>(null);
  const { fetchVaults, createNewVault, removeVault, fetchTableLog, isLoading } =
    useNodeApi(accessToken);

  // State for vault management
  const [vaults, setVaults] = useState<VaultData[]>([]);
  const [selectedVault, setSelectedVault] = useState<VaultData | null>(null);
  const [isCreatingVault, setIsCreatingVault] = useState(false);
  const [vaultTableData, setVaultTableData] = useState<TableLogResponse | null>(
    null
  );
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const org = userClass?.getOrgs()[0];
  const isFurtherAI =
    org?.orgMetadata["6296ccd4-3aa5-4475-bb9b-4a005612990b_FAI"];

  useEffect(() => {
    if (isFurtherAI) {
      loadVaults();
    }
  }, [isFurtherAI]); // eslint-disable-line react-hooks/exhaustive-deps

  // Select initial vault from navigation state if available
  useEffect(() => {
    if (initialVaultId && vaults.length > 0) {
      const vault = vaults.find((v) => v._id === initialVaultId);
      if (vault) {
        setSelectedVault(vault);
      }
    }
  }, [initialVaultId, vaults]);

  // Load vault data when a vault is selected
  useEffect(() => {
    if (isFurtherAI && selectedVault && selectedVault.table_log_id) {
      loadVaultData(selectedVault.table_log_id);
    } else {
      setVaultTableData(null);
    }
  }, [selectedVault, isFurtherAI]); // eslint-disable-line react-hooks/exhaustive-deps

  // Fetch vaults from API
  const loadVaults = async () => {
    try {
      const data = await fetchVaults();
      setVaults(data);
    } catch (err) {
      console.error("Failed to load vaults:", err);
      setError("Failed to load vaults. Please try again.");
      toast.error("Failed to load vaults");
    }
  };

  // Fetch vault data using table log ID
  const loadVaultData = async (tableLogId: string) => {
    setIsLoadingTable(true);
    try {
      const data = await fetchTableLog(tableLogId);
      setVaultTableData(data);
      setIsLoadingTable(false);
    } catch (err) {
      console.error("Failed to load vault data:", err);
      setError("Failed to load vault data. Please try again.");
      toast.error("Failed to load vault data");
      setIsLoadingTable(false);
    }
  };

  // Handle vault selection
  const handleVaultClick = (vault: VaultData) => {
    setSelectedVault(vault);
  };

  // Create new vault
  const handleCreateVault = async () => {
    setIsCreatingVault(true);
    try {
      const newVaultName = `Vault ${vaults.length}`;
      const newVaultDesc = "Vault Description";

      const newVault = await createNewVault(newVaultName, newVaultDesc);
      setVaults([...vaults, newVault]);
      toast.success("Vault created successfully");
    } catch (err) {
      console.error("Failed to create vault:", err);
      toast.error("Failed to create vault");
    } finally {
      setIsCreatingVault(false);
    }
  };

  // Delete vault
  const handleDeleteVault = async (vaultId: string, e: React.MouseEvent) => {
    e.stopPropagation();

    const vaultToDelete = vaults.find((v) => v._id === vaultId);
    if (!vaultToDelete) return;

    try {
      await removeVault(vaultId);

      setVaults(vaults.filter((v) => v._id !== vaultId));

      // If the deleted vault was selected, clear selection
      if (selectedVault && selectedVault._id === vaultId) {
        setSelectedVault(null);
      }

      toast.success(`Deleted ${vaultToDelete.name}`);
    } catch (err) {
      console.error("Failed to delete vault:", err);
      toast.error("Failed to delete vault");
    }
  };

  // Handle going back to workflow
  const handleBackToWorkflow = () => {
    if (sourceExecutionId) {
      navigate(`/workflow-execution/${sourceExecutionId}`);
    }
  };

  if (!isFurtherAI) {
    return <NoMatch />;
  }

  // Render loading state if initially loading vaults
  if (isLoading && vaults.length === 0) {
    return (
      <div className="max-w-6xl mx-auto px-4 py-8">
        <div className="flex flex-col items-center justify-center h-64">
          <Loader2 className="h-8 w-8 text-blue-500 animate-spin mb-4" />
          <p className="text-gray-500">Loading vaults...</p>
        </div>
      </div>
    );
  }

  // Render error state
  if (error && vaults.length === 0) {
    return (
      <div className="max-w-6xl mx-auto px-4 py-8">
        <div className="flex flex-col items-center justify-center h-64">
          <p className="text-red-500 mb-4">{error}</p>
          <Button onClick={loadVaults}>Try Again</Button>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-6xl mx-auto px-4 py-8">
      <div ref={topComponentRef}>
        <div className="flex justify-between items-center mb-6">
          {cameFromWorkflow ? (
            <div className="flex items-center text-md text-gray-500">
              <span
                className="cursor-pointer font-medium text-gray-900 truncate max-w-[200px] inline-block"
                onClick={handleBackToWorkflow}
              >
                {workflowTitle}
              </span>
              <ChevronRight className="h-4 w-4 mx-2" />
              <span className="font-semibold text-gray-900">Vault</span>
            </div>
          ) : (
            <h1 className="text-xl font-semibold">Vault</h1>
          )}
        </div>

        {/* Vault Tiles - grid-cols-6 to match Extract.tsx */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-6 gap-4 mb-8">
          {vaults.map((vault) => (
            <div key={vault._id} className="relative">
              <Button
                variant="outline"
                size="lg"
                className={`
                  h-28
                  w-full
                  flex flex-col items-center justify-center
                  hover:bg-gray-50
                  transition-colors
                  bg-white
                  shadow-sm
                  rounded-lg
                  border border-gray-200
                  ${
                    selectedVault?._id === vault._id
                      ? "ring-2 ring-blue-600 border-transparent"
                      : "hover:border-gray-300"
                  }
                `}
                onClick={() => handleVaultClick(vault)}
              >
                <VaultIcon className="h-6 w-6 text-blue-500 mb-2" />
                <span className="text-sm font-medium text-gray-900">
                  {vault.name}
                </span>
              </Button>

              <div className="absolute top-2 right-2">
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button
                      variant="ghost"
                      size="sm"
                      className="h-8 w-8 p-0 hover:bg-gray-100"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <MoreVertical className="h-4 w-4 text-gray-400" />
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent
                    className="w-40 p-1 bg-white"
                    align="start"
                    alignOffset={5}
                    sideOffset={8}
                    forceMount
                  >
                    <DropdownMenuItem
                      onClick={(e) => handleDeleteVault(vault._id, e)}
                      className="flex items-center px-2 py-2 text-sm text-red-600 hover:bg-red-50 hover:text-red-700"
                    >
                      <Trash2 className="mr-2 h-4 w-4" />
                      <span>Delete</span>
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            </div>
          ))}

          <div>
            <Button
              variant="outline"
              size="lg"
              className="h-28 w-full flex flex-col items-center justify-center hover:bg-gray-50 transition-colors bg-white shadow-sm rounded-lg border border-dashed border-gray-300"
              onClick={handleCreateVault}
              disabled={isCreatingVault}
            >
              {isCreatingVault ? (
                <Loader2 className="h-6 w-6 text-blue-500 animate-spin mb-2" />
              ) : (
                <Plus className="h-6 w-6 text-blue-500 mb-2" />
              )}
              <span className="text-sm font-medium text-gray-900">
                {isCreatingVault ? "Creating..." : "Create Vault"}
              </span>
            </Button>
          </div>
        </div>

        {selectedVault && (
          <div className="w-full">
            {isLoadingTable ? (
              <div className="flex flex-col items-center justify-center h-64">
                <Loader2 className="h-8 w-8 text-blue-500 animate-spin mb-4" />
                <p className="text-gray-500">Loading vault data...</p>
              </div>
            ) : vaultTableData ? (
              <div className="w-full overflow-x-auto border border-gray-200 rounded-lg">
                <TableContainer
                  tableData={{
                    ...vaultTableData,
                    metadata: {
                      ...vaultTableData.metadata,
                      title: selectedVault.name,
                    },
                  }}
                  className="w-full -mt-8"
                  isSimplifiedView={true}
                />
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center h-64">
                <p className="text-gray-500">No data available</p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

// Export the wrapped component
export const Vault = withRequiredAuthInfo(VaultMain);
