import { useState, useCallback, useRef, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useNodeApi } from "../../hooks/useNodeApi";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import { AuditLogResponse, Citation } from "../../services/api";
import { Button } from "../ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table";
import {
  ExternalLink,
  Search,
  ChevronRight,
  FileText,
  Sparkles,
  PanelLeftOpen,
  PanelLeftClose,
} from "lucide-react";
import toast from "react-hot-toast";
import { Input } from "../ui/input";
import { UwPdfPreviewSlider } from "./UwPdfPreview";
import { produce } from "immer";
import {
  withRequiredAuthInfo,
  WithLoggedInAuthInfoProps,
} from "@propelauth/react";
import axios from "axios";
import { ScrollArea } from "../ui/scroll-area";
import { usePolling } from "../../lib/usePolling";
import { POLLING_CONFIG } from "../../config/pollingConfig";
import DocumentPreviewDialog from "../DocumentPreviewDialog";
import { SubmissionAIChat } from "../submissions/SubmissionAIChat";
import { Breadcrumbs } from "../core/Breadcrumbs";
import { cn } from "../../lib/utils";

type Status = "Match" | "Mismatch" | "Needs Info";

const StatusBadge = ({ status }: { status: Status }) => {
  const colorClass =
    status === "Match"
      ? "bg-green-100 text-green-800"
      : status === "Mismatch"
      ? "bg-red-100 text-red-800"
      : "bg-yellow-100 text-yellow-800";
  return (
    <span
      className={`px-2 py-1 ${colorClass} rounded-full text-xs font-medium`}
    >
      {status}
    </span>
  );
};

const defaultDocumentNames = [
  "Guideline",
  "Submission",
  "Submission",
  "Submission",
  "Submission",
  "Submission",
  "Submission",
  "Submission",
  "Submission",
  "Submission",
];

interface UwAuditDetailBaseProps {
  initialAuditId?: string;
  isEmbedded?: boolean;
}

type UwAuditDetailProps = UwAuditDetailBaseProps & WithLoggedInAuthInfoProps;

export const UwAuditDetail = withRequiredAuthInfo(
  ({ accessToken, initialAuditId, isEmbedded = false }: UwAuditDetailProps) => {
    const navigate = useNavigate();
    const { id: urlAuditId } = useParams<{ id: string }>();
    const auditLogId = initialAuditId || urlAuditId;
    const { getAuditLog, getPdfUrl, exportAuditLog } = useNodeApi(accessToken);
    const [auditLog, setAuditLog] = useState<AuditLogResponse | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const lastPollTimeRef = useRef(0);
    const [isSliderOpen, setIsSliderOpen] = useState(false);
    const [pdfUrls, setPdfUrls] = useState<Map<number, string>>(new Map());
    const [currentPdfUrl, setCurrentPdfUrl] = useState<string | null>(null);
    const [isLoadingPdf, setIsLoadingPdf] = useState(false);
    const [isExporting, setIsExporting] = useState(false);
    const [selectedCitations, setSelectedCitations] = useState<Citation[]>([]);
    const [showFullSummary, setShowFullSummary] = useState(false);
    const [filterQuery, setFilterQuery] = useState("");
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [previewUrl, setPreviewUrl] = useState("");
    const [previewFileType, setPreviewFileType] = useState<"pdf" | "image">(
      "pdf"
    );
    const [isAIChatOpen, setIsAIChatOpen] = useState(false);
    const [isFullScreen, setIsFullScreen] = useState(false);

    const filteredRows = useMemo(() => {
      if (!auditLog?.result?.rows) return [];

      const query = filterQuery.toLowerCase().trim();
      if (!query) return auditLog.result.rows;

      return auditLog.result.rows.filter((row) => {
        if (row.title.toLowerCase().includes(query)) return true;
        if (
          row.data.some(
            (value) => value && value.toString().toLowerCase().includes(query)
          )
        )
          return true;
        return false;
      });
    }, [auditLog?.result?.rows, filterQuery]);

    usePolling(
      () => {
        if (!auditLogId) {
          throw new Error("No audit ID provided.");
        }
        return getAuditLog(auditLogId);
      },
      {
        interval: POLLING_CONFIG.INITIAL_INTERVAL,
        maxInterval: POLLING_CONFIG.MAX_INTERVAL,
        backoffMultiplier: POLLING_CONFIG.BACKOFF_MULTIPLIER,
        enabled: true,
        lastPollTimeRef,
        continuePollingOnSuccess: true,
        isComplete: (result) => {
          return result?.status === "completed";
        },
        onSuccess: (result) => {
          setAuditLog(result);
          setError(null);
          setIsLoading(false);
        },
        onError: (error) => {
          setIsLoading(false);
          if (axios.isAxiosError(error) && error.response?.status === 404) {
            navigate("/404");
            return false;
          }
          setError(error instanceof Error ? error.message : "Unknown error");
          return true;
        },
        onOfflineChange: (isOffline) => {
          if (isOffline) {
            toast.error("Failed to fetch audit results. Please try again.");
          } else {
            toast.success("Connection restored. Audit log updated.");
          }
        },
      }
    );

    const handleExport = async () => {
      if (!auditLog) return;

      setIsExporting(true);
      try {
        const downloadUrl = await exportAuditLog(auditLog._id);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.target = "_blank";
        link.download = `audit_${auditLog._id}.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        toast.success("Export successful. Your download should begin shortly.");
      } catch (error) {
        console.error("Export failed:", error);
        toast.error("Failed to export audit. Please try again.");
      } finally {
        setIsExporting(false);
      }
    };

    const handleCellClick = useCallback(
      async (rowIndex: number, colIndex: number) => {
        setIsSliderOpen(true);
        setIsLoadingPdf(true);
        setCurrentPdfUrl(null);
        setSelectedCitations([]);

        const currentRow = auditLog?.result?.rows[rowIndex];
        if (!currentRow) return;

        const rawCitations = currentRow.citations?.[colIndex] || [];
        const flattenedCitations: Citation[] = [];
        if (Array.isArray(rawCitations)) {
          flattenedCitations.push(...rawCitations);
        } else if (typeof rawCitations === "object") {
          for (const citations of Object.values(rawCitations)) {
            flattenedCitations.push(...citations);
          }
        }

        setSelectedCitations(flattenedCitations);

        const documentId =
          currentRow.citation_document_ids?.[colIndex] ||
          auditLog?.result?.documents[colIndex];

        if (documentId) {
          try {
            const cachedUrl = pdfUrls.get(colIndex);
            if (cachedUrl) {
              setCurrentPdfUrl(cachedUrl);
            } else {
              const url = await getPdfUrl(documentId);
              setPdfUrls(
                produce((draft) => {
                  draft.set(colIndex, url);
                })
              );
              setCurrentPdfUrl(url);
            }
          } catch (error) {
            console.error("Failed to fetch PDF URL:", error);
            toast.error("Failed to load PDF preview. Please try again.");
          }
        } else {
          console.warn("Document ID not found for column:", colIndex);
        }

        setIsLoadingPdf(false);
      },
      [auditLog, getPdfUrl, pdfUrls]
    );

    const handleDocumentClick = async (documentId: string) => {
      try {
        const url = await getPdfUrl(documentId);
        setPreviewFileType("pdf");
        setPreviewUrl(url);
        setIsPreviewOpen(true);
      } catch (error) {
        console.error("Failed to fetch PDF URL:", error);
        toast.error("Failed to load PDF preview");
      }
    };

    if (isLoading) {
      return (
        <div className="flex-1 overflow-auto min-h-screen bg-white py-2">
          <div className="mx-auto px-2">
            <div className="bg-white h-full overflow-y-auto">
              <div className="p-6">
                <Breadcrumbs module="" path="/audit" isEmbedded={isEmbedded} />

                <div className="mb-6">
                  <div className="h-6 w-24 bg-gray-200 rounded animate-pulse mb-4" />
                  <div className="space-y-2">
                    {[...Array(3)].map((_, i) => (
                      <div
                        key={i}
                        className="h-4 bg-gray-200 rounded animate-pulse"
                        style={{ width: `${Math.random() * 30 + 70}%` }}
                      />
                    ))}
                  </div>
                  <div className="absolute bottom-0 left-0 right-0 h-16 bg-gradient-to-t from-white to-transparent" />
                  <div className="absolute bottom-0 left-0 right-0 flex justify-center">
                    <div className="w-24 h-8 bg-gray-200 rounded-full transform translate-y-1/2 animate-pulse" />
                  </div>
                </div>

                <h2 className="text-lg font-semibold mb-4">Table</h2>

                <Table className="border-collapse border border-gray-200">
                  <TableHeader>
                    <TableRow>
                      {/* <TableHead className="w-[40px] border-y border-l border-gray-200">#</TableHead> */}
                      <TableHead className="border-y border-gray-200">
                        Checklist
                      </TableHead>
                      <TableHead className="border border-gray-200"></TableHead>
                      <TableHead className="border border-gray-200"></TableHead>
                      <TableHead
                        className="w-[200px] border-y border-r border-gray-200"
                        colSpan={2}
                      >
                        Status
                      </TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {[...Array(5)].map((_, index) => (
                      <TableRow key={index}>
                        {/* <TableCell className="border-y border-l border-gray-200">{index + 1}</TableCell> */}
                        <TableCell className="border-y border-gray-200">
                          <div className="h-4 bg-gray-200 rounded animate-pulse"></div>
                        </TableCell>
                        <TableCell className="border border-gray-200">
                          <div className="h-4 bg-gray-200 rounded animate-pulse"></div>
                        </TableCell>
                        <TableCell className="border border-gray-200">
                          <div className="h-4 bg-gray-200 rounded animate-pulse"></div>
                        </TableCell>
                        <TableCell className="border-y border-gray-200">
                          <div className="h-4 w-16 bg-gray-200 rounded animate-pulse"></div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (error) {
      return <CompareDetailError error={error} />;
    }

    if (!auditLog || !auditLog.result) {
      return <CompareDetailSkeleton isEmbedded={isEmbedded} />;
    }

    const { header, rows, summary } = auditLog.result;

    if (!header || !rows) {
      return <CompareDetailError error="Invalid audit data structure." />;
    }

    return (
      <div
        className={`flex flex-1 ${isEmbedded ? "h-full overflow-auto" : ""}`}
      >
        <div
          className={`flex-1 ${
            isAIChatOpen ? "pr-80 transition-all duration-300" : ""
          }`}
        >
          <div className="flex-1 overflow-auto min-h-screen bg-white py-2">
            <div className="mx-auto px-2">
              <div
                className={cn(
                  "bg-white h-full",
                  isFullScreen
                    ? "fixed inset-0 z-50 overflow-y-auto"
                    : "overflow-y-auto"
                )}
              >
                <div className="p-6">
                  <div>
                    <Breadcrumbs
                      module=""
                      path="/audit"
                      isEmbedded={isEmbedded}
                    />
                  </div>

                  {summary && (
                    <>
                      <h3 className="text-lg font-semibold mb-4">Summary</h3>
                      <div className="mb-6 border border-gray-200 rounded-lg">
                        <div className="p-4 pt-0 relative">
                          <ScrollArea
                            className={`
                              relative
                              ${
                                !showFullSummary
                                  ? "max-h-32"
                                  : "h-[calc(100vh-300px)]"
                              }
                              transition-all
                              duration-300
                            `}
                            type="always"
                          >
                            <div className="pr-4">
                              <ReactMarkdown
                                remarkPlugins={[remarkGfm]}
                                rehypePlugins={[rehypeRaw]}
                                components={{
                                  table: (props) => (
                                    <table
                                      className="border-collapse border border-gray-200 my-4 w-full text-gray-900"
                                      {...props}
                                    />
                                  ),
                                  th: (props) => (
                                    <th
                                      className="border border-gray-200 px-4 py-2 bg-gray-50 font-semibold text-gray-900"
                                      {...props}
                                    />
                                  ),
                                  td: (props) => (
                                    <td
                                      className="border border-gray-200 px-4 py-2 text-gray-700"
                                      {...props}
                                    />
                                  ),
                                  h1: (props) => (
                                    <h1
                                      className="text-xl font-semibold mt-5 mb-3 text-gray-900"
                                      {...props}
                                    />
                                  ),
                                  h2: (props) => (
                                    <h2
                                      className="text-xl font-semibold mt-5 mb-3 text-gray-900"
                                      {...props}
                                    />
                                  ),
                                  h3: (props) => (
                                    <h3
                                      className="text-xl font-medium mt-4 mb-2 text-gray-900"
                                      {...props}
                                    />
                                  ),
                                  p: (props) => (
                                    <p
                                      className="my-2 text-gray-700"
                                      {...props}
                                    />
                                  ),
                                  ul: (props) => (
                                    <ul
                                      className="list-disc pl-5 my-3 text-gray-700"
                                      {...props}
                                    />
                                  ),
                                  ol: (props) => (
                                    <ol
                                      className="list-decimal pl-5 my-3 text-gray-700"
                                      {...props}
                                    />
                                  ),
                                  li: (props) => (
                                    <li
                                      className="my-1 text-gray-700"
                                      {...props}
                                    />
                                  ),
                                  blockquote: (props) => (
                                    <blockquote
                                      className="border-l-4 border-gray-300 pl-4 py-2 my-4 italic text-gray-600"
                                      {...props}
                                    />
                                  ),
                                  code: (props) => {
                                    const { children, className } = props;
                                    const match = /language-(\w+)/.exec(
                                      className || ""
                                    );
                                    return match ? (
                                      <pre className="bg-gray-50 rounded p-2 overflow-x-auto my-4">
                                        <code
                                          className={`${className} text-gray-700`}
                                          {...props}
                                        >
                                          {children}
                                        </code>
                                      </pre>
                                    ) : (
                                      <code
                                        className="bg-gray-50 rounded px-1 py-0.5 font-mono text-sm text-gray-700"
                                        {...props}
                                      >
                                        {children}
                                      </code>
                                    );
                                  },
                                  pre: (props) => (
                                    <pre
                                      className="bg-gray-50 rounded p-2 overflow-x-auto my-4 text-gray-700"
                                      {...props}
                                    />
                                  ),
                                  a: (props) => (
                                    <a
                                      className="text-blue-600 hover:underline"
                                      {...props}
                                    />
                                  ),
                                  img: (props) => (
                                    <img
                                      className="max-w-full h-auto my-4"
                                      {...props}
                                    />
                                  ),
                                }}
                              >
                                {summary}
                              </ReactMarkdown>
                            </div>

                            {!showFullSummary && (
                              <div className="absolute bottom-0 left-0 right-0 h-16 bg-gradient-to-t from-white to-transparent pointer-events-none z-10" />
                            )}
                          </ScrollArea>

                          <div className="absolute bottom-0 left-0 right-0 flex justify-center z-20">
                            <Button
                              variant="outline"
                              size="sm"
                              onClick={() =>
                                setShowFullSummary(!showFullSummary)
                              }
                              className="bg-white border border-gray-200 shadow-sm px-4 py-2 rounded-full transform translate-y-1/2 hover:bg-gray-50"
                            >
                              {showFullSummary ? "Show less" : "Show more"}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  <div className="mb-6">
                    <div className="flex items-center gap-2 mb-8">
                      <button aria-expanded={isFullScreen}
                        onClick={() => setIsFullScreen(!isFullScreen)}
                        className="p-1 hover:bg-gray-100 transition-colors duration-200"
                        title={isFullScreen ? "Collapse view" : "Expand view"}
                      >
                        {isFullScreen ? (
                          <PanelLeftOpen className="h-5 w-5 text-black" />
                        ) : (
                          <PanelLeftClose className="h-5 w-5 text-black" />
                        )}
                      </button>
                      <div className="h-5 w-px bg-gray-500/60" />
                      <h3 className="text-lg font-semibold">
                        Uploaded documents
                      </h3>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                      {auditLog.result?.documents.map((docId, index) => (
                        <div
                          key={index}
                          onClick={() => handleDocumentClick(docId)}
                          className="flex items-center p-4 rounded-lg border border-gray-200 bg-white hover:bg-gray-50 cursor-pointer"
                        >
                          <div className="w-8 h-8 rounded-full bg-purple-100 flex items-center justify-center mr-3">
                            <FileText className="w-4 h-4 text-purple-600" />
                          </div>
                          <div className="flex flex-col">
                            <span className="text-sm font-medium text-gray-900">
                              {auditLog.result?.document_filenames
                                ? auditLog.result?.document_filenames[index]
                                : defaultDocumentNames[index] || "Other"}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="flex items-center justify-between mb-4">
                    <h2 className="text-lg font-semibold">Table</h2>
                    <div className="flex items-center space-x-2">
                      <div className="relative">
                        <Input
                          type="text"
                          placeholder="Filter documents..."
                          className="pl-8 pr-4 py-1 w-48 h-9"
                          value={filterQuery}
                          onChange={(e) => setFilterQuery(e.target.value)}
                        />
                        <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
                      </div>
                      <div className="flex items-center space-x-2">
                        {!isEmbedded && (
                          <Button
                            variant="outline"
                            size="sm"
                            className="text-gray-700 hover:text-gray-900 border border-gray-300 hover:bg-white"
                            onClick={() => setIsAIChatOpen(!isAIChatOpen)}
                          >
                            <Sparkles className="h-4 w-4 mr-2" />
                            Ask AI
                          </Button>
                        )}
                        <Button
                          variant="outline"
                          size="sm"
                          className="text-gray-700 hover:text-gray-900 border border-gray-300 hover:bg-white"
                          onClick={handleExport}
                          disabled={isExporting}
                        >
                          <ExternalLink className="h-4 w-4 mr-2" />
                          {isExporting ? "Exporting..." : "Export"}
                        </Button>
                      </div>
                    </div>
                  </div>
                  <Table className="border-collapse border border-gray-200">
                    <TableHeader>
                      <TableRow>
                        {/* <TableHead className="w-[40px] border-y border-l border-gray-200">#</TableHead> */}
                        <TableHead className="border-y border-gray-200">
                          Checklist
                        </TableHead>
                        {header.map((docName, index) => (
                          <TableHead
                            key={index}
                            className="border border-gray-200"
                          >
                            {docName}
                          </TableHead>
                        ))}
                        <TableHead
                          className="w-[200px] border-y border-r border-gray-200"
                          colSpan={2}
                        >
                          Status
                        </TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {filteredRows.map((row, rowIndex) => (
                        <TableRow
                          key={rowIndex}
                          className="hover:bg-transparent data-[state=selected]:bg-transparent"
                        >
                          {/* <TableCell className="border-y border-l border-gray-200">{rowIndex + 1}</TableCell> */}
                          <TableCell className="border-y border-gray-200 hover:bg-gray-50">
                            {row.title}
                          </TableCell>
                          {row.data.map((value, colIndex) => (
                            <TableCell
                              key={colIndex}
                              className="border border-gray-200 relative cursor-pointer hover:bg-gray-50 whitespace-pre-wrap"
                              onClick={() =>
                                handleCellClick(rowIndex, colIndex)
                              }
                            >
                              {value}
                              <ChevronRight className="h-4 w-4 absolute right-2 top-1/2 transform -translate-y-1/2 opacity-0 group-hover:opacity-100 transition-opacity" />
                            </TableCell>
                          ))}
                          <TableCell className="border-y border-gray-200 hover:bg-gray-50">
                            <StatusBadge
                              status={
                                row.status === "Match"
                                  ? "Match"
                                  : row.status === "Mismatch"
                                  ? "Mismatch"
                                  : "Needs Info"
                              }
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
          <UwPdfPreviewSlider
            isOpen={isSliderOpen}
            onClose={() => {
              setIsSliderOpen(false);
              setCurrentPdfUrl(null);
              setSelectedCitations([]);
            }}
            pdfUrl={currentPdfUrl}
            citations={selectedCitations}
            isLoading={isLoadingPdf}
          />
          <DocumentPreviewDialog
            isOpen={isPreviewOpen}
            onClose={() => {
              setPreviewUrl("");
              setIsPreviewOpen(false);
            }}
            previewUrl={previewUrl}
            fileType={previewFileType}
          />
        </div>

        <div
          className={`fixed right-0 top-0 h-full w-80 bg-white border-l border-gray-200 transform transition-transform duration-300 ${
            isAIChatOpen ? "translate-x-0" : "translate-x-full"
          }`}
        >
          {isAIChatOpen && (
            <SubmissionAIChat onClose={() => setIsAIChatOpen(false)} />
          )}
        </div>
      </div>
    );
  }
);

function CompareDetailSkeleton({ isEmbedded }: { isEmbedded?: boolean }) {
  return (
    <div className="flex-1 overflow-auto min-h-screen bg-white py-2">
      <div className="mx-auto px-2">
        <div className="bg-white h-full overflow-y-auto">
          <div className="p-6">
            <Breadcrumbs module="" path="/audit" isEmbedded={isEmbedded} />

            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center space-x-2">
                <div className="relative">
                  <Input
                    type="text"
                    placeholder="Search"
                    className="pl-8 pr-4 py-1 w-48 bg-white border-gray-300 text-gray-900 focus:ring-0 h-9"
                    disabled
                  />
                  <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
                </div>
              </div>
              <Button
                variant="outline"
                size="sm"
                className="text-gray-700 hover:text-gray-900 border border-gray-300 hover:bg-white"
                disabled
              >
                <ExternalLink className="h-4 w-4 mr-2" />
                Export
              </Button>
            </div>

            <h3 className="text-lg font-semibold mb-4">Summary</h3>
            <div className="mb-6 border border-gray-200 rounded-lg">
              <div className="p-4 relative">
                <div className="max-h-20 overflow-hidden">
                  {[...Array(3)].map((_, index) => (
                    <div
                      key={index}
                      className="h-4 bg-gray-200 rounded animate-pulse mb-2"
                      style={{ width: `${Math.random() * 30 + 70}%` }}
                    />
                  ))}
                </div>
                <div className="absolute bottom-0 left-0 right-0 h-16 bg-gradient-to-t from-white to-transparent" />
                <div className="absolute bottom-0 left-0 right-0 flex justify-center">
                  <div className="w-24 h-8 bg-gray-200 rounded-full transform translate-y-1/2 animate-pulse" />
                </div>
              </div>
            </div>

            <h2 className="text-lg font-semibold mb-4">Table</h2>

            <Table className="border-collapse border border-gray-200">
              <TableHeader>
                <TableRow>
                  {/* <TableHead className="w-[40px] border-y border-l border-gray-200">#</TableHead> */}
                  <TableHead className="border-y border-gray-200">
                    Checklist
                  </TableHead>
                  <TableHead className="border border-gray-200"></TableHead>
                  <TableHead className="border border-gray-200"></TableHead>
                  <TableHead
                    className="w-[200px] border-y border-r border-gray-200"
                    colSpan={2}
                  >
                    Status
                  </TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {[...Array(5)].map((_, index) => (
                  <TableRow
                    key={index}
                    className="hover:bg-transparent data-[state=selected]:bg-transparent"
                  >
                    {/* <TableCell className="border-y border-l border-gray-200">{index + 1}</TableCell> */}
                    <TableCell className="border-y border-gray-200 hover:bg-gray-50">
                      <div className="h-4 bg-gray-200 rounded animate-pulse"></div>
                    </TableCell>
                    <TableCell className="border border-gray-200 hover:bg-gray-50">
                      <div className="h-4 bg-gray-200 rounded animate-pulse"></div>
                    </TableCell>
                    <TableCell className="border border-gray-200 hover:bg-gray-50">
                      <div className="h-4 bg-gray-200 rounded animate-pulse"></div>
                    </TableCell>
                    <TableCell className="border-y border-gray-200 hover:bg-gray-50">
                      <div className="h-4 w-16 bg-gray-200 rounded animate-pulse"></div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
}

function CompareDetailError({ error }: { error: string }) {
  return (
    <div className="flex">
      <div className="flex-1 min-h-screen bg-white py-2">
        <div className="mx-auto px-2">
          <div className="bg-white h-full overflow-y-auto">
            <div className="p-6">
              <h1 className="text-xl font-semibold mb-6">Error</h1>
              <p className="text-red-500 mb-4">{error}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
