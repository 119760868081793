import { useState, useCallback } from "react";
import { useNodeApi } from "../../hooks/useNodeApi";
import { toast } from "react-hot-toast";
import {
  WithLoggedInAuthInfoProps,
  withRequiredAuthInfo,
} from "@propelauth/react";
import {
  SquareSplitHorizontal,
  ExternalLink,
  PanelLeftClose,
  PanelLeftOpen,
} from "lucide-react";
import { Grid } from "./Grid";
import { GridPdfDialog } from "../common/dialog/GridPdfDialog";
import { Citation } from "../../types/table";
import { TableLogResponse } from "../../services/api";
import { produce } from "immer";
import { Button } from "../ui/button";
import { cn } from "../../lib/utils";

interface GridContainerBaseProps {
  tableData: TableLogResponse;
  className?: string;
  filteredRows?: number[];
  isSimplifiedView?: boolean;
}

type GridContainerProps = GridContainerBaseProps & WithLoggedInAuthInfoProps;

export const GridContainer = withRequiredAuthInfo(
  ({
    accessToken,
    tableData,
    filteredRows,
    isSimplifiedView = false,
    className,
  }: GridContainerProps) => {
    const [currentPdfUrl, setCurrentPdfUrl] = useState<string | null>(null);
    const [currentEmailHtml, setCurrentEmailHtml] = useState<string | null>(
      null
    );
    const [currentEmailHighlightRanges, setCurrentEmailHighlightRanges] =
      useState<Array<{ start: number; end: number }> | null>(null);
    const [currentEmailHeaders, setCurrentEmailHeaders] = useState<
      Record<string, string> | undefined
    >(undefined);
    const [isSplitDialogOpen, setIsSplitDialogOpen] = useState(false);
    const [isPdfLoading, setIsPdfLoading] = useState<boolean>(false);
    const [isExporting, setIsExporting] = useState(false);
    const {
      getPdfUrl,
      updateTableCells,
      fetchEmailDetails,
      exportTableLog,
      fetchTableLog,
    } = useNodeApi(accessToken);

    const [tableDataState, setTableDataState] = useState({
      headers: tableData.result.headers,
      rows: tableData.result.rows.map((row) => ({ cells: row.cells })),
      metadata: tableData.metadata,
    });

    const [selectedCitations, setSelectedCitations] = useState<
      Citation[] | null
    >(null);

    const [isFullScreen, setIsFullScreen] = useState(false);

    const handleCitationClick = async (
      citations: Citation[] | null,
      citationIndex = 0
    ) => {
      if (!citations || citations.length === 0) {
        setSelectedCitations(null);
        setCurrentPdfUrl(null);
        setCurrentEmailHtml(null);
        setCurrentEmailHighlightRanges(null);
        setCurrentEmailHeaders(undefined);
        setIsPdfLoading(false);
        return;
      }

      const citation = citations[citationIndex];

      if (!citation) {
        setSelectedCitations(null);
        setCurrentPdfUrl(null);
        setCurrentEmailHtml(null);
        setCurrentEmailHighlightRanges(null);
        setCurrentEmailHeaders(undefined);
        setIsPdfLoading(false);
        return;
      }

      setSelectedCitations(citations);

      if (currentPdfUrl && currentPdfUrl === citation.document) {
        setCurrentPdfUrl(null);
        return;
      }

      try {
        setIsPdfLoading(true);
        const url = await getPdfUrl(citation.document);
        const urlParsed = new URL(url);
        if (urlParsed.pathname.endsWith(".pdf")) {
          setCurrentPdfUrl(url);
          setCurrentEmailHtml(null);
          setCurrentEmailHighlightRanges(null);
          setCurrentEmailHeaders(undefined);
        } else if (
          urlParsed.pathname.endsWith(".eml") ||
          urlParsed.pathname.endsWith(".msg")
        ) {
          const emailDetails = await fetchEmailDetails(citation.document);
          setCurrentEmailHtml(emailDetails.body_html);
          setCurrentEmailHeaders(emailDetails.headers);
          setCurrentEmailHighlightRanges(
            citations
              ?.filter(
                (c) =>
                  Number.isFinite(c.email_citation?.start) &&
                  Number.isFinite(c.email_citation?.end)
              )
              .map((c) => ({
                start: c.email_citation?.start || 0,
                end: c.email_citation?.end || 0,
              }))
          );
          setCurrentPdfUrl(null);
        }
        setIsPdfLoading(false);
      } catch (error) {
        console.error("Failed to fetch PDF URL:", error);
        toast.error("Failed to load PDF preview");
        setIsPdfLoading(false);
      }
    };

    const handleCellEdited = useCallback(
      async (cell: [number, number], newValue: string) => {
        const [col, row] = cell;
        const actualRow = filteredRows ? filteredRows[row] : row;

        setTableDataState((prevTableData) =>
          produce(prevTableData, (draft) => {
            draft.rows[actualRow].cells[col].value = newValue;
          })
        );

        if (tableData._id) {
          try {
            await updateTableCells(tableData._id, [
              { row, col, new_value: newValue },
            ]);
          } catch (error) {
            console.error("Failed to update cell on server:", error);
          }
        }
      },
      [filteredRows, tableData._id, updateTableCells]
    );

    const toggleSplitDialog = () => {
      setIsSplitDialogOpen((prev) => !prev);
    };

    const handleExport = async () => {
      if (!tableData._id || !accessToken) {
        toast.error("Unable to export: missing table ID or authentication");
        return;
      }

      setIsExporting(true);
      try {
        const url = await exportTableLog(tableData._id);

        const link = document.createElement("a");
        link.href = url;
        link.download = `${tableData.metadata?.title || "table"}.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        toast.success("Export successful");
      } catch (error) {
        console.error("Export failed:", error);
        toast.error("Failed to export. Please try again.");
      } finally {
        setIsExporting(false);
      }
    };

    const refreshTableData = async () => {
      if (tableData._id) {
        try {
          const updatedData = await fetchTableLog(tableData._id);

          const formattedData = {
            headers: updatedData.result.headers,
            rows: updatedData.result.rows,
            metadata: tableDataState.metadata,
          };

          setTableDataState(formattedData);
        } catch (error) {
          console.error("Failed to refresh table data:", error);
        }
      }
    };

    return (
      <>
        <div
          className={cn(
            "flex flex-col",
            !isSplitDialogOpen ? "w-full" : "",
            isFullScreen ? "fixed inset-0 z-50 bg-white" : "",
            className
          )}
          onDoubleClick={() => {
            if (!isSplitDialogOpen && !isSimplifiedView) {
              toggleSplitDialog();
            }
          }}
        >
          {!isSimplifiedView && (
            <div
              className={cn(
                "flex justify-between items-center py-4",
                !isSplitDialogOpen ? "px-[3.5%] pt-8 pr-8" : "px-2"
              )}
            >
              <div className="flex items-center gap-2">
                <button
                  onClick={() => setIsFullScreen(!isFullScreen)}
                  className="p-1 hover:bg-gray-100 transition-colors duration-200"
                  title={isFullScreen ? "Collapse view" : "Expand view"}
                >
                  {isFullScreen ? (
                    <PanelLeftOpen className="h-5 w-5 text-black" />
                  ) : (
                    <PanelLeftClose className="h-5 w-5 text-black" />
                  )}
                </button>
                <div className="h-5 w-px bg-gray-500/60" />
                <h3 className="text-lg font-semibold tracking-tight">
                  {tableData.metadata?.title}
                </h3>
              </div>

              {!isSplitDialogOpen && (
                <div className="flex items-center mr-6">
                  <Button
                    variant="outline"
                    size="sm"
                    className="text-gray-700 hover:text-gray-900 border border-gray-300 hover:bg-white"
                    onClick={handleExport}
                    disabled={isExporting || !tableData._id || !accessToken}
                  >
                    <ExternalLink className="h-4 w-4 mr-2" />
                    {isExporting ? "Exporting..." : "Export"}
                  </Button>
                  <button
                    onClick={toggleSplitDialog}
                    className="p-2 hover:bg-gray-100 rounded-full transition-colors ml-4"
                    title="Expand to split view"
                  >
                    <SquareSplitHorizontal className="h-5 w-5 text-gray-500" />
                  </button>
                </div>
              )}
            </div>
          )}
          <div
            className={cn(
              "w-full overflow-auto flex-1",
              isSimplifiedView ? "px-0" : !isSplitDialogOpen ? "px-4" : "",
              isFullScreen ? "p-6" : ""
            )}
          >
            <div
              className={cn(
                isSimplifiedView
                  ? ""
                  : "border-t border-l border-r border-b border-gray-200",
                !isSplitDialogOpen && !isSimplifiedView
                  ? "max-w-[95%] mx-auto"
                  : "w-full"
              )}
            >
              <Grid
                tableData={tableDataState}
                filteredRows={filteredRows}
                pdfUrl={currentPdfUrl}
                emailHtml={currentEmailHtml}
                emailHighlightRanges={currentEmailHighlightRanges}
                emailHeaders={currentEmailHeaders}
                onCitationClick={handleCitationClick}
                onCellEdited={handleCellEdited}
                editable={false}
                filterQuery={""}
                fetchTableLog={refreshTableData}
                disableInlinePdfPreview={isSimplifiedView}
              />
            </div>
            {!isSplitDialogOpen && !isSimplifiedView && (
              <div className="h-6"></div>
            )}
          </div>
        </div>

        {isSplitDialogOpen && !isSimplifiedView && (
          <GridPdfDialog
            isOpen={isSplitDialogOpen}
            onClose={() => setIsSplitDialogOpen(false)}
            tableData={tableDataState}
            filteredRows={filteredRows}
            onCellEdited={handleCellEdited}
            onCitationClick={handleCitationClick}
            tableLogId={tableData._id}
            pdfUrl={currentPdfUrl}
            emailHtml={currentEmailHtml}
            emailHighlightRanges={currentEmailHighlightRanges}
            emailHeaders={currentEmailHeaders}
            onClearPdf={() => {
              setCurrentPdfUrl(null);
              setCurrentEmailHtml(null);
              setCurrentEmailHeaders(undefined);
              setCurrentEmailHighlightRanges(null);
            }}
            citations={
              selectedCitations?.map((c) => ({
                citation: c.citation,
                state: "match",
              })) || null
            }
            fileType={
              currentPdfUrl ? "pdf" : currentEmailHtml ? "email" : undefined
            }
            isLoading={isPdfLoading}
            fetchTableLog={refreshTableData}
          />
        )}
      </>
    );
  }
);
