import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "./ui/dialog";
import { Button } from "./ui/button";
import { ArrowUpRight, FileText, Upload, Trash } from "lucide-react";
import { useRef, useState, useEffect, useMemo, useCallback } from "react";
import { UserDocument, UserDocumentUploadRequest } from "../services/api";
import toast from "react-hot-toast";
import { CaretSortIcon } from "@radix-ui/react-icons";
import { ScrollArea } from "./ui/scroll-area";

interface UploadDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onUploadComplete: (documents: UserDocument[]) => void;
  uploadDocument: (
    file: File,
    request: UserDocumentUploadRequest
  ) => Promise<{ user_document_id: string; sas_url: string }>;
  deleteDocument: (documentId: string) => Promise<void>;
  selectedTemplate: string;
  acceptedFileTypes?: {
    mimeTypes: string[];
    errorMessage: string;
  };
  category?: "fill_excel" | "compare" | "extract" | string;
  singleFileOnly?: boolean;
  selectedSchema?: {
    name: string;
    value: string;
    schema: string;
  };
  maxFiles?: number;
}

const defaultAcceptedFileTypes = {
  mimeTypes: [
    "application/pdf",
    "image/jpeg",
    "image/png",
    "image/gif",
    "image/webp",
  ],
  errorMessage: "Please upload PDF or image files only.",
};

const getMimeTypeAcceptString = (mimeTypes: string[]): string => {
  const mimeToExtension: { [key: string]: string } = {
    "application/pdf": ".pdf",
    "application/vnd.ms-excel": ".xls",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      ".xlsx",
    "image/jpeg": ".jpg,.jpeg",
    "image/png": ".png",
    "image/gif": ".gif",
    "image/webp": ".webp",
  };

  return mimeTypes
    .map((mime) => `${mime},${mimeToExtension[mime] || ""}`)
    .filter(Boolean)
    .join(",");
};

interface SchemaProperty {
  title?: string;
  description?: string;
  type?: string;
}

interface SchemaProperties {
  [key: string]: SchemaProperty;
}

interface ParsedSchema {
  properties: SchemaProperties;
}

const MAX_FILES = 10;

export function UploadDialog({
  isOpen,
  onClose,
  onUploadComplete,
  uploadDocument,
  deleteDocument,
  acceptedFileTypes = defaultAcceptedFileTypes,
  category,
  singleFileOnly = false,
  selectedSchema,
}: UploadDialogProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [uploadedDocuments, setUploadedDocuments] = useState<UserDocument[]>(
    []
  );
  const [isUploading, setIsUploading] = useState(false);
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const acceptString = useMemo(
    () => getMimeTypeAcceptString(acceptedFileTypes.mimeTypes),
    [acceptedFileTypes.mimeTypes]
  );

  useEffect(() => {
    if (!isOpen) {
      setUploadedDocuments([]);
    }
  }, [isOpen]);

  useEffect(() => {
    if (scrollContainerRef.current && uploadedDocuments.length > 0) {
      scrollContainerRef.current.scrollTop =
        scrollContainerRef.current.scrollHeight;
    }
  }, [uploadedDocuments.length]);

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const droppedFiles = Array.from(e.dataTransfer.files);
    handleFiles(droppedFiles);
  };

  const removeFile = async (fileName: string) => {
    const documentToRemove = uploadedDocuments.find(
      (doc) => doc.filename === fileName
    );
    if (documentToRemove) {
      try {
        await deleteDocument(documentToRemove._id);
        setUploadedDocuments((prev) =>
          prev.filter((doc) => doc._id !== documentToRemove._id)
        );

        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      } catch (error) {
        toast.error(`Failed to delete document ${fileName}`);
      }
    }
  };

  const handleFiles = async (newFiles: File[]) => {
    const totalFileCount = uploadedDocuments.length + newFiles.length;
    if (totalFileCount > MAX_FILES) {
      toast.error(`You can upload maximum ${MAX_FILES} files.`, {
        duration: 4000,
        icon: "⚠️",
      });
      return;
    }

    const filesToProcess = singleFileOnly ? newFiles.slice(0, 1) : newFiles;
    const validFiles = Array.from(filesToProcess).filter((file) => {
      const fileType = file.type.toLowerCase();
      return acceptedFileTypes.mimeTypes.includes(fileType);
    });

    setIsUploading(true);

    try {
      for (let i = 0; i < validFiles.length; i++) {
        const file = validFiles[i];
        const uploadRequest: UserDocumentUploadRequest = {
          filename: file.name,
          blob_url: "",
          origin: "upload",
          category: category || "",
          owner_uid: "",
          owner_oid: "",
          run_id: "",
        };

        const { user_document_id, sas_url } = await uploadDocument(
          file,
          uploadRequest
        );
        setUploadedDocuments((prev) => {
          if (prev.length >= MAX_FILES) {
            throw new Error(`Maximum ${MAX_FILES} files allowed`);
          }
          return singleFileOnly
            ? [
                {
                  _id: user_document_id,
                  filename: file.name,
                  blob_url: sas_url,
                } as UserDocument,
              ]
            : [
                ...prev,
                {
                  _id: user_document_id,
                  filename: file.name,
                  blob_url: sas_url,
                } as UserDocument,
              ];
        });
      }
    } catch (error) {
      console.error("Upload failed:", error);
      toast.error(
        error instanceof Error ? error.message : "Failed to upload file(s)"
      );
    } finally {
      setIsUploading(false);
    }

    if (validFiles.length !== filesToProcess.length) {
      toast.error(acceptedFileTypes.errorMessage, {
        duration: 4000,
        icon: "⚠️",
      });
    }
  };

  const handleSave = () => {
    if (uploadedDocuments.length > 0) {
      onUploadComplete(uploadedDocuments);
      onClose();
    }
  };

  const getSchemaFields = useMemo(() => {
    if (!selectedSchema?.schema) return [];
    try {
      const parsed = JSON.parse(selectedSchema.schema) as ParsedSchema;
      const fields = Object.entries(parsed.properties || {}).map(
        ([key, value]) => {
          const property = value as SchemaProperty;
          return {
            name: property.title || key,
            description: property.description || "",
          };
        }
      );

      return sortDirection === "asc" ? fields : [...fields].reverse();
    } catch (e) {
      console.error("Failed to parse schema:", e);
      return [];
    }
  }, [selectedSchema?.schema, sortDirection]);

  const toggleSort = () => {
    setSortDirection((prev) => (prev === "asc" ? "desc" : "asc"));
  };

  const scrollAreaRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = useCallback(() => {
    const viewport = scrollAreaRef.current?.querySelector(
      "[data-radix-scroll-area-viewport]"
    );
    if (viewport instanceof HTMLElement) {
      viewport.scrollTop = viewport.scrollHeight;
    }
  }, []);

  useEffect(() => {
    if (uploadedDocuments.length > 0) {
      requestAnimationFrame(() => {
        scrollToBottom();
      });
    }
  }, [uploadedDocuments.length, scrollToBottom]);

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[650px] w-[95vw] max-h-[85vh] flex flex-col overflow-hidden p-0 rounded-lg [&>button]:hidden">
        <DialogHeader className="flex-shrink-0 p-6 pb-2">
          <DialogTitle className="text-xl font-semibold">
            Upload Documents
          </DialogTitle>
          <p className="text-sm text-gray-500 mt-1">
            Upload{" "}
            {category === "extract"
              ? "Documents to Extract Key Data into a Table"
              : category === "compare"
              ? "Documents to Run Comparisons"
              : category === "audit"
              ? "a Guideline and Submission Documents for Audit"
              : category === "fill_excel"
              ? "Excel Files to Convert into a Standardized Template"
              : ""}
            .
          </p>
        </DialogHeader>

        <div className="flex-1 overflow-hidden">
          <div className="p-6 pt-2 h-full flex flex-col">
            <div className="flex gap-4 flex-shrink-0 mb-4">
              <div
                className={`border-2 border-dashed border-gray-300 rounded-lg h-[260px] flex flex-col items-center justify-center cursor-pointer ${
                  getSchemaFields.length ? "flex-1" : "w-full"
                }`}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                onClick={() => fileInputRef.current?.click()}
              >
                <Upload className="h-8 w-8 text-gray-400 mb-3" />
                <p className="text-sm font-medium text-gray-900">
                  Drag & drop files here,
                </p>
                <p className="text-sm font-medium text-gray-900 mb-1">
                  or click to select files
                </p>
                <p className="text-xs text-gray-500">
                  You can upload up to {MAX_FILES} files.
                </p>
              </div>

              {getSchemaFields.length > 0 && (
                <div className="w-[220px] border rounded-lg h-[260px] flex flex-col overflow-hidden">
                  <div className="p-3 border-b">
                    <div className="flex items-center space-x-2">
                      <button
                        onClick={toggleSort}
                        className="flex items-center gap-1 text-xs text-gray-500 hover:text-gray-900"
                      >
                        <CaretSortIcon className="h-3 w-3" />
                      </button>
                      <div className="flex items-center">
                        <span className="text-xs font-medium">
                          Template Fields
                        </span>
                      </div>
                    </div>
                  </div>

                  <ScrollArea className="flex-1 p-2">
                    <div className="space-y-1">
                      {getSchemaFields.map((field) => (
                        <div
                          key={field.name}
                          className="flex items-center gap-3 py-1.5 px-1 hover:bg-gray-50 rounded-md"
                        >
                          <span className="text-xs truncate">{field.name}</span>
                        </div>
                      ))}
                    </div>
                  </ScrollArea>
                </div>
              )}
            </div>

            {uploadedDocuments.length > 0 && (
              <ScrollArea ref={scrollAreaRef} className="h-[200px] w-full">
                <div className="p-2 space-y-1">
                  {uploadedDocuments.map((document) => (
                    <div
                      key={document._id}
                      className="flex items-center justify-between py-2 px-3 hover:bg-gray-50 rounded-md group"
                    >
                      <div className="flex items-center gap-3 flex-1 min-w-0">
                        <div className="w-8 h-8 rounded-lg bg-purple-50 flex-shrink-0 flex items-center justify-center">
                          <FileText className="w-4 h-4 text-purple-600" />
                        </div>
                        <span className="text-sm text-gray-900 truncate max-w-[400px]">
                          {document.filename}
                        </span>
                      </div>
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={() => removeFile(document.filename)}
                        className="h-8 w-8 rounded-md opacity-0 group-hover:opacity-100 ml-2 flex-shrink-0 group"
                      >
                        <Trash className="h-4 w-4 text-gray-400 group-hover:text-red-500" />
                      </Button>
                    </div>
                  ))}
                </div>
              </ScrollArea>
            )}
          </div>
        </div>

        <input
          type="file"
          ref={fileInputRef}
          className="hidden"
          onChange={(e) =>
            e.target.files && handleFiles(Array.from(e.target.files))
          }
          multiple={!singleFileOnly}
          accept={acceptString}
        />

        <DialogFooter className="flex-shrink-0 border-t p-4">
          <div className="flex justify-end gap-2">
            <Button variant="outline" onClick={onClose} className="text-sm">
              Cancel
            </Button>
            <Button
              onClick={handleSave}
              disabled={uploadedDocuments.length === 0 || isUploading}
              className="text-sm bg-blue-50 text-blue-600 border border-blue-200 hover:bg-blue-100"
            >
              <ArrowUpRight className="w-4 h-4 mr-1" />
              {isUploading ? "Uploading..." : "Run"}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
