import { useEffect, useState } from "react";
import Chat from "./Chat";
import { NoMatch } from "../../pages/NoMatch";
import { withRequiredAuthInfo, UserClass } from "@propelauth/react";
import { Link, useSearchParams } from "react-router-dom";
import { Home } from "lucide-react";

interface InteractPageProps {
  accessToken: string | null;
  userClass: UserClass | null;
}

export const InteractPage = withRequiredAuthInfo(
  ({ accessToken, userClass }: InteractPageProps) => {
    const [isLoading, setIsLoading] = useState(true);
    const [hasFAIAccess, setHasFAIAccess] = useState(false);
    const [searchParams] = useSearchParams();
    const type = searchParams.get("type") || "default";
    const executionId = searchParams.get("executionId") || "";

    // Map type to title
    const chatConfig = {
      comparison: { title: "Policy Comparison" },
      intake: { title: "Submission Intake" },
      audit: { title: "UW Audit" },
      lossrun: { title: "Loss Run" },
      policychecking: { title: "Policy Checking" },
      default: { title: "Policy Checking" },
    };

    const { title } =
      chatConfig[type as keyof typeof chatConfig] || chatConfig.default;

    useEffect(() => {
      if (userClass) {
        try {
          const org = userClass.getOrgs()[0];
          const isFurtherAI =
            org?.orgMetadata["6296ccd4-3aa5-4475-bb9b-4a005612990b_FAI"] ||
            org?.orgMetadata["a20ff685-3aa7-4fec-95f4-006e0e061fd9"] || // RoundStone
            org?.orgMetadata["923a9c27-69ed-4976-a3ea-40211b9fa4a9"]; // Roundstone staging
          setHasFAIAccess(!!isFurtherAI);
        } catch (error) {
          console.error("Error checking FAI flag:", error);
          setHasFAIAccess(false);
        }
      }
      setIsLoading(false);
    }, [userClass]);

    if (isLoading) {
      return (
        <div className="flex items-center justify-center min-h-screen">
          Loading...
        </div>
      );
    }

    if (!hasFAIAccess) {
      return <NoMatch />;
    }

    return (
      <div className="min-h-screen bg-white">
        {/* Breadcrumb Navigation */}
        <div className="max-w-4xl mx-auto p-6 pb-0">
          <nav className="flex items-center text-gray-500 text-sm mb-4">
            <Link to="/" className="hover:text-gray-700">
              <Home className="w-4 h-4" />
            </Link>
            <span className="mx-2">›</span>
            <Link to="/ai-assistants" className="hover:text-gray-700">
              AI Assistants
            </Link>
            <span className="mx-2">›</span>
            <span className="text-gray-900">{title}</span>
          </nav>
        </div>

        {/* Pass the necessary props to Chat component */}
        <Chat
          accessToken={accessToken}
          userClass={userClass}
          executionId={executionId}
        />
      </div>
    );
  }
);

export default InteractPage;
