import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  ChevronLeft,
  ChevronRight,
  MoreHorizontal,
  Search,
  Trash2,
} from "lucide-react";
import { Input } from "../ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  TooltipProvider,
} from "../ui/tooltip";
import { Button } from "../ui/button";
import { usePolling } from "../../lib/usePolling";
import { POLLING_CONFIG } from "../../config/pollingConfig";
import toast from "react-hot-toast";
import { WorkflowExecutionLogResponse } from "../../services/api";

export interface ChatHistoryItem {
  id: string;
  senderName: string;
  numberOfDocuments: number;
  date: string;
  time: string;
  timestamp: number; // Raw timestamp for accurate sorting
  title: string;
  isDeleted?: boolean;
}

interface ChatHistoryProps {
  workflowId: string;
  fetchWorkflowExecutionLogs: (
    workflowId: string
  ) => Promise<WorkflowExecutionLogResponse[]>;
  deleteLogByCategory?: (category: string, logId: string) => Promise<void>;
}

export const ChatHistory = ({
  workflowId,
  fetchWorkflowExecutionLogs,
  deleteLogByCategory,
}: ChatHistoryProps) => {
  const navigate = useNavigate();
  // History tab states
  const [chatHistory, setChatHistory] = useState<ChatHistoryItem[]>([]);
  const [isHistoryLoading, setIsHistoryLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const lastPollTimeRef = useRef(0);
  const [pollingEnabled, setPollingEnabled] = useState(true);

  const processLogsToHistoryItems = (
    logs: WorkflowExecutionLogResponse[]
  ): ChatHistoryItem[] => {
    return logs
      .filter((log) => !log.isDeleted)
      .map((log) => {
        const timestamp = parseISODate(log.created_at);
        return {
          id: log._id,
          senderName: log.owner_name || "You",
          numberOfDocuments: log.request.documents.length,
          date: formatDate(log.created_at),
          time: formatTime(log.created_at),
          timestamp: timestamp.getTime(),
          title: log.title || log.request.instruction || "Ask AI Query",
          isDeleted: log.isDeleted || false,
        };
      });
  };

  // Reset state when workflow ID changes
  useEffect(() => {
    setIsHistoryLoading(true);
    setChatHistory([]);
    setCurrentPage(1);
    // Reset polling to make sure it's enabled for the new workflowId
    setPollingEnabled(true);
  }, [workflowId]);

  // Polling for history updates
  usePolling(
    () => {
      setIsHistoryLoading(true);
      return fetchWorkflowExecutionLogs(workflowId);
    },
    {
      interval: POLLING_CONFIG.INITIAL_INTERVAL,
      maxInterval: POLLING_CONFIG.MAX_INTERVAL,
      backoffMultiplier: POLLING_CONFIG.BACKOFF_MULTIPLIER,
      enabled: pollingEnabled,
      lastPollTimeRef,
      onSuccess: (logs) => {
        const historyItems = processLogsToHistoryItems(logs);
        setChatHistory(historyItems);
        setIsHistoryLoading(false);
      },
      onError: (error: unknown) => {
        console.error("Failed to fetch chat history updates:", error);
        toast.error("Failed to load chat history");
        setIsHistoryLoading(false);
      },
    }
  );

  // Format date and time functions
  const formatDate = (created_at: string): string => {
    const startDate = parseISODate(created_at);
    return startDate.toLocaleDateString();
  };

  const formatTime = (created_at: string): string => {
    const startDate = parseISODate(created_at);
    return startDate.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  // Ensure consistent date handling
  const parseISODate = (created_at: string): Date => {
    // Handle ISO strings that might or might not have Z suffix
    return new Date(created_at.endsWith("Z") ? created_at : created_at + "Z");
  };

  // Handle search in history tab
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  // Handle history item deletion
  const handleDelete = async (id: string, e: React.MouseEvent) => {
    e.stopPropagation();
    try {
      if (deleteLogByCategory) {
        await deleteLogByCategory("workflow-execution", id);
        setChatHistory((prevHistory) =>
          prevHistory.map((item) =>
            item.id === id ? { ...item, isDeleted: true } : item
          )
        );
      } else {
        // Fallback to just updating the UI if no delete function available
        setChatHistory((prevHistory) =>
          prevHistory.map((item) =>
            item.id === id ? { ...item, isDeleted: true } : item
          )
        );
        toast.success("History item marked as deleted");
      }
    } catch (error) {
      console.error("Failed to delete history item:", error);
      toast.error("Failed to delete history item");
    }
  };

  // Sort and filter history data
  const sortedAndFilteredHistory = chatHistory
    .filter((item) => {
      if (item.isDeleted) return false;
      const searchLower = searchQuery.toLowerCase();
      return (
        item.senderName.toLowerCase().includes(searchLower) ||
        item.title.toLowerCase().includes(searchLower) ||
        item.date.toLowerCase().includes(searchLower) ||
        item.time.toLowerCase().includes(searchLower) ||
        item.numberOfDocuments.toString().includes(searchQuery)
      );
    })
    .sort((a, b) => {
      // Sort by timestamp in descending order (newest first)
      return b.timestamp - a.timestamp;
    });

  // Paginate history data
  const paginatedHistory = sortedAndFilteredHistory.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  // Function to handle clicking on a history row
  const handleHistoryRowClick = (historyItem: ChatHistoryItem) => {
    navigate(`/workflow-execution/${historyItem.id}`);
  };

  // Render shimmer loading content for history
  const renderHistoryShimmerContent = () => {
    return (
      <>
        {Array(7)
          .fill(0)
          .map((_, index) => (
            <div
              key={`shimmer-${index}`}
              className={`flex items-center px-4 py-3 ${
                index % 2 === 0 ? "bg-white" : "bg-gray-50"
              }`}
              style={{ animationDelay: `${index * 0.05}s` }}
            >
              {/* Title shimmer */}
              <div
                className="flex-1 h-4 bg-gray-200 rounded animate-pulse min-w-0"
                style={{ animationDuration: "1.5s" }}
              ></div>

              {/* Sender name shimmer - hide on small screens */}
              <div
                className="h-4 w-28 bg-gray-200 rounded animate-pulse ml-3 mr-2 hidden sm:block"
                style={{ animationDuration: "1.7s" }}
              ></div>

              {/* Date shimmer */}
              <div
                className="h-4 w-20 bg-gray-200 rounded animate-pulse mr-2 flex-shrink-0"
                style={{ animationDuration: "1.6s" }}
              ></div>

              {/* Button shimmer */}
              <div
                className="h-7 w-7 bg-gray-200 rounded-full animate-pulse flex-shrink-0"
                style={{ animationDuration: "1.8s" }}
              ></div>
            </div>
          ))}
      </>
    );
  };

  return (
    <div className="mb-4">
      <div className="border border-gray-200 rounded-lg bg-white shadow-sm">
        <div className="px-3 py-2">
          <div className="relative w-full">
            <Input
              type="text"
              placeholder="Filter Past Queries"
              className="pl-9 w-full h-10 border border-gray-200 rounded-md text-sm bg-white"
              value={searchQuery}
              onChange={handleSearchChange}
            />
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
          </div>
        </div>

        <div className="relative">
          <div className="space-y-0">
            {isHistoryLoading ? (
              renderHistoryShimmerContent()
            ) : (
              <>
                {paginatedHistory.length > 0 ? (
                  paginatedHistory.map((item, index) => (
                    <div
                      key={item.id}
                      onClick={() => handleHistoryRowClick(item)}
                      className={`flex items-center px-4 py-3 hover:bg-gray-100 cursor-pointer transition-colors duration-150 ${
                        index % 2 === 0 ? "bg-white" : "bg-gray-50"
                      }`}
                    >
                      <TooltipProvider delayDuration={300}>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <p className="text-sm truncate min-w-0 flex-1">
                              {item.title}
                            </p>
                          </TooltipTrigger>
                          <TooltipContent
                            className="bg-white border border-gray-200 text-gray-900 text-xs px-3 py-2 max-w-[calc(100vw-40px)] sm:max-w-xs z-[100] shadow-md rounded-md"
                            side="top"
                            sideOffset={5}
                          >
                            {item.title}
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                      <span className="text-sm ml-3 mr-2 whitespace-nowrap hidden sm:inline-block">
                        {item.senderName}
                      </span>
                      <span className="text-xs mr-2 whitespace-nowrap flex-shrink-0">
                        {item.date}
                      </span>
                      <DropdownMenu>
                        <DropdownMenuTrigger
                          asChild
                          onClick={(e) => e.stopPropagation()}
                        >
                          <Button
                            variant="ghost"
                            size="sm"
                            className="h-7 w-7 p-0 hover:bg-gray-100 rounded-full flex-shrink-0"
                          >
                            <MoreHorizontal className="h-4 w-4 text-gray-400" />
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent
                          className="w-40 p-1 bg-white"
                          align="end"
                          alignOffset={-5}
                          sideOffset={8}
                          forceMount
                        >
                          <DropdownMenuItem
                            onClick={(e) => handleDelete(item.id, e)}
                            className="flex items-center px-2 py-2 text-sm text-red-600 hover:bg-red-50 hover:text-red-700"
                          >
                            <Trash2 className="mr-2 h-4 w-4" />
                            <span>Delete</span>
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </div>
                  ))
                ) : (
                  <div className="py-10 text-center text-gray-500 flex flex-col items-center">
                    <Search className="h-10 w-10 text-gray-300 mb-2" />
                    <p>No chat history found</p>
                    <p className="text-xs text-gray-400 mt-1">
                      Your conversations will appear here
                    </p>
                  </div>
                )}
              </>
            )}
          </div>
        </div>

        {paginatedHistory.length > 0 && (
          <div className="flex flex-col sm:flex-row justify-between items-center p-3 border-t border-gray-200 gap-2">
            <span className="text-sm text-gray-500 order-2 sm:order-1 text-center sm:text-left">
              Showing {paginatedHistory.length} of{" "}
              {sortedAndFilteredHistory.length} conversations
            </span>
            <div className="flex items-center space-x-2 order-1 sm:order-2">
              <span className="text-sm text-gray-500 hidden sm:inline">
                Rows per page
              </span>
              <Select
                value={rowsPerPage.toString()}
                onValueChange={(value) => setRowsPerPage(Number(value))}
              >
                <SelectTrigger className="w-[70px] focus:ring-0 bg-white">
                  <SelectValue placeholder={rowsPerPage.toString()} />
                </SelectTrigger>
                <SelectContent className="bg-white">
                  <SelectItem value="5">5</SelectItem>
                  <SelectItem value="10">10</SelectItem>
                  <SelectItem value="20">20</SelectItem>
                </SelectContent>
              </Select>
              <span className="text-sm text-gray-500 hidden md:inline">
                Page {currentPage} of{" "}
                {Math.max(
                  1,
                  Math.ceil(sortedAndFilteredHistory.length / rowsPerPage)
                )}
              </span>
              <div className="flex space-x-1">
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() =>
                    setCurrentPage((prev) => Math.max(prev - 1, 1))
                  }
                  disabled={currentPage === 1}
                >
                  <ChevronLeft className="h-4 w-4" />
                </Button>
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() =>
                    setCurrentPage((prev) =>
                      Math.min(
                        prev + 1,
                        Math.ceil(sortedAndFilteredHistory.length / rowsPerPage)
                      )
                    )
                  }
                  disabled={
                    currentPage ===
                      Math.ceil(
                        sortedAndFilteredHistory.length / rowsPerPage
                      ) || sortedAndFilteredHistory.length === 0
                  }
                >
                  <ChevronRight className="h-4 w-4" />
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
