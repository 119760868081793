import { useState, useCallback, useMemo, useRef, useEffect } from "react";
import {
  DataEditor,
  type Theme,
  type GridColumn,
  type GridCell,
  type EditableGridCell,
  type Item,
  type DataEditorProps,
  GridCellKind,
  type GridMouseEventArgs,
} from "@glideapps/glide-data-grid";
import "@glideapps/glide-data-grid/dist/index.css";
import { PDFPreviewSlider } from "../common/pdf/PDFPreviewSlider";
import { EmailPreviewSlider } from "../core/EmailPreviewSlider";
import { TableCell as TableCellType, Citation } from "../../types/table";

interface TableData {
  headers: string[];
  rows: { cells: TableCellType[] }[];
}

interface GridProps {
  tableData: TableData;
  filteredRows?: number[];
  pdfUrl?: string | null;
  emailHtml?: string | null;
  emailHighlightRanges?: Array<{ start: number; end: number }> | null;
  emailHeaders?: Record<string, string>;
  onCitationClick?: (citations: Citation[] | null) => Promise<void> | void;
  onCellEdited?: (
    cell: [number, number],
    newValue: string
  ) => Promise<void> | void;
  disableInlinePdfPreview?: boolean;
  editable?: boolean;
  filterQuery?: string;
  fetchTableLog?: () => Promise<void>;
}

interface TooltipState {
  message: string;
  x: number;
  y: number;
}

const getConfidenceInfo = (
  cell: TableCellType
): { score: number; message?: string } => {
  let score = 1.0;
  let message = null;

  if (cell.score != null && cell.score != undefined) {
    // handles both null and undefined
    score = cell.score;
  } else {
    score = 1.0;
  }

  if (cell.message != null && cell.message != undefined) {
    // handles both null and undefined
    message = cell.message;
  } else {
    message = null;
  }

  return { score: score, message: "AI Reason: " + message };
};

const hasEditHistory = (cell: TableCellType): boolean => {
  return Boolean(cell.edit_history && cell.edit_history.length > 0);
};

const formatTimestamp = (timestamp: string): string => {
  try {
    const date = new Date(timestamp);
    const now = new Date();
    const diffMs = now.getTime() - date.getTime();
    const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));

    if (diffDays === 0) {
      return `${date.toLocaleString("en-US", {
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      })}`;
    }

    if (diffDays === 1) {
      return "Yesterday";
    }

    if (diffDays < 7) {
      return date.toLocaleString("en-US", { weekday: "long" });
    }

    if (date.getFullYear() === now.getFullYear()) {
      return date.toLocaleString("en-US", {
        month: "short",
        day: "numeric",
      });
    }

    return date.toLocaleString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  } catch (e) {
    return timestamp;
  }
};

export const Grid: React.FC<GridProps> = ({
  tableData,
  filteredRows,
  pdfUrl,
  emailHtml,
  emailHighlightRanges,
  emailHeaders,
  onCitationClick,
  onCellEdited,
  disableInlinePdfPreview,
  editable = true,
  filterQuery = "",
  fetchTableLog,
}) => {
  const [isSliderOpen, setIsSliderOpen] = useState(false);
  const [selectedCitations, setSelectedCitations] = useState<Citation[] | null>(
    null
  );
  const clickTimeoutRef = useRef<number | undefined>(undefined);
  const [tooltip, setTooltip] = useState<TooltipState | undefined>();
  const timeoutRef = useRef<number>();
  const [hoverDisabled, setHoverDisabled] = useState(false);

  const headers = tableData.headers;
  const rows = tableData.rows;
  const ROW_COUNT = useMemo(() => {
    const query = filterQuery.toLowerCase().trim();
    if (!query) {
      return filteredRows ? filteredRows.length : rows.length;
    }

    const filteredData = (
      filteredRows ? filteredRows.map((i) => rows[i]) : rows
    ).filter((row) =>
      row.cells.some((cell) =>
        cell.value?.toString().toLowerCase().includes(query)
      )
    );

    return filteredData.length;
  }, [rows, filteredRows, filterQuery]);

  const customTheme = useMemo<Partial<Theme>>(
    () => ({
      baseFontStyle: "14px",
      editorFontSize: "14px",
      allowWrapping: true,
      allowOverlay: true,
      cellHorizontalPadding: 8,
      cellVerticalPadding: 8,
      headerFontStyle: "500 14px",
      textHeader: "#5d6174",
      bgHeader: "#ffffff",
      lineHeight: 1.5,
    }),
    []
  );

  const columns = useMemo<GridColumn[]>(() => {
    return headers.map((header, columnIndex) => {
      const maxLength = Math.max(
        header.length,
        ...rows.map((row) => {
          const cellValue = row.cells[columnIndex]?.value;
          return String(cellValue ?? "").length;
        })
      );

      let width;
      if (maxLength > 250) {
        width = 750;
      } else if (maxLength > 100) {
        width = 450;
      } else if (maxLength > 25) {
        width = 300;
      } else {
        width = 250;
      }

      let grow: number | undefined;
      if (headers.length === 1) {
        grow = 1;
      } else if (columnIndex === headers.length - 1) {
        grow = 1;
      } else if (maxLength > 100) {
        grow = 0.5;
      }

      return {
        title: header,
        width,
        grow,
      };
    });
  }, [headers, rows]);

  const rowHeights = useMemo(() => {
    const avgContentLength =
      rows.reduce((sum, row) => {
        const rowLength = row.cells.reduce(
          (total, cell) => total + String(cell.value || "").length,
          0
        );
        return sum + rowLength;
      }, 0) /
      (rows.length * headers.length);

    const MIN_ROW_HEIGHT = avgContentLength < 50 ? 72 : 64;

    return rows.map((row) => {
      let maxLines = 1;
      row.cells.forEach((cell) => {
        const content = String(cell.value || "");
        const lineCount = content.split("\n").length;
        const estimatedWrappedLines = Math.ceil(content.length / 65);
        maxLines = Math.max(maxLines, lineCount, estimatedWrappedLines);
      });

      const contentHeight = maxLines * 24;
      return Math.max(MIN_ROW_HEIGHT, contentHeight);
    });
  }, [rows, headers.length]);

  const getRowHeight = useCallback(
    (row: number) => {
      const actualRow = filteredRows ? filteredRows[row] : row;
      return rowHeights[actualRow] || 48;
    },
    [rowHeights, filteredRows]
  );

  const getContent = useCallback(
    (cell: Item): GridCell => {
      const [col, row] = cell;
      const query = filterQuery.toLowerCase().trim();
      let actualRow = filteredRows ? filteredRows[row] : row;

      if (query) {
        const filteredData = (
          filteredRows ? filteredRows.map((i) => rows[i]) : rows
        ).filter((row) =>
          row.cells.some((cell) =>
            cell.value?.toString().toLowerCase().includes(query)
          )
        );
        actualRow = rows.indexOf(filteredData[row]);
      }

      const cellData = rows[actualRow].cells[col];
      const value = cellData.value;
      const displayValue = value?.toString() ?? "";

      return {
        kind: GridCellKind.Text,
        data: displayValue,
        displayData: displayValue,
        allowOverlay: editable,
        readonly: !editable,
        contentAlign: "left",
        allowWrapping: true,
        themeOverride:
          col === 0
            ? {
                baseFontStyle: "500 14px -apple-system",
              }
            : undefined,
      };
    },
    [rows, filteredRows, editable, filterQuery]
  );

  const handleCellEdited = useCallback(
    async (cell: Item, newValue: EditableGridCell) => {
      if (newValue.kind !== GridCellKind.Text) return;

      if (onCellEdited) {
        await onCellEdited(cell as [number, number], newValue.data);
        if (fetchTableLog) {
          try {
            await fetchTableLog();
          } catch (error) {
            console.error("Failed to refresh table data:", error);
          }
        }
      }
    },
    [onCellEdited, fetchTableLog]
  );

  const onCellClicked = useCallback<
    NonNullable<DataEditorProps["onCellClicked"]>
  >(
    (cell) => {
      // Disable hover tooltips when cell is clicked
      setHoverDisabled(true);
      setTooltip(undefined);

      if (clickTimeoutRef.current) {
        clearTimeout(clickTimeoutRef.current);
        clickTimeoutRef.current = undefined;
        return;
      }

      clickTimeoutRef.current = window.setTimeout(() => {
        clickTimeoutRef.current = undefined;
        const [col, row] = cell;
        const actualRow = filteredRows ? filteredRows[row] : row;
        const cellData = rows[actualRow].cells[col];

        const citations =
          cellData.citations_with_documents &&
          cellData.citations_with_documents.length > 0
            ? cellData.citations_with_documents
            : null;

        if (onCitationClick) {
          onCitationClick(citations);
        }

        if (citations) {
          if (!disableInlinePdfPreview) {
            setSelectedCitations(citations);
            setIsSliderOpen(true);
          }
        } else {
          setSelectedCitations(null);
          setIsSliderOpen(false);
        }

        // Re-enable hover tooltips after click action is complete
        setTimeout(() => {
          setHoverDisabled(false);
        }, 500);
      }, 250);
    },
    [rows, filteredRows, onCitationClick, disableInlinePdfPreview]
  );

  const onCellActivated = useCallback<
    NonNullable<DataEditorProps["onCellActivated"]>
  >((_cell) => {
    if (clickTimeoutRef.current) {
      clearTimeout(clickTimeoutRef.current);
      clickTimeoutRef.current = undefined;
    }
  }, []);

  const drawCell = useCallback<NonNullable<DataEditorProps["drawCell"]>>(
    (args, draw) => {
      draw();

      const { ctx, rect, col, row } = args;

      const actualRow = filteredRows ? filteredRows[row] : row;
      const cellData = rows[actualRow].cells[col];

      // Check if the cell has edit history
      const hasHistory = hasEditHistory(cellData);

      const { score } = getConfidenceInfo(cellData);

      if (score < 0.8 && !hasHistory) {
        const size = 7;
        ctx.beginPath();
        ctx.moveTo(rect.x + rect.width - size, rect.y + 1);
        ctx.lineTo(rect.x + rect.width, rect.y + size + 1);
        ctx.lineTo(rect.x + rect.width, rect.y + 1);
        ctx.closePath();

        ctx.save();
        const color = score < 0.5 ? "#ff0000" : "#ffa500";
        ctx.fillStyle = color;
        ctx.fill();
        ctx.restore();
      }

      if (hasHistory) {
        const size = 7;
        ctx.beginPath();
        ctx.moveTo(rect.x + rect.width - size, rect.y + 1);
        ctx.lineTo(rect.x + rect.width, rect.y + size + 1);
        ctx.lineTo(rect.x + rect.width, rect.y + 1);
        ctx.closePath();

        ctx.save();
        ctx.fillStyle = "#22c55e"; // Green color
        ctx.fill();
        ctx.restore();
      }
    },
    [rows, filteredRows]
  );

  const onItemHovered = useCallback(
    (args: GridMouseEventArgs) => {
      if (hoverDisabled) {
        return;
      }

      if (args.kind === "cell") {
        window.clearTimeout(timeoutRef.current);
        setTooltip(undefined);

        const [col, row] = args.location;
        const actualRow = filteredRows ? filteredRows[row] : row;
        const cellData = rows[actualRow].cells[col];
        const { score, message } = getConfidenceInfo(cellData);
        const hasHistory = hasEditHistory(cellData);

        if (hasHistory) {
          timeoutRef.current = window.setTimeout(() => {
            if (cellData.edit_history && cellData.edit_history.length > 0) {
              const latestEdit =
                cellData.edit_history[cellData.edit_history.length - 1];
              const editorName = latestEdit.editor_name || "";
              const time = latestEdit.timestamp
                ? formatTimestamp(latestEdit.timestamp)
                : "";
              const originalValue = cellData.edit_history[0].value;
              const newValue = cellData.value;

              setTooltip({
                message: JSON.stringify({
                  type: "edit_history",
                  editorName,
                  time,
                  originalValue,
                  newValue,
                }),
                x: args.bounds.x + args.bounds.width / 2,
                y: args.bounds.y - 8,
              });
            }
          }, 500);
        } else if (score < 0.8 && message) {
          timeoutRef.current = window.setTimeout(() => {
            setTooltip({
              message,
              x: args.bounds.x + args.bounds.width / 2,
              y: args.bounds.y - 8,
            });
          }, 500);
        }
      } else {
        window.clearTimeout(timeoutRef.current);
        setTooltip(undefined);
      }
    },
    [rows, filteredRows, hoverDisabled]
  );

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        window.clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <div className="w-full flex">
      <div className="w-full">
        <DataEditor
          theme={customTheme}
          headerHeight={40}
          scaleToRem={true}
          getCellContent={getContent}
          columns={columns}
          rows={ROW_COUNT}
          onCellEdited={handleCellEdited}
          onCellClicked={onCellClicked}
          onCellActivated={onCellActivated}
          onItemHovered={onItemHovered}
          width="100%"
          // TODO: @Deepanshu - Check this one please
          height={Math.max(
            200,
            rowHeights.reduce((sum, height) => sum + height, 0) + 40
          )}
          rowHeight={getRowHeight}
          smoothScrollX={true}
          smoothScrollY={false}
          verticalBorder={true}
          drawCell={drawCell}
        />
      </div>
      {!disableInlinePdfPreview &&
        isSliderOpen &&
        (pdfUrl ? (
          <PDFPreviewSlider
            isOpen={isSliderOpen}
            onClose={() => {
              setIsSliderOpen(false);
              setSelectedCitations(null);
            }}
            pdfUrl={pdfUrl}
            citations={
              selectedCitations?.map((citation) => ({
                citation: citation.citation,
                state: "match",
              })) ?? null
            }
            isLoading={!pdfUrl}
          />
        ) : (
          emailHtml && (
            <EmailPreviewSlider
              isOpen={isSliderOpen}
              onClose={() => {
                setIsSliderOpen(false);
                setSelectedCitations(null);
              }}
              emailHtml={emailHtml}
              highlightRanges={emailHighlightRanges}
              headers={emailHeaders}
              isLoading={!emailHtml}
            />
          )
        ))}
      {tooltip && (
        <div
          style={{
            position: "fixed",
            left: tooltip.x,
            top: tooltip.y,
            transform: "translate(-50%, -100%)",
            padding: "0",
            backgroundColor: "white",
            borderRadius: "0",
            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
            pointerEvents: "none",
            zIndex: 40,
            minWidth: "250px",
            maxWidth: "400px",
            border: "1px solid #e5e7eb",
            overflow: "hidden",
          }}
        >
          {tooltip.message.startsWith("AI Reason:") ? (
            // Low confidence tooltip
            <div
              style={{
                borderLeft: "3px solid #E53E3E",
                padding: "12px",
                paddingLeft: "16px",
              }}
            >
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "#4B5563",
                  marginBottom: "6px",
                }}
              >
                Low Confidence
              </div>
              <div
                style={{
                  fontSize: "13px",
                  color: "#4B5563",
                  marginBottom: "8px",
                }}
              >
                AI has low confidence in this value
              </div>
              <div
                style={{
                  fontSize: "14px",
                  color: "#4B5563",
                  lineHeight: "1.4",
                  overflowWrap: "break-word",
                }}
              >
                {tooltip.message.replace("AI Reason: ", "")}
              </div>
            </div>
          ) : tooltip.message.startsWith("{") ? (
            (() => {
              try {
                const data = JSON.parse(tooltip.message);

                if (data.type === "edit_history") {
                  return (
                    <div
                      style={{
                        borderLeft: "3px solid #22c55e",
                        padding: "12px",
                        paddingLeft: "16px",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#4B5563",
                          marginBottom: "6px",
                        }}
                      >
                        Correction Saved
                      </div>
                      <div
                        style={{
                          fontSize: "13px",
                          color: "#4B5563",
                          marginBottom: "8px",
                        }}
                      >
                        Changes made by{" "}
                        <span style={{ color: "#3B82F6", fontWeight: "500" }}>
                          {data.editorName}
                        </span>
                      </div>
                      <div
                        style={{
                          fontSize: "14px",
                          color: "#4B5563",
                          display: "flex",
                          flexWrap: "wrap",
                          alignItems: "center",
                          gap: "4px",
                        }}
                      >
                        <span
                          style={{
                            fontWeight: "600",
                            color: "#4B5563",
                            overflowWrap: "break-word",
                            wordBreak: "break-all",
                            maxWidth: "100%",
                          }}
                        >
                          {data.originalValue}
                        </span>
                        <span
                          style={{
                            fontSize: "13px",
                            color: "#6B7280",
                            margin: "0 4px",
                          }}
                        >
                          replaced with
                        </span>
                        <span
                          style={{
                            color: "#22c55e",
                            fontWeight: "500",
                            overflowWrap: "break-word",
                            wordBreak: "break-all",
                            maxWidth: "100%",
                          }}
                        >
                          {data.newValue}
                        </span>
                      </div>
                    </div>
                  );
                }
              } catch (e) {
                return (
                  <div
                    style={{
                      padding: "12px",
                      overflowWrap: "break-word",
                      wordBreak: "break-all",
                    }}
                  >
                    {tooltip.message}
                  </div>
                );
              }
              return null;
            })()
          ) : (
            <div
              style={{
                padding: "12px",
                overflowWrap: "break-word",
                wordBreak: "break-all",
              }}
            >
              {tooltip.message}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
