import { useState, useRef, useCallback, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Button } from "../ui/button";
import { cn } from "../../lib/utils";
import {
  CircleCheck,
  ChevronRight,
  FileText,
  XCircle,
  Loader2,
  ThumbsUp,
  ThumbsDown,
  PenSquare,
  X,
} from "lucide-react";
import { withRequiredAuthInfo, UserClass } from "@propelauth/react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import DocumentPreviewDialog from "../DocumentPreviewDialog";
import { useNodeApi } from "../../hooks/useNodeApi";
import {
  WorkflowStep as ApiWorkflowStep,
  Citation,
  CitationWithState,
  getWorkflowV1,
  WorkflowExecutionLogResponse,
  UserDocumentUploadRequest,
  UserDocument,
  WorkflowStepInput,
  WorkflowStepOutput,
  type WorkflowV1 as WorkflowV1Type,
  VaultData,
} from "../../services/api";
import { usePolling } from "../../lib/usePolling";
import { WorkflowExecutionStatus } from "../../services/api";
import { toast } from "react-hot-toast";
import { PromptInput } from "../core/PromptInput";
import faiLogo from "../../assets/fai-logo-short.svg";
import { Canvas } from "../canvas/Canvas";
import { GuidelinesDetail } from "../uwaudit/GuidelinesDetail";
import { UwAuditDetail } from "../uwaudit/UwAuditDetail";
import { PDFPreviewSlider } from "../common/pdf/PDFPreviewSlider";
import { produce } from "immer";
import { useSharePoint } from "../../lib/sharepoint";
import { CompareDetailCanvas } from "../compare/CompareDetail_Canvas";
import { FillExcelDetail } from "../fillexcel/FillExcelDetail";
import { ExtractDetailCanvas } from "../extract/ExtractDetail_Canvas";
import { EmailDetail } from "../submissions/EmailDetail";
import { markdownComponents } from "../core/MarkdownComponents";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import { EmailPreviewSlider } from "../core/EmailPreviewSlider";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog";
import { GridContainer } from "../table/GridContainer";
import { TableContainer } from "../common/table/TableContainer";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  TooltipProvider,
} from "../ui/tooltip";
import { WelcomeSection } from "./WelcomeSection";
import { Input } from "../../components/ui/input";

interface WorkflowPresentationProps {
  executionId: string;
  accessToken: string;
  userClass: UserClass;
  isInCanvas?: boolean;
  fromLandingPage?: boolean; // New prop to track if user came from landing page
}

interface SubquestionData {
  subquestion: string;
  answer: string;
  documents?: string[];
  bbox_with_docs?: {
    document_id: string;
    bbox_values: Citation[];
  }[];
}

interface AnswerSnippet {
  text: string;
  document_id: string;
  bbox_values: Citation[];
}

interface SignatureInfo {
  document_name: string; // Keep this for backward compatibility
  signatures: Array<{
    signature_type?: string;
    is_signature_present?: boolean;
    is_date_present?: boolean;
    signer_name?: string;
    signature_date?: string;
    is_verified_digital?: boolean | null;
    is_scanned_handwritten?: boolean | null;
    signature_page_number?: string;
    signer_type?: string;
  }>;
}

interface StepOutput extends WorkflowStepOutput {
  result?:
    | {
        [className: string]: {
          document: string;
          filename: string;
          category: string;
        }[];
      }
    | string
    | {
        document_name: string;
        signatures: {
          signature_type?: string;
          is_signature_present?: boolean;
          is_date_present?: boolean;
          signer_name?: string;
          signature_date?: string;
          is_verified_digital?: boolean | null;
          is_scanned_handwritten?: boolean | null;
          signature_page_number?: string | null;
          signer_type?: string | null;
        }[];
      }[];
  link_to_excel_log?: string;
  link_to_compare_details?: string;
  link_to_extract_log?: string;
  table_log_id?: string;
  email_body?: string;
  email_body_button_text?: string;
  email_component_title?: string;
  attachments?: Array<{ user_document_id: string; filename: string }>;
  headers?: Record<string, string>;
}

interface StepInput extends Omit<WorkflowStepInput, "document"> {
  classes?: Array<{
    name: string;
    description: string;
  }>;
  document?: {
    user_document_id: string;
    filename: string;
    category: string;
    file_hash: string;
  };
}

interface UIWorkflowStep {
  id: string;
  title: string;
  stepType: string;
  documentTypes?: string[];
  input?: StepInput;
  output?: StepOutput;
  submissionType?: string;
  auditLink?: string;
  compareLink?: string;
  documents?: {
    name: string;
    documentId: string;
    fileType?: string;
    className?: string;
  }[];
  guidelines?: string[];
  titles?: string[];
  question?: string;
  answerSnippets?: AnswerSnippet[];
  signatureAnalysis?: SignatureInfo[];
}

interface Message {
  content: string;
  role: "user" | "assistant";
  timestamp: Date;
  icon?: React.ReactNode;
  attachments?: UserDocument[];
  headers?: Record<string, string>;
  citations?: {
    document_id: string;
    bbox_values: Citation[];
  }[];
  tableLogId?: string;
  tableRows?: number[];
  email_html?: string;
  subquestions?: string[];
  subquestion?: string;
  type?:
    | "subquestion_details"
    | "subquestion"
    | "final_answer"
    | "subquestion_citations"
    | "subquestion_token"
    | "token";
  pending_citations?: boolean;
}

interface ChatMessage {
  timestamp: string;
  question: string;
  answer: string;
  documents: string[];
  bbox_with_docs?: {
    document_id: string;
    bbox_values: Citation[];
  }[];
  table_log_id?: string;
  table_rows?: number[];
  email_html?: string;
  subquestion_data?: SubquestionData[];
}

interface RawPDFCitation {
  content: string;
  bbox: {
    page: number;
    left: number;
    top: number;
    width: number;
    height: number;
  };
}

interface RawEmailCitation {
  content: string;
  start: number;
  end: number;
}

type RawCitation = RawPDFCitation | RawEmailCitation;

interface CitationWithDocId extends CitationWithState {
  documentId: string;
  citationType: "pdf" | "email";
  start?: number;
  end?: number;
}

interface DocumentReference {
  id: string;
  filename: string;
  startIndex: number;
  endIndex: number;
}

interface ParsedMessage {
  rawContent: string;
  references: DocumentReference[];
}

interface MessageContentProps {
  content: string;
  role: "user" | "assistant";
  citations?: { document_id: string; bbox_values: Citation[] }[];
  setIsFeedbackOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  executionLog?: WorkflowExecutionLogResponse | null;
}

interface TranslationDocument {
  user_document_id: string;
  filename: string;
  category: string;
  file_hash: string;
  original_document_id: string | null;
}

interface TranslationInput {
  documents: TranslationDocument[];
  target_language: string;
}

interface TranslationMapping {
  [documentId: string]: {
    originalDocId: string | null;
    translatedDocId: string | null;
    filename: string;
  };
}

function isTranslationInput(input: unknown): input is TranslationInput {
  if (!input || typeof input !== "object") return false;
  const typedInput = input as TranslationInput;

  return (
    Array.isArray(typedInput.documents) &&
    typeof typedInput.target_language === "string" &&
    typedInput.documents.every(
      (doc) =>
        typeof doc.user_document_id === "string" &&
        typeof doc.filename === "string" &&
        (doc.original_document_id === null ||
          typeof doc.original_document_id === "string")
    )
  );
}

function isTranslationResult(result: unknown): result is TranslationDocument[] {
  return (
    Array.isArray(result) &&
    result.length > 0 &&
    result.every(
      (doc) =>
        "user_document_id" in doc &&
        "original_document_id" in doc &&
        "filename" in doc
    )
  );
}

function findTranslationStep(steps: ApiWorkflowStep[]) {
  return steps.find(
    (step) =>
      step.step_type === "wait" &&
      isTranslationInput(step.input) &&
      isTranslationResult(step.output?.result)
  );
}

const getTranslationInfo = (steps: ApiWorkflowStep[]): TranslationMapping => {
  const translationStep = findTranslationStep(steps);
  if (
    !translationStep ||
    !isTranslationResult(translationStep.output?.result)
  ) {
    return {};
  }

  const translationResult = translationStep.output.result;

  return translationResult.reduce((mapping, doc) => {
    if (doc.original_document_id) {
      mapping[doc.user_document_id] = {
        originalDocId: doc.original_document_id,
        translatedDocId: doc.user_document_id,
        filename: doc.filename,
      };

      mapping[doc.original_document_id] = {
        originalDocId: doc.original_document_id,
        translatedDocId: doc.user_document_id,
        filename: doc.filename,
      };
    } else {
      mapping[doc.user_document_id] = {
        originalDocId: null,
        translatedDocId: null,
        filename: doc.filename,
      };
    }
    return mapping;
  }, {} as TranslationMapping);
};

const transformStepToUIFormat = (step: ApiWorkflowStep): UIWorkflowStep => {
  if (step.step_type === "classify_submission_type") {
    const result = step.output?.result as
      | { submission_type: string }
      | undefined;
    return {
      id: `${step.step_type}_${step.title.slice(0, 20).replace(/\s+/g, "_")}`,
      title: step.title,
      stepType: step.step_type,
      documentTypes: step.input?.classes?.map((c) => c.name) || [],
      submissionType: result?.submission_type,
      input: step.input,
      output: step.output,
    };
  }

  if (step.step_type === "classify_documents" && step.output?.result) {
    const documents: {
      name: string;
      documentId: string;
      fileType?: string;
      className: string;
    }[] = [];

    const documentTypes = step.input?.classes?.map((c) => c.name) || [];

    Object.entries(step.output.result).forEach(([className, docs]) => {
      if (Array.isArray(docs)) {
        const transformedDocs = docs.map((doc) => ({
          name: doc.filename,
          documentId: doc.user_document_id,
          fileType: doc.filename.split(".").pop()?.toLowerCase(),
          className,
        }));
        documents.push(...transformedDocs);
      }
    });

    return {
      id: step.step_type,
      title: step.title,
      stepType: step.step_type,
      documents: documents.length > 0 ? documents : undefined,
      documentTypes: documentTypes,
      input: step.input,
      output: step.output,
    };
  }

  if (step.step_type === "extract_guidelines") {
    return {
      id: step.step_type,
      title: step.title,
      stepType: step.step_type,
      guidelines: step.output?.guidelines,
      titles: step.output?.titles,
    };
  }

  if (step.step_type === "check_guidelines") {
    return {
      id: step.step_type,
      title: step.title,
      stepType: step.step_type,
      auditLink: step.output?.link_to_audit_details,
    };
  }

  if (step.step_type === "compare_with_schema") {
    return {
      id: step.step_type,
      title: step.title,
      stepType: step.step_type,
      compareLink: step.output?.link_to_compare_details,
    };
  }

  if (step.step_type === "qa") {
    return {
      id: step.step_type,
      title: step.title,
      stepType: step.step_type,
      question: step.input?.question,
      answerSnippets: step.output?.answer_snippets_with_bbox_values?.map(
        (snippet) => ({
          text: snippet.text,
          document_id: snippet.document_id,
          bbox_values: snippet.bbox_values.map((bbox) => ({
            type: bbox.type,
            bbox: bbox.bbox,
            content: bbox.content || "",
            image_url: bbox.image_url || null,
          })),
        })
      ),
    };
  }

  if (step.step_type === "sov_mapping") {
    return {
      id: step.step_type,
      title: step.title,
      stepType: step.step_type,
      output: {
        link_to_excel_log: step.output?.link_to_excel_log,
      },
    };
  }

  if (
    step.step_type === "extract_from_text" ||
    step.step_type === "extract_from_document" ||
    step.step_type === "extract_rows_from_multiple_documents"
  ) {
    return {
      id: step.step_type,
      title: step.title,
      stepType: step.step_type,
      output: {
        link_to_extract_log: step.output?.link_to_extract_log,
        table_log_id: step.output?.table_log_id,
      },
    };
  }

  if (step.step_type === "email") {
    return {
      id: step.step_type,
      title: step.title,
      stepType: step.step_type,
      output: {
        email_body: step.output?.email_body,
        email_body_button_text: step.output?.email_body_button_text,
        email_component_title: step.output?.email_component_title,
        attachments: step.output?.attachments,
        headers: step.output?.headers,
      },
    };
  }

  if (step.step_type === "signature_analysis") {
    const result = step.output?.result as SignatureInfo[];

    return {
      id: step.step_type,
      title: step.title,
      stepType: step.step_type,
      input: step.input,
      signatureAnalysis: result?.map((item) => ({
        document_name:
          step.input?.document?.filename || item.document_name || "",
        signatures: item.signatures,
      })),
    };
  }

  return {
    id: step.step_type,
    title: step.title,
    stepType: step.step_type,
  };
};

const parseMessageReferences = (content: string): ParsedMessage => {
  const references: DocumentReference[] = [];
  const pattern = /@([^<]+)<document_start>([^<]+)<document_end>/g;

  const allMatches: {
    filename: string;
    docId: string;
    index: number;
    length: number;
  }[] = [];

  let match;
  while ((match = pattern.exec(content)) !== null) {
    const [fullMatch, filename, docId] = match;

    const displayName =
      filename.length > 40 ? filename.substring(0, 37) + "..." : filename;

    allMatches.push({
      filename: displayName.trim(),
      docId,
      index: match.index,
      length: fullMatch.length,
    });
  }

  allMatches.sort((a, b) => a.index - b.index);

  let lastIndex = 0;
  let processedContent = "";

  allMatches.forEach((match) => {
    processedContent += content.slice(lastIndex, match.index);

    references.push({
      id: match.docId,
      filename: match.filename,
      startIndex: processedContent.length,
      endIndex: processedContent.length + match.filename.length + 1,
    });

    processedContent += `@${match.filename}`;
    lastIndex = match.index + match.length;
  });

  processedContent += content.slice(lastIndex);

  return {
    rawContent: processedContent,
    references,
  };
};

const renderMessageContent = ({
  content,
  role,
  citations,
  executionLog,
}: MessageContentProps) => {
  if (role === "assistant" && citations) {
    let citationCounter = 1;
    return (
      <div className="inline leading-normal">
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          rehypePlugins={[rehypeRaw]}
          components={{
            ...markdownComponents,
            p: ({ children }) => (
              <span className="inline whitespace-pre-wrap block-if-multiline">
                {children}
              </span>
            ),
            ul: ({ children }) => (
              <ul className="block list-disc pl-6 space-y-2 w-full my-2">
                {children}
              </ul>
            ),
            ol: ({ children }) => (
              <ol className="block list-decimal pl-6 space-y-2 w-full my-2">
                {children}
              </ol>
            ),
            li: ({ children }) => (
              <li className="text-sm leading-relaxed">{children}</li>
            ),
            h1: ({ children }) => (
              <h1 className="block text-2xl font-bold my-3">{children}</h1>
            ),
            h2: ({ children }) => (
              <h2 className="block text-xl font-bold my-2">{children}</h2>
            ),
            h3: ({ children }) => (
              <h3 className="block text-lg font-bold my-2">{children}</h3>
            ),
          }}
          className="prose prose-sm max-w-none inline [&>*:not(h1,h2,h3,table,ul,ol)]:inline [&>*:not(h1,h2,h3,table,ul,ol)]:m-0 [&>*:not(h1,h2,h3,table,ul,ol)]:p-0 [&>ol:first-child]:-mt-1 [&>ul:first-child]:-mt-1 [&>h1:first-child]:mt-0 [&>h2:first-child]:mt-0 [&>h3:first-child]:mt-0 [&>:not(table)+table]:mt-4"
        >
          {content}
        </ReactMarkdown>
        <span className="inline-flex items-center gap-0.5 ml-1">
          {citations.map((doc, docIndex) =>
            doc.bbox_values.map((bbox, bboxIndex) => {
              const currentCitation = citationCounter++;
              const documentName =
                executionLog?.all_workflow_execution_documents?.find(
                  (document) => document.user_document_id === doc.document_id
                )?.filename || "Unknown Document";

              return (
                <TooltipProvider
                  key={`citation-${docIndex}-${bboxIndex}`}
                  delayDuration={300}
                >
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <button
                        type="button"
                        className="citation-link inline-flex items-center justify-center rounded-full bg-gray-200 w-[18px] h-[18px] text-xs font-medium text-gray-700 hover:bg-gray-300 transition-colors cursor-pointer no-underline align-baseline"
                        data-citation-index={bboxIndex}
                        data-document-id={doc.document_id}
                        data-citation-content={bbox.content}
                        aria-label={`Citation ${currentCitation}`}
                      >
                        {currentCitation}
                      </button>
                    </TooltipTrigger>
                    <TooltipContent
                      side="top"
                      align="start"
                      sideOffset={5}
                      className="bg-stone-50 p-2 w-[300px]"
                    >
                      <div className="flex flex-col gap-2 text-sm mb-2 break-words">
                        <span className="font-medium">{documentName}</span>
                        {bbox.bbox?.page && (
                          <span>Page: {bbox.bbox.page || "N/A"}</span>
                        )}
                      </div>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              );
            })
          )}
        </span>
      </div>
    );
  }

  if (role === "user") {
    const { rawContent, references } = parseMessageReferences(content);

    if (references.length === 0) {
      return <span className="whitespace-pre-wrap">{content}</span>;
    }

    const parts: JSX.Element[] = [];
    let lastIndex = 0;

    references.forEach((ref, index) => {
      if (ref.startIndex > lastIndex) {
        parts.push(
          <span key={`text-${index}`} className="whitespace-pre-wrap">
            {rawContent.slice(lastIndex, ref.startIndex)}
          </span>
        );
      }

      parts.push(
        <span
          key={`ref-${ref.id}`}
          className="inline bg-purple-200/50 text-purple-900 rounded-md px-1"
        >
          <span className="opacity-50">@</span>
          {ref.filename}
        </span>
      );

      lastIndex = ref.startIndex + `@${ref.filename}`.length;
    });

    if (lastIndex < rawContent.length) {
      parts.push(
        <span key="text-end" className="whitespace-pre-wrap">
          {rawContent.slice(lastIndex)}
        </span>
      );
    }

    return <>{parts}</>;
  }

  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[rehypeRaw]}
      components={markdownComponents}
      className="prose prose-sm max-w-none"
    >
      {content}
    </ReactMarkdown>
  );
};

const hasDetailView = (stepType: string): boolean => {
  return [
    "classify_submission_type",
    "classify_documents",
    "extract_guidelines",
    "check_guidelines",
    "compare_with_schema",
    "qa",
    "sov_mapping",
    "extract_from_text",
    "extract_from_document",
    "extract_rows_from_multiple_documents",
    "email",
    "signature_analysis",
  ].includes(stepType);
};

const truncateTitle = (title: string, maxLength = 55) => {
  if (title.length <= maxLength) return title;
  return `${title.slice(0, maxLength)}...`;
};

const U_Extract_Workflow_Id = "7e251d79-7f44-46b5-b634-d2d0c6103738"; // Upland workflow

const WorkflowPresentation = ({
  executionId,
  accessToken,
  userClass,
  isInCanvas: initialIsInCanvas = false,
  fromLandingPage = false,
}: WorkflowPresentationProps) => {
  const org = userClass?.getOrgs()[0];
  const useNewWorkflowFlow = true;
  const navigate = useNavigate();
  // Define the Ask AI workflow ID
  const ASK_AI_WORKFLOW_ID = process.env.REACT_APP_ASK_AI_WORKFLOW_ID;
  const [workflowName, setWorkflowName] = useState<string>("");
  const [workflowId, setWorkflowId] = useState<string | null>(null);
  const [workflowData, setWorkflowData] = useState<WorkflowV1Type | null>(null);
  const [steps, setSteps] = useState<UIWorkflowStep[]>([]);
  const [executionLog, setExecutionLog] =
    useState<WorkflowExecutionLogResponse | null>(null);
  const [selectedVault, setSelectedVault] = useState<VaultData | null>(null);
  const [previewDocument, setPreviewDocument] = useState<{
    url: string;
    fileType?: string;
  } | null>(null);
  const [selectedDocTypes, setSelectedDocTypes] = useState<
    Record<string, string>
  >({});
  const selectedDocTypeRef = useRef<string>("");
  const {
    fetchWorkflowExecutionLog,
    getPdfUrl,
    fetchWorkkflowExecutionAskAI,
    fetchWorkkflowExecutionAskAIStream,
    uploadDocument,
    deleteDocument,
    fetchUserDocument,
    fetchTableLog,
    fetchEmailDetails,
    submitFeedback,
    resumeWorkflow,
    getExecutionTitle,
    updateExecutionTitle,
    fetchVaults,
  } = useNodeApi(accessToken);
  const lastPollTimeRef = useRef(0);
  const [isStreaming, setIsStreaming] = useState(false);
  const [isLoadingStable, setIsLoadingStable] = useState(true);
  const [messages, setMessages] = useState<Message[]>([]);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [expandedSteps, setExpandedSteps] = useState<{
    [key: string]: boolean;
  }>({});
  const [rightContent, setRightContent] = useState<React.ReactNode>(null);
  const [isInCanvas, setIsInCanvas] = useState(initialIsInCanvas);
  const [currentPdfUrl, setCurrentPdfUrl] = useState<string | null>(null);
  const [isLoadingPdf, setIsLoadingPdf] = useState(false);
  const [isPdfSliderOpen, setIsPdfSliderOpen] = useState(false);
  const [selectedCitations, setSelectedCitations] = useState<
    CitationWithDocId[]
  >([]);
  const [pdfUrls, setPdfUrls] = useState<Map<string, string>>(new Map());
  const [uploadedDocuments, setUploadedDocuments] = useState<UserDocument[]>(
    []
  );
  const [chatDocuments, setChatDocuments] = useState<UserDocument[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isWorkflowNameLoading, setIsWorkflowNameLoading] = useState(true);
  const [isStepsLoading, setIsStepsLoading] = useState(true);
  const [currentFileType, setCurrentFileType] = useState<"pdf" | "excel">(
    "pdf"
  );
  const [filteredDocumentIds, setFilteredDocumentIds] = useState<string[]>([]);
  const [shouldInvalidateCache, setShouldInvalidateCache] = useState(false);
  const [workflowTitle, setWorkflowTitle] = useState<string>("");
  const [originalPdfUrl, setOriginalPdfUrl] = useState<string | null>(null);
  const [isEmailSliderOpen, setIsEmailSliderOpen] = useState(false);
  const [currentEmailHtml, setCurrentEmailHtml] = useState<string | null>(null);
  // const [emailHighlightText, setEmailHighlightText] = useState<string | null>(null);
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
  const [feedbackSubmitted, setFeedbackSubmitted] = useState<{
    [key: string]: boolean;
  }>({});
  const [activeFeedbackId, setActiveFeedbackId] = useState("");
  const [messageFeedbackTypes, setMessageFeedbackTypes] = useState<{
    [key: string]: 1 | -1;
  }>({});
  const [emailHighlightRange, setEmailHighlightRange] = useState<{
    start: number;
    end: number;
  } | null>(null);
  const [showWelcomeSection, setShowWelcomeSection] =
    useState(useNewWorkflowFlow);
  // Add a ref to track if the initial message has been processed
  const initialMessageProcessedRef = useRef(false);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [editedTitle, setEditedTitle] = useState("");
  const [isSavingTitle, setIsSavingTitle] = useState(false);
  const [isUserAtBottom, setIsUserAtBottom] = useState(true);
  // Add this near the other state declarations

  /**
   * Helper that maps a raw citation (from bbox_values) to our internal type.
   * It determines the type based on whether a `bbox` exists.
   */
  const mapCitation = useCallback(
    (docId: string, raw: RawCitation): CitationWithDocId => {
      if ("bbox" in raw) {
        // PDF citation conversion:
        const pdfCitation: Citation = {
          type: "pdf", // assign a default type
          bbox: raw.bbox, // copy the bbox object
          content: raw.content,
          image_url: null, // no image URL provided
        };
        return {
          citation: pdfCitation,
          state: "match",
          documentId: docId,
          citationType: "pdf",
        };
      } else {
        // Email citation conversion:
        // Since Email citations don't have bbox, we provide a dummy bbox.
        const emailCitation: Citation = {
          type: "email",
          bbox: {
            left: 0,
            top: 0,
            width: 0,
            height: 0,
            page: 0,
          },
          content: raw.content,
          image_url: null,
        };
        return {
          citation: emailCitation,
          state: "match",
          documentId: docId,
          citationType: "email",
          start: raw.start,
          end: raw.end,
        };
      }
    },
    []
  );

  const { selectAndUploadFile, isLoading: isSharePointLoading } = useSharePoint(
    uploadDocument,
    setUploadedDocuments,
    process.env.REACT_APP_SHAREPOINT_CLIENT_ID || "",
    process.env.REACT_APP_TENANT_ID || "",
    org?.orgMetadata["SHAREPOINT_HOSTNAME"] || ""
  );

  const isWorkflowComplete = useCallback(
    (log: WorkflowExecutionLogResponse): boolean => {
      return (
        log.status === WorkflowExecutionStatus.COMPLETED ||
        log.status === WorkflowExecutionStatus.FAILED ||
        log.status === WorkflowExecutionStatus.SUSPENDED
      );
    },
    []
  );

  // Check initial status when component mounts
  useEffect(() => {
    const checkInitialStatus = async () => {
      try {
        const log = await fetchWorkflowExecutionLog(executionId);

        if (log.status === WorkflowExecutionStatus.NOT_STARTED) {
          setIsLoadingStable(false);
          setIsStepsLoading(false);
        }

        // Todo - make vault fetch conditional to wf id
        if (org) {
          try {
            const vaultsData = await fetchVaults();
            if (vaultsData && vaultsData.length > 0) {
              setSelectedVault(vaultsData[0]);
            }
          } catch (err) {
            console.error("Failed to load vaults:", err);
          }
        }
      } catch (error) {
        console.error("Failed to fetch initial workflow status:", error);
      }
    };

    checkInitialStatus();
  }, [executionId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (workflowId) {
      setIsWorkflowNameLoading(true);

      const loadWorkflowName = async () => {
        try {
          const workflow = await getWorkflowV1(workflowId, accessToken);
          setWorkflowName(workflow.name);
          setWorkflowData(workflow);
        } catch (error) {
          console.error("Failed to fetch workflow name:", error);
        } finally {
          setIsWorkflowNameLoading(false);
        }
      };

      loadWorkflowName();
    }
  }, [workflowId, accessToken]); // eslint-disable-line react-hooks/exhaustive-deps

  usePolling(
    async () => {
      const log = await fetchWorkflowExecutionLog(executionId);
      return log;
    },
    {
      interval: 2000,
      enabled: true,
      continuePollingOnSuccess: true,
      isComplete: (data) => {
        return isWorkflowComplete(data);
      },
      lastPollTimeRef,
      onSuccess: (log: WorkflowExecutionLogResponse) => {
        setExecutionLog(log);
        setWorkflowId(log.request.workflow_id);

        // If status is not_started, set loading states to false immediately and return
        if (log.status === WorkflowExecutionStatus.NOT_STARTED) {
          setIsLoadingStable(false);
          setIsStepsLoading(false);
          return;
        }

        if (log.result?.steps) {
          const uiSteps = log.result.steps.map((step) =>
            transformStepToUIFormat(step)
          );
          setSteps(uiSteps);
          setIsStepsLoading(false);
        }

        if (log.chat_history?.length && messages.length === 0) {
          const uiMessages = log.chat_history.flatMap((chat: ChatMessage) => {
            const messages: Message[] = [
              {
                role: "user" as const,
                content: chat.question,
                timestamp: new Date(chat.timestamp),
                attachments:
                  chat.documents?.map((docId) => ({
                    _id: docId,
                    filename:
                      log.all_workflow_execution_documents?.find(
                        (doc) => doc.user_document_id === docId
                      )?.filename || "",
                    blob_url: "",
                    category: "chat",
                    origin: "workflow",
                    owner_uid: "",
                    owner_oid: "",
                    run_id: executionId,
                    description: "",
                    user_data: {},
                  })) || [],
              },
            ];

            if (chat.subquestion_data && chat.subquestion_data.length > 0) {
              messages.push({
                role: "assistant" as const,
                content: "Breaking down your question into parts:",
                timestamp: new Date(chat.timestamp),
                type: "subquestion_details",
                subquestions: chat.subquestion_data.map(
                  (subq: SubquestionData) => subq.subquestion
                ),
              });

              chat.subquestion_data.forEach((subq: SubquestionData) => {
                messages.push({
                  role: "assistant" as const,
                  content: subq.answer,
                  timestamp: new Date(chat.timestamp),
                  type: "subquestion",
                  subquestion: subq.subquestion,
                  citations: subq.bbox_with_docs,
                });
              });
            }

            messages.push({
              role: "assistant" as const,
              content: chat.answer,
              timestamp: new Date(chat.timestamp),
              citations: chat.bbox_with_docs,
              tableLogId: chat.table_log_id ? chat.table_log_id : "",
              tableRows: chat.table_rows ? chat.table_rows : [],
              email_html: chat.email_html,
            });

            return messages;
          });

          setMessages(uiMessages);
        }

        // Only set the stable loading state to false once we have confirmed data is loaded
        if (
          log &&
          (log.status !== WorkflowExecutionStatus.RUNNING ||
            messages.length > 0 ||
            (log.result?.steps && log.result.steps.length > 0))
        ) {
          setIsLoadingStable(false);
        }
      },
      onError: (error: unknown) => {
        console.error("Failed to fetch workflow execution log:", error);
        setIsLoadingStable(false);
        setIsStepsLoading(false);
      },
    }
  );

  useEffect(() => {
    if (steps && steps.length > 0 && !selectedDocTypeRef.current) {
      const classifyStep = steps.find(
        (step) => step.stepType === "classify_documents"
      );
      const documentTypes = classifyStep?.documentTypes;

      if (documentTypes && documentTypes.length > 0) {
        setSelectedDocTypes((prev) => ({
          ...prev,
          [classifyStep.id]: documentTypes[0],
        }));
        selectedDocTypeRef.current = documentTypes[0];
      }
    }
  }, [steps]);

  const handleDocTypeChange = (value: string, stepId: string) => {
    setSelectedDocTypes((prev) => ({
      ...prev,
      [stepId]: value,
    }));
  };

  const filterDocumentsByType = (
    step: UIWorkflowStep,
    selectedDocType: string
  ) => {
    if (step.stepType !== "classify_documents" || !step.documents) {
      return [];
    }
    return step.documents.filter((doc) => doc.className === selectedDocType);
  };

  const getStepStatus = useCallback(
    (stepId: string, stepIndex: number) => {
      if (!executionLog) return "pending";
      if (!executionLog.result) return "pending";

      if (executionLog.status === WorkflowExecutionStatus.FAILED) {
        return "failed";
      }

      const currentStep = executionLog.result.steps[stepIndex];
      if (!currentStep) return "pending";

      const activeStepIndex = executionLog.result.steps.findIndex(
        (step) => !step.output || Object.keys(step.output).length === 0
      );

      if (executionLog.status === WorkflowExecutionStatus.RUNNING) {
        if (stepIndex === activeStepIndex) {
          return "running";
        }

        if (activeStepIndex === -1 || stepIndex < activeStepIndex) {
          return "completed";
        }

        return "pending";
      }

      if (executionLog.status === WorkflowExecutionStatus.COMPLETED) {
        const hasValidOutput =
          currentStep.output &&
          Object.keys(currentStep.output).length > 0 &&
          Object.values(currentStep.output).some(
            (value) =>
              value !== null &&
              value !== undefined &&
              (typeof value === "object" ? Object.keys(value).length > 0 : true)
          );
        return hasValidOutput ? "completed" : "pending";
      }

      return "pending";
    },
    [executionLog]
  );

  const getLoadingText = (stepTitle: string) => {
    if (stepTitle.toLowerCase().includes("classify"))
      return "Classifying documents...";
    if (stepTitle.toLowerCase().includes("extract"))
      return "Extracting data...";
    return "Processing...";
  };

  const handleDocumentClick = async (doc: {
    documentId: string;
    fileType?: string;
  }) => {
    if (
      doc.fileType === "pdf" ||
      doc.fileType === "xlsx" ||
      doc.fileType === "image"
    ) {
      try {
        if (doc.fileType === "xlsx") {
          doc.fileType = "excel";
        }

        const translationMapping = getTranslationInfo(
          (executionLog?.result?.steps as ApiWorkflowStep[]) || []
        );
        const documentInfo = translationMapping[doc.documentId];

        if (documentInfo) {
          const url = await getPdfUrl(doc.documentId);

          if (documentInfo.originalDocId) {
            setPreviewDocument({ url, fileType: doc.fileType });
            const originalUrl = await getPdfUrl(documentInfo.originalDocId);
            setOriginalPdfUrl(originalUrl);
          } else if (documentInfo.translatedDocId) {
            const translatedUrl = await getPdfUrl(documentInfo.translatedDocId);
            setPreviewDocument({ url: translatedUrl, fileType: doc.fileType });
            setOriginalPdfUrl(url);
          } else {
            setPreviewDocument({ url, fileType: doc.fileType });
            setOriginalPdfUrl(null);
          }
        } else {
          const url = await getPdfUrl(doc.documentId);
          setPreviewDocument({ url, fileType: doc.fileType });
          setOriginalPdfUrl(null);
        }
      } catch (error) {
        console.error("Failed to fetch PDF URL:", error);
        toast.error("Unable to preview document. Please try again later.");
      }
    }
  };

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newFiles = event.target.files;
    if (newFiles && uploadDocument) {
      setIsUploading(true);
      try {
        const validFiles = Array.from(newFiles).filter((file) => {
          const fileType = file.type.toLowerCase();
          const fileName = file.name.toLowerCase();
          return (
            fileType === "application/pdf" ||
            fileType ===
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
            fileType ===
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
            fileType === "message/rfc822" ||
            fileType === "application/vnd.ms-outlook" ||
            fileType === "application/zip" ||
            fileType === "application/x-zip-compressed" ||
            fileType === "image/jpeg" ||
            fileType === "image/png" ||
            fileName.endsWith(".eml") ||
            fileName.endsWith(".msg") ||
            fileName.endsWith(".zip")
          );
        });

        for (const file of validFiles) {
          try {
            const uploadRequest: UserDocumentUploadRequest = {
              filename: file.name,
              blob_url: "",
              origin: "workflow",
              category: "chat",
              owner_uid: "",
              owner_oid: "",
              run_id: executionId,
            };

            const result = await uploadDocument(file, uploadRequest);
            if (result) {
              const { user_document_id, sas_url } = result;
              const newDocument: UserDocument = {
                _id: user_document_id,
                filename: file.name,
                blob_url: sas_url,
                category: "chat",
                origin: "workflow",
                owner_uid: "",
                owner_oid: "",
                run_id: executionId,
                description: "",
                user_data: {},
              };
              setChatDocuments((prev) => [...prev, newDocument]);
              setUploadedDocuments((prev) => [...prev, newDocument]);

              // If workflow status is not_started, resume the workflow after uploading the document
              if (
                executionLog?.status === WorkflowExecutionStatus.NOT_STARTED
              ) {
                try {
                  await resumeWorkflow(executionId, {}, [user_document_id]);

                  // Refresh the workflow execution log to get the updated status
                  const updatedLog = await fetchWorkflowExecutionLog(
                    executionId
                  );
                  setExecutionLog(updatedLog);
                } catch (error) {
                  console.error("Failed to resume workflow:", error);
                  toast.error("Failed to resume workflow");
                }
              }
            }
          } catch (error) {
            console.error(`Failed to upload file ${file.name}:`, error);
            toast.error(`Failed to upload file ${file.name}`);
          }
        }
      } catch (error) {
        console.error("Failed to upload files:", error);
        toast.error("Failed to upload files");
      } finally {
        setIsUploading(false);
        if (event.target) {
          event.target.value = "";
        }
      }
    }
  };

  const removeFile = async (filename: string) => {
    const documentToRemove = chatDocuments.find(
      (doc) => doc.filename === filename
    );
    if (documentToRemove && deleteDocument) {
      try {
        await deleteDocument(documentToRemove._id);
        setChatDocuments((prev) =>
          prev.filter((doc) => doc.filename !== filename)
        );
        setUploadedDocuments((prev) =>
          prev.filter((doc) => doc.filename !== filename)
        );
        toast.success(`Removed ${filename}`);
      } catch (error) {
        console.error(`Failed to remove file ${filename}:`, error);
        toast.error(`Failed to remove file ${filename}`);
      }
    }
  };

  const handleSendMessage = useCallback(
    async (
      message: string,
      documentIds?: string[],
      useSubquestions?: boolean,
      skipUserMessage?: boolean
    ) => {
      if (!message.trim() && !documentIds?.length && !uploadedDocuments.length)
        return;
      setIsStreaming(true);

      try {
        // Get the document objects from the provided documentIds or use uploadedDocuments
        const attachmentsToUse = documentIds
          ? documentIds
              .map((id) => {
                const doc = chatDocuments.find((d) => d._id === id);
                return doc || null;
              })
              .filter((doc): doc is UserDocument => doc !== null)
          : uploadedDocuments;

        // Only add the user message if skipUserMessage is not true
        if (!skipUserMessage) {
          const userMessage: Message = {
            role: "user",
            content: message,
            timestamp: new Date(),
            attachments: attachmentsToUse,
          };
          setMessages((prev) => [...prev, userMessage]);
        }

        const docsToUse =
          documentIds || uploadedDocuments.map((doc) => doc._id);

        setUploadedDocuments([]);

        if (!useSubquestions) {
          useSubquestions =
            (workflowData?.options?.["settings"]?.["use_subquestions"] as
              | boolean
              | undefined) ?? false;
        }

        if (useSubquestions) {
          const response = await fetchWorkkflowExecutionAskAIStream(
            executionId,
            message,
            docsToUse,
            filteredDocumentIds
          );

          if (!response.ok) {
            throw new Error("Stream response was not ok");
          }

          const reader = response.body?.getReader();
          if (!reader) {
            throw new Error("No reader available");
          }

          const decoder = new TextDecoder();
          let buffer = "";
          let isReading = true;

          while (isReading) {
            const { done, value } = await reader.read();
            if (done) {
              isReading = false;
              break;
            }

            // Decode and log the text
            const chunk = decoder.decode(value, { stream: true });

            buffer += chunk;
            const lines = buffer.split("\n");
            buffer = lines.pop() || "";

            for (const line of lines) {
              if (line.trim()) {
                try {
                  const data = JSON.parse(line);

                  if (data.type === "subquestion_citations") {
                    const targetSubquestion = data.subquestion;

                    setMessages((prev) => {
                      let targetIndex: number | undefined;

                      if (targetSubquestion) {
                        targetIndex = prev.findIndex(
                          (msg) =>
                            msg.type === "subquestion" &&
                            msg.subquestion === targetSubquestion
                        );
                      } else {
                        targetIndex = prev
                          .map((msg, idx) =>
                            msg.type === "subquestion" ? idx : -1
                          )
                          .filter((idx) => idx !== -1)
                          .pop();
                      }

                      if (targetIndex !== undefined) {
                        const newMessages = [...prev];
                        newMessages[targetIndex] = {
                          ...newMessages[targetIndex],
                          citations: data.bbox_with_docs,
                        };
                        return newMessages;
                      }

                      return prev;
                    });

                    // Also add to selectedCitations for previewing
                    if (
                      data.bbox_with_docs &&
                      Array.isArray(data.bbox_with_docs)
                    ) {
                      const newCitations = data.bbox_with_docs.flatMap(
                        (doc: {
                          document_id: string;
                          bbox_values: RawCitation[];
                        }) =>
                          doc.bbox_values.map((bbox: RawCitation) =>
                            mapCitation(doc.document_id, bbox)
                          )
                      );

                      setSelectedCitations((prev) => [
                        ...prev,
                        ...newCitations,
                      ]);
                    }
                  }

                  // Continue with existing code to process the data...
                  if (data.type === "subquestion_details") {
                    const subquestionsMessage: Message = {
                      role: "assistant",
                      content: "Breaking down your question into parts:",
                      timestamp: new Date(),
                      type: "subquestion_details",
                      subquestions: data.subquestions,
                    };
                    setMessages((prev) => [...prev, subquestionsMessage]);
                  } else if (data.type === "subquestion_token") {
                    // Handle token-by-token streaming for subquestions
                    const { subquestion, token } = data;

                    // Check if this subquestion already exists in messages
                    setMessages((prev) => {
                      const existingMessageIndex = prev.findIndex(
                        (msg) =>
                          msg.type === "subquestion" &&
                          msg.subquestion === subquestion
                      );

                      if (existingMessageIndex >= 0) {
                        // Update existing message with new content
                        return prev.map((msg, i) =>
                          i === existingMessageIndex
                            ? {
                                ...msg,
                                content:
                                  (prev[existingMessageIndex].content || "") +
                                  token,
                              }
                            : msg
                        );
                      } else {
                        // Create new message for this subquestion
                        const newMessage: Message = {
                          role: "assistant",
                          content: token,
                          timestamp: new Date(),
                          type: "subquestion",
                          subquestion: subquestion,
                        };
                        return [...prev, newMessage];
                      }
                    });
                  } else if (data.type === "token") {
                    // Handle token-by-token streaming for general responses
                    const { token } = data;

                    // Check if we already have a token-streaming message
                    setMessages((prev) => {
                      // Find the most recent message (as tokens come in sequence)
                      const lastIndex = prev.length - 1;

                      // If the last message is a token message, append to it
                      if (lastIndex >= 0 && prev[lastIndex].type === "token") {
                        const newMessages = [...prev];
                        newMessages[lastIndex] = {
                          ...newMessages[lastIndex],
                          content: newMessages[lastIndex].content + token,
                        };
                        return newMessages;
                      }

                      // Otherwise create a new token message
                      return [
                        ...prev,
                        {
                          role: "assistant",
                          content: token,
                          timestamp: new Date(),
                          type: "token",
                        },
                      ];
                    });
                  } else if (data.type === "subquestion") {
                    // Handle full subquestion answers (non-token based)
                    const subquestionMessage: Message = {
                      role: "assistant",
                      content: data.answer,
                      timestamp: new Date(),
                      type: "subquestion",
                      subquestion: data.subquestion,
                      citations: data.bbox_with_docs,
                    };
                    setMessages((prev) => [...prev, subquestionMessage]);
                  } else if (data.type === "final_answer") {
                    // Add citations to selectedCitations for preview
                    if (data.bbox_with_docs) {
                      const newCitations: (CitationWithDocId & {
                        citationType: "pdf" | "email";
                      })[] = data.bbox_with_docs.flatMap(
                        (doc: {
                          document_id: string;
                          bbox_values: RawCitation[];
                        }) =>
                          doc.bbox_values.map((bbox: RawCitation) =>
                            mapCitation(doc.document_id, bbox)
                          )
                      );
                      setSelectedCitations((prev) => [
                        ...prev,
                        ...newCitations,
                      ]);
                    }

                    // For final answer, find the token message and replace it
                    setMessages((prev) => {
                      const lastIndex = prev.length - 1;

                      // If the last message is a token message, replace it with final answer
                      if (lastIndex >= 0 && prev[lastIndex].type === "token") {
                        const newMessages = [...prev];
                        newMessages[lastIndex] = {
                          role: "assistant",
                          content: data.answer,
                          timestamp: new Date(),
                          citations: data.bbox_with_docs,
                          tableLogId: data.table_log_id || "",
                          tableRows: data.table_rows || [],
                          email_html: data.email_html,
                        };
                        return newMessages;
                      }

                      // Otherwise add as a new message
                      return [
                        ...prev,
                        {
                          role: "assistant",
                          content: data.answer,
                          timestamp: new Date(),
                          type: "final_answer",
                          citations: data.bbox_with_docs,
                          tableLogId: data.table_log_id || "",
                          tableRows: data.table_rows || [],
                          email_html: data.email_html,
                        },
                      ];
                    });
                  }
                } catch (e) {
                  console.error("Error parsing streaming response line:", line);
                  console.error("Parse error:", e);
                }
              }
            }
          }
        } else {
          // Use non-streaming endpoint when use_subquestions is false
          const response = await fetchWorkkflowExecutionAskAI(
            executionId,
            message,
            docsToUse,
            filteredDocumentIds
          );

          setShouldInvalidateCache(true);

          if (response.bbox_with_docs) {
            const newCitations: (CitationWithDocId & {
              citationType: "pdf" | "email";
            })[] = response.bbox_with_docs.flatMap(
              (doc: { document_id: string; bbox_values: RawCitation[] }) =>
                doc.bbox_values.map((bbox) =>
                  mapCitation(doc.document_id, bbox)
                )
            );
            setSelectedCitations((prev) => [...prev, ...newCitations]);
          }

          const assistantMessage: Message = {
            role: "assistant",
            content: response.answer,
            timestamp: new Date(),
            citations: response.bbox_with_docs,
            tableLogId: response.table_log_id ? response.table_log_id : "",
            tableRows: response.table_rows ? response.table_rows : [],
            email_html: response.email_html,
          };
          setMessages((prev) => [...prev, assistantMessage]);
          if (workflowTitle === "") {
            const title = await getExecutionTitle(executionId);
            if (title) {
              setWorkflowTitle(title);
            }
          }
        }
      } catch (error) {
        console.error("Error getting AI response:", error);
        toast.error("Failed to get AI response");
      } finally {
        setIsStreaming(false);
        setTimeout(() => {
          setShouldInvalidateCache(false);
        }, 100);
      }
    },
    [
      executionId,
      fetchWorkkflowExecutionAskAI,
      fetchWorkkflowExecutionAskAIStream,
      filteredDocumentIds,
      setShouldInvalidateCache,
      uploadedDocuments,
      workflowData?.options,
      setSelectedCitations,
      mapCitation,
      chatDocuments,
      getExecutionTitle,
      workflowTitle,
    ]
  );

  const handleTableClick = async (
    tableLogId: string,
    filteredRows?: number[]
  ) => {
    try {
      const tableData = await fetchTableLog(tableLogId);
      if (!isInCanvas) {
        setIsInCanvas(true);
      }

      if (
        workflowData?.options?.settings?.use_grid_editor ||
        executionLog?.request.workflow_id === U_Extract_Workflow_Id
      ) {
        setRightContent(
          <GridContainer
            key={tableLogId}
            tableData={tableData}
            filteredRows={filteredRows}
          />
        );
      } else {
        setRightContent(
          <TableContainer
            key={tableLogId}
            tableData={tableData}
            filteredRows={filteredRows}
            className="p-4"
          />
        );
      }
    } catch (error) {
      console.error("Failed to fetch table log:", error);
      toast.error("Failed to load table log");
    }
  };

  const renderShimmer = () => (
    <div className="min-h-screen">
      <div className="py-10 px-8">
        <div className="max-w-3xl mx-auto">
          <div className="flex items-center text-md text-gray-500">
            <div className="flex items-center space-x-2">
              <div className="h-5 w-32 bg-gray-200 rounded animate-pulse" />
              <ChevronRight className="h-4 w-4 text-gray-300" />
              <div className="h-5 w-48 bg-gray-200 rounded animate-pulse" />
            </div>
          </div>
        </div>
      </div>

      <div className="flex-1 px-6 pb-4">
        <div className="mx-auto">
          <div className="max-w-3xl mx-auto">
            {/* Chat Interface Shimmer - Only Messages */}
            <div className="space-y-4">
              {/* Message Shimmers */}
              {[1, 2, 3].map((index) => (
                <div key={index} className="space-y-2">
                  <div className="flex items-start">
                    <div className="min-w-[24px] min-h-[24px] w-6 h-6 flex-shrink-0 flex items-center justify-center mr-2 bg-gray-200 rounded-full animate-pulse" />
                    <div className="rounded-xl max-w-[720px] px-2 w-full">
                      <div className="space-y-2">
                        <div className="h-4 w-full bg-gray-200 rounded animate-pulse" />
                        <div className="h-4 w-5/6 bg-gray-200 rounded animate-pulse" />
                        <div className="h-4 w-2/3 bg-gray-200 rounded animate-pulse" />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const scrollToBottom = (ref: React.RefObject<HTMLDivElement>) => {
    ref.current?.scrollIntoView();
  };

  useEffect(() => {
    const container = scrollContainerRef.current;

    const handleScroll = () => {
      if (!container) return;

      const { scrollTop, scrollHeight, clientHeight } = container;
      const isAtBottom = Math.abs(scrollHeight - scrollTop - clientHeight) < 80;
      setIsUserAtBottom(isAtBottom);
    };

    container?.addEventListener("scroll", handleScroll);
    return () => container?.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (isUserAtBottom) {
      scrollToBottom(messagesEndRef);
    }
  }, [isUserAtBottom, messagesEndRef]);

  useEffect(() => {
    if (!isLoadingStable && !isStepsLoading) {
      scrollToBottom(messagesEndRef);
      setIsUserAtBottom(true);
    }
  }, [isLoadingStable, isStepsLoading]);

  const toggleStep = (stepId: string, index: number) => {
    const uniqueStepId = `${stepId}-${index}`;
    setExpandedSteps((prev) => ({
      ...prev,
      [uniqueStepId]: !prev[uniqueStepId],
    }));
  };

  useEffect(() => {
    if (steps.length > 0) {
      const isWorkflowDone =
        executionLog?.status === WorkflowExecutionStatus.COMPLETED ||
        executionLog?.status === WorkflowExecutionStatus.FAILED;

      if (isWorkflowDone) {
        const initialExpanded = steps.reduce((acc, step, index) => {
          acc[`${step.id}-${index}`] = index === steps.length - 1;
          return acc;
        }, {} as { [key: string]: boolean });
        setExpandedSteps(initialExpanded);
      } else {
        const initialExpanded = steps.reduce((acc, step, index) => {
          acc[`${step.id}-${index}`] = true;
          return acc;
        }, {} as { [key: string]: boolean });
        setExpandedSteps(initialExpanded);
      }
    }
  }, [steps, executionLog?.status]);

  const handleGuidelinesClick = () => {
    if (!isInCanvas) {
      setIsInCanvas(true);
      setRightContent(
        <GuidelinesDetail initialExecutionId={executionId} isEmbedded={true} />
      );
    } else {
      setRightContent(
        <GuidelinesDetail initialExecutionId={executionId} isEmbedded={true} />
      );
    }
  };

  const handleAuditClick = (auditLink: string) => {
    const auditId = auditLink.split("/").pop();

    if (!isInCanvas) {
      setIsInCanvas(true);
      setRightContent(
        <UwAuditDetail initialAuditId={auditId} isEmbedded={true} />
      );
    } else {
      setRightContent(
        <UwAuditDetail initialAuditId={auditId} isEmbedded={true} />
      );
    }
  };

  const handleCompareClick = (compareLink: string) => {
    const compareId = compareLink?.split("/").pop();

    if (!compareId) {
      console.error("No compare ID found in link:", compareLink);
      return;
    }

    if (!isInCanvas) {
      setIsInCanvas(true);
    }

    setRightContent(
      <CompareDetailCanvas
        key={compareId}
        initialCompareId={compareId}
        isEmbedded={true}
      />
    );
  };

  const handleExcelLogClick = (excelLogId: string | undefined) => {
    if (!excelLogId) {
      toast.error("No excel log ID provided");
      return;
    }

    if (!isInCanvas) {
      setIsInCanvas(true);
    }

    setRightContent(
      <FillExcelDetail
        key={excelLogId}
        initialExcelId={excelLogId}
        isEmbedded={true}
      />
    );
  };

  const handleCanvasClose = () => {
    setIsInCanvas(false);
    setRightContent(null);
  };

  const handleCitationClick = async (snippet: AnswerSnippet) => {
    if (!snippet.document_id) return;

    setIsPdfSliderOpen(true);
    setIsLoadingPdf(true);

    setSelectedCitations(
      snippet.bbox_values.map((bboxValue: RawCitation) =>
        mapCitation(snippet.document_id, bboxValue)
      )
    );

    try {
      const originalDocId = getTranslationInfo(
        (executionLog?.result?.steps as ApiWorkflowStep[]) || []
      )[snippet.document_id]?.originalDocId;

      if (!pdfUrls.has(snippet.document_id)) {
        const documentDetails = await fetchUserDocument(snippet.document_id);
        const fileType = documentDetails.filename
          .split(".")
          .pop()
          ?.toLowerCase();

        if (["eml", "msg"].includes(fileType || "")) {
          const emailDetails = await fetchEmailDetails(snippet.document_id);
          setCurrentEmailHtml(emailDetails.body_html);
          // Use the first citation from the snippet to set text and range
          const firstCitation = snippet.bbox_values[0];
          const mappedCitation = mapCitation(
            snippet.document_id,
            firstCitation
          );
          // setEmailHighlightText(mappedCitation.citation.content);
          if (
            mappedCitation.citationType === "email" &&
            typeof mappedCitation.start === "number" &&
            typeof mappedCitation.end === "number"
          ) {
            // setEmailHighlightRange({ start: mappedCitation.start, end: mappedCitation.end });
            setEmailHighlightRange(null); // TODO: use range for email citations later
          } else {
            setEmailHighlightRange(null);
          }
          setIsEmailSliderOpen(true);
          return;
        }

        const url = await getPdfUrl(snippet.document_id);
        setPdfUrls(
          produce((draft) => {
            draft.set(snippet.document_id, url);
          })
        );
        setCurrentPdfUrl(url);
        setCurrentFileType(fileType === "xlsx" ? "excel" : "pdf");

        if (originalDocId) {
          const originalUrl = await getPdfUrl(originalDocId);
          setPdfUrls(
            produce((draft) => {
              draft.set(originalDocId, originalUrl);
            })
          );
          setOriginalPdfUrl(originalUrl);
        }
      } else {
        const url = pdfUrls.get(snippet.document_id) || null;
        setCurrentPdfUrl(url);

        const documentDetails = await fetchUserDocument(snippet.document_id);
        const fileType = documentDetails.filename
          .split(".")
          .pop()
          ?.toLowerCase();

        if (["eml", "msg"].includes(fileType || "")) {
          const emailDetails = await fetchEmailDetails(snippet.document_id);
          setCurrentEmailHtml(emailDetails.body_html);
          const firstCitation = snippet.bbox_values[0];
          const mappedCitation = mapCitation(
            snippet.document_id,
            firstCitation
          );
          if (
            mappedCitation.citationType === "email" &&
            typeof mappedCitation.start === "number" &&
            typeof mappedCitation.end === "number"
          ) {
            setEmailHighlightRange({
              start: mappedCitation.start,
              end: mappedCitation.end,
            });
          } else {
            setEmailHighlightRange(null);
          }
          setIsEmailSliderOpen(true);
          return;
        }

        setCurrentFileType(fileType === "xlsx" ? "excel" : "pdf");

        if (originalDocId) {
          const originalUrl =
            pdfUrls.get(originalDocId) || (await getPdfUrl(originalDocId));
          if (!pdfUrls.has(originalDocId)) {
            setPdfUrls(
              produce((draft) => {
                draft.set(originalDocId, originalUrl);
              })
            );
          }
          setOriginalPdfUrl(originalUrl);
        }
      }
    } catch (error) {
      console.error("Failed to fetch document:", error);
      toast.error("Unable to preview document");
    } finally {
      setIsLoadingPdf(false);
    }
  };

  const handleExtractLogClick = (extractLink: string) => {
    const extractId = extractLink?.split("/").pop();

    if (!extractId) {
      console.error("No extract ID found in link:", extractLink);
      return;
    }

    if (!isInCanvas) {
      setIsInCanvas(true);
    }

    setRightContent(
      <ExtractDetailCanvas
        key={extractId}
        initialExtractId={extractId}
        isEmbedded={true}
      />
    );
  };

  const handleEmailClick = (
    emailBody: string,
    emailComponentTitle: string,
    attachments?: Array<{ user_document_id: string; filename: string }>,
    headers?: Record<string, string>
  ) => {
    if (!isInCanvas) {
      setIsInCanvas(true);
      setRightContent(
        <EmailDetail
          emailBody={emailBody}
          emailComponentTitle={emailComponentTitle}
          attachments={attachments}
          emailHeaders={headers}
          isEmbedded={true}
        />
      );
    } else {
      setRightContent(
        <EmailDetail
          emailBody={emailBody}
          emailComponentTitle={emailComponentTitle}
          attachments={attachments}
          emailHeaders={headers}
          isEmbedded={true}
        />
      );
    }
  };

  const handleFeedbackSubmit = async (
    feedbackText?: string,
    feedbackType?: 1 | -1
  ) => {
    try {
      const messageIndex = activeFeedbackId.startsWith("message-")
        ? parseInt(activeFeedbackId.split("-")[1])
        : null;

      const messageContent =
        messageIndex !== null && messages[messageIndex]
          ? messages[messageIndex].content
          : null;

      const feedback_type =
        feedbackType ?? messageFeedbackTypes[activeFeedbackId];

      if (!feedback_type) {
        throw new Error("Feedback type is required");
      }

      await submitFeedback(workflowId || "", executionId, {
        feedback_type,
        message_index: messageIndex || undefined,
        message_content: messageContent || undefined,
        feedback_text: feedbackText,
      });

      setMessageFeedbackTypes((prev) => ({
        ...prev,
        [activeFeedbackId]: feedback_type,
      }));
      setFeedbackSubmitted((prev) => ({
        ...prev,
        [activeFeedbackId]: true,
      }));

      toast.success("Feedback submitted successfully");
      setIsFeedbackOpen(false);
    } catch (error) {
      console.error("Failed to submit feedback:", error);
      toast.error("Failed to submit feedback. Please try again.");
    }
  };

  // Initialize welcome section visibility based on workflow options
  useEffect(() => {
    if (
      workflowData?.options?.["settings"]?.["show_welcome_section"] === false ||
      workflowId === ASK_AI_WORKFLOW_ID // Hide welcome section for Ask AI workflows
    ) {
      setShowWelcomeSection(false);
    }
  }, [workflowData, workflowId, ASK_AI_WORKFLOW_ID]);

  // Get document information for the welcome section
  const getDocumentsForWelcomeSection = () => {
    const allDocuments = [];

    // Collect documents from steps
    for (const step of steps) {
      if (step.documents && step.documents.length > 0) {
        for (const doc of step.documents) {
          allDocuments.push({
            id: doc.documentId,
            name: doc.name,
            fileType: doc.fileType,
          });
        }
      }
    }

    // If no documents found in steps, check if there are uploaded documents
    if (allDocuments.length === 0 && uploadedDocuments.length > 0) {
      for (const doc of uploadedDocuments) {
        allDocuments.push({
          id: doc._id,
          name: doc.filename,
          fileType: doc.filename.split(".").pop(),
        });
      }
    }

    // For testing, add a dummy document if no documents are found
    if (allDocuments.length === 0) {
      allDocuments.push({
        id: "test-doc-1",
        name: "Test Document.pdf",
        fileType: "pdf",
      });
    }

    return allDocuments;
  };

  const handleWelcomeSectionFileUpload = async (
    file: File
  ): Promise<string | undefined> => {
    try {
      const uploadRequest: UserDocumentUploadRequest = {
        filename: file.name,
        blob_url: "",
        origin: "workflow",
        category: "chat",
        owner_uid: "",
        owner_oid: "",
        run_id: executionId,
      };

      const result = await uploadDocument(file, uploadRequest);
      if (result) {
        const { user_document_id, sas_url } = result;
        const newDocument: UserDocument = {
          _id: user_document_id,
          filename: file.name,
          blob_url: sas_url,
          category: "chat",
          origin: "workflow",
          owner_uid: "",
          owner_oid: "",
          run_id: executionId,
          description: "",
          user_data: {},
        };
        setUploadedDocuments((prev) => [...prev, newDocument]);

        // We no longer automatically resume the workflow here
        // Instead, we'll let the user click the Resume button

        return user_document_id;
      }
    } catch (error) {
      console.error(`Failed to upload file ${file.name}:`, error);
      toast.error(`Failed to upload file ${file.name}`);
    }

    return undefined;
  };

  // Add a new function to handle resuming the workflow
  const handleResumeWorkflow = async () => {
    if (executionLog?.status === WorkflowExecutionStatus.NOT_STARTED) {
      try {
        // Get all uploaded document IDs
        const documentIds = uploadedDocuments.map((doc) => doc._id);

        if (documentIds.length === 0) {
          toast.error("Please upload at least one document before starting");
          return;
        }

        await resumeWorkflow(executionId, {}, documentIds);

        // Clear the uploaded documents after successfully starting the workflow
        setUploadedDocuments([]); // Add this line

        // Refresh the workflow execution log to get the updated status
        const updatedLog = await fetchWorkflowExecutionLog(executionId);
        setExecutionLog(updatedLog);
      } catch (error) {
        console.error("Failed to resume workflow:", error);
        toast.error("Failed to resume workflow");
        throw error;
      }
    }
  };

  const handleSharePointUpload = async () => {
    try {
      await selectAndUploadFile();
    } catch (error) {
      console.error("Failed to upload from SharePoint:", error);
      toast.error("Failed to upload from SharePoint");
    }
  };

  const handleTitleSave = async () => {
    if (!editedTitle.trim()) return;
    setIsSavingTitle(true);
    try {
      await updateExecutionTitle(executionId, editedTitle);
      setWorkflowTitle(editedTitle);
      setIsEditingTitle(false);
      toast.success("Title updated successfully");
    } catch (error) {
      console.error("Error updating title:", error);
      toast.error("Failed to update title");
    } finally {
      setIsSavingTitle(false);
    }
  };

  const workflowContent = (
    <div className="min-h-screen flex flex-col">
      <div className="py-10 px-6">
        <div className="max-w-3xl mx-auto">
          <div className="flex items-center text-md text-gray-500">
            {isWorkflowNameLoading ? (
              <div className="flex items-center space-x-2">
                <div className="h-5 w-32 bg-gray-200 rounded animate-pulse" />
                <ChevronRight className="h-4 w-4 text-gray-300" />
                <div className="h-5 w-48 bg-gray-200 rounded animate-pulse" />
              </div>
            ) : (
              <>
                {workflowId === ASK_AI_WORKFLOW_ID || fromLandingPage ? (
                  <span
                    className="cursor-pointer font-medium text-gray-900 truncate max-w-[200px] inline-block"
                    onClick={() => navigate(`/home`)}
                  >
                    Assistant
                  </span>
                ) : (
                  <span
                    className="cursor-pointer font-medium text-gray-900 truncate max-w-[200px] inline-block"
                    onClick={() =>
                      navigate(
                        `/workflows/${executionLog?.request.workflow_id}`
                      )
                    }
                  >
                    {workflowName}
                  </span>
                )}
                <ChevronRight className="h-4 w-4 mx-2" />
                {isEditingTitle ? (
                  <div className="flex items-center">
                    <Input
                      value={editedTitle}
                      onChange={(e) => setEditedTitle(e.target.value)}
                      className="w-64 focus-visible:ring-gray-400 focus-visible:ring-offset-0 border-gray-200 focus-visible:border-gray-400"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") handleTitleSave();
                        if (e.key === "Escape") {
                          setEditedTitle(workflowTitle);
                          setIsEditingTitle(false);
                        }
                      }}
                      disabled={isSavingTitle}
                      autoFocus
                    />
                    <button
                      aria-label="Cancel editing title"
                      onClick={() => {
                        setEditedTitle(workflowTitle);
                        setIsEditingTitle(false);
                      }}
                      className="ml-2 text-gray-400 hover:text-gray-600"
                      disabled={isSavingTitle}
                    >
                      <X className="w-4 h-4" />
                    </button>
                  </div>
                ) : (
                  <div
                    className="flex items-center group/title cursor-pointer"
                    onClick={() => {
                      setEditedTitle(workflowTitle);
                      setIsEditingTitle(true);
                    }}
                  >
                    <span
                      className="font-medium text-gray-900 group-hover/title:text-gray-700 truncate max-w-[200px] inline-block"
                      title={workflowTitle}
                    >
                      {workflowTitle
                        ? truncateTitle(workflowTitle)
                        : executionLog?.status ===
                          WorkflowExecutionStatus.RUNNING
                        ? "Untitled"
                        : executionLog?.status ===
                          WorkflowExecutionStatus.NOT_STARTED
                        ? "Untitled"
                        : "Untitled"}
                    </span>
                    <PenSquare className="w-4 h-4 ml-2 text-gray-400 opacity-0 group-hover/title:opacity-100 transition-opacity duration-200" />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      <div
        className="flex-1 px-6 overflow-y-auto pb-4"
        ref={scrollContainerRef}
      >
        <div className="mx-auto">
          <div className="max-w-3xl mx-auto">
            {showWelcomeSection && workflowId !== ASK_AI_WORKFLOW_ID && (
              <WelcomeSection
                workflowTitle={workflowData?.name}
                customPrompt={
                  executionLog?.request.workflow_settings?.no_document_upload
                    ? "Please ask a question to get started"
                    : (workflowData?.options?.welcome_message as
                        | string
                        | undefined)
                }
                onFileUpload={handleWelcomeSectionFileUpload}
                existingDocuments={getDocumentsForWelcomeSection()}
                workflowStatus={
                  executionLog?.request.workflow_settings?.no_document_upload
                    ? "completed"
                    : executionLog?.status
                }
                onDocumentClick={(documentId, fileType) => {
                  handleDocumentClick({
                    documentId,
                    fileType,
                  });
                }}
                onDocumentRemove={async (documentId) => {
                  const documentToRemove = uploadedDocuments.find(
                    (doc) => doc._id === documentId
                  );
                  if (documentToRemove && deleteDocument) {
                    try {
                      await deleteDocument(documentToRemove._id);
                      setUploadedDocuments((prev) =>
                        prev.filter((doc) => doc._id !== documentId)
                      );
                      setChatDocuments((prev) =>
                        prev.filter((doc) => doc._id !== documentId)
                      );
                    } catch (error) {
                      console.error(
                        `Failed to remove document ${documentId}:`,
                        error
                      );
                      toast.error(`Failed to remove document`);
                    }
                  }
                }}
                workflowId={workflowId || undefined}
                onResumeWorkflow={handleResumeWorkflow}
                onUploadFromSharePoint={handleSharePointUpload}
                isSharePointLoading={isSharePointLoading}
                isSharePointAvailable={
                  !!org?.orgMetadata["SHAREPOINT_HOSTNAME"]
                }
                uploadedDocumentsCount={uploadedDocuments.length}
                isNoDocumentUpload={
                  !!executionLog?.request.workflow_settings?.no_document_upload
                }
                onVaultClick={() => {
                  navigate("/vault", {
                    state: {
                      selectedVaultId: selectedVault?._id,
                      executionId,
                      workflowTitle: workflowTitle || "Workflow",
                    },
                  });
                }}
                vaultName={selectedVault?.name || "Vault"}
              />
            )}
            {executionLog?.status !== WorkflowExecutionStatus.NOT_STARTED &&
              steps.length > 0 && (
                <div className="rounded-lg mb-4 mx-10  border border-gray-100">
                  <div className="p-2">
                    <div className="relative mx-3">
                      <div className="absolute left-[10px] top-[22px] bottom-4 w-[1.5px] bg-gray-100" />
                      <div className="space-y-1.5">
                        {isStepsLoading
                          ? [1, 2, 3].map((index) => (
                              <div key={index} className="relative">
                                <div className="flex items-start">
                                  <div className="relative z-[1] mr-3 rounded-full bg-white p-0.5">
                                    <div className="w-5 h-5 rounded-full bg-gray-200 animate-pulse" />
                                  </div>

                                  <div className="flex-1 min-h-[30px] flex flex-col justify-center">
                                    <div className="flex items-center justify-between">
                                      <div className="h-5 w-40 bg-gray-200 rounded animate-pulse" />
                                      <div className="h-5 w-5 rounded-full bg-gray-200 animate-pulse" />
                                    </div>

                                    <div className="space-y-2 mt-1">
                                      <div className="h-4 w-full bg-gray-200 rounded animate-pulse" />
                                      <div className="h-4 w-5/6 bg-gray-200 rounded animate-pulse" />
                                    </div>
                                  </div>
                                </div>

                                {index < 3 && (
                                  <div className="h-px bg-gray-100 my-1.5 ml-9" />
                                )}
                              </div>
                            ))
                          : steps.map((step, index) => (
                              <div
                                key={index.toString() + "-" + step.id}
                                className="relative"
                              >
                                <div className="flex items-start py-2">
                                  <div
                                    className={cn(
                                      "relative z-[1] mr-3 rounded-full bg-white p-0.5",
                                      getStepStatus(step.id, index) === "failed"
                                        ? "text-red-600"
                                        : "text-purple-600"
                                    )}
                                  >
                                    {getStepStatus(step.id, index) ===
                                    "completed" ? (
                                      <div className="relative z-10 rounded-full bg-white text-purple-600">
                                        <CircleCheck className="w-5 h-5" />
                                      </div>
                                    ) : getStepStatus(step.id, index) ===
                                      "running" ? (
                                      <div className="relative z-10 rounded-full bg-white">
                                        <Loader2 className="w-5 h-5 animate-spin text-purple-600" />
                                      </div>
                                    ) : getStepStatus(step.id, index) ===
                                      "failed" ? (
                                      <div className="relative z-10 rounded-full bg-white text-red-600">
                                        <XCircle className="w-5 h-5" />
                                      </div>
                                    ) : (
                                      <div className="w-5 h-5 rounded-full border-2 border-gray-200" />
                                    )}
                                  </div>

                                  <div className="flex-1">
                                    <div className="flex items-center justify-between">
                                      <h3 className="text-sm font-medium leading-snug">
                                        {step.title}
                                      </h3>
                                      {hasDetailView(step.stepType) && (
                                        <button
                                          onClick={() =>
                                            toggleStep(step.id, index)
                                          }
                                          className="p-0.5 hover:bg-gray-50 rounded-full"
                                        >
                                          <ChevronRight
                                            className={cn(
                                              "w-4 h-4 text-gray-400 transition-transform duration-200",
                                              expandedSteps[
                                                `${step.id}-${index}`
                                              ]
                                                ? "transform rotate-90"
                                                : ""
                                            )}
                                          />
                                        </button>
                                      )}
                                    </div>

                                    <div
                                      className={cn(
                                        "transition-all duration-200 mt-2",
                                        expandedSteps[`${step.id}-${index}`]
                                          ? "max-h-none opacity-100"
                                          : "max-h-0 opacity-0 overflow-hidden"
                                      )}
                                    >
                                      {getStepStatus(step.id, index) ===
                                        "running" && (
                                        <p className="text-sm text-gray-500 mb-3">
                                          {getLoadingText(step.title)}
                                        </p>
                                      )}

                                      {step.stepType ===
                                        "classify_submission_type" &&
                                        step.submissionType &&
                                        getStepStatus(step.id, index) ===
                                          "completed" && (
                                          <div className="mt-2">
                                            <p className="text-sm text-gray-900">
                                              <span className="font-medium">
                                                {step.submissionType}
                                              </span>
                                            </p>
                                          </div>
                                        )}

                                      {step.stepType === "classify_documents" &&
                                        step.documentTypes && (
                                          <div className="space-y-3">
                                            <Select
                                              value={
                                                selectedDocTypes[step.id] || ""
                                              }
                                              onValueChange={(value: string) =>
                                                handleDocTypeChange(
                                                  value,
                                                  step.id
                                                )
                                              }
                                            >
                                              <SelectTrigger className="w-[180px] h-9 text-sm focus:ring-0">
                                                <SelectValue placeholder="Select type" />
                                              </SelectTrigger>
                                              <SelectContent className="bg-white">
                                                {step.input?.classes
                                                  ? step.input.classes.map(
                                                      (classType) => (
                                                        <SelectItem
                                                          key={classType.name}
                                                          value={classType.name}
                                                          className="text-sm"
                                                        >
                                                          {classType.name}
                                                        </SelectItem>
                                                      )
                                                    )
                                                  : Object.keys(
                                                      step.output?.result || {}
                                                    ).map((type) => (
                                                      <SelectItem
                                                        key={type}
                                                        value={type}
                                                        className="text-sm"
                                                      >
                                                        {type}
                                                      </SelectItem>
                                                    ))}
                                              </SelectContent>
                                            </Select>

                                            <div className="grid grid-cols-2 gap-3">
                                              {filterDocumentsByType(
                                                step,
                                                selectedDocTypes[step.id] || ""
                                              ).map((doc) => (
                                                <div
                                                  key={doc.documentId}
                                                  className="flex items-center p-2 hover:bg-gray-50 rounded-md cursor-pointer min-w-0"
                                                  onClick={() =>
                                                    handleDocumentClick({
                                                      documentId:
                                                        doc.documentId,
                                                      fileType: doc.fileType,
                                                    })
                                                  }
                                                >
                                                  <div className="flex-shrink-0 w-7 h-7 rounded-lg bg-purple-50 flex items-center justify-center mr-2">
                                                    <FileText className="w-4 h-4 text-purple-600" />
                                                  </div>
                                                  <div className="flex flex-col min-w-0 justify-center">
                                                    <TooltipProvider
                                                      delayDuration={300}
                                                    >
                                                      <Tooltip>
                                                        <TooltipTrigger asChild>
                                                          <span className="text-sm text-gray-900 truncate">
                                                            {doc.name}
                                                          </span>
                                                        </TooltipTrigger>
                                                        <TooltipContent
                                                          side="top"
                                                          align="start"
                                                          sideOffset={5}
                                                          className="bg-white p-2 w-[300px]"
                                                        >
                                                          <div className="flex flex-col gap-2 text-sm break-words">
                                                            <span className="font-medium">
                                                              {doc.name}
                                                            </span>
                                                            {(doc.fileType ===
                                                              "xlsx" ||
                                                              doc.fileType ===
                                                                "eml") && (
                                                              <span>
                                                                Page: N/A
                                                              </span>
                                                            )}
                                                          </div>
                                                        </TooltipContent>
                                                      </Tooltip>
                                                    </TooltipProvider>
                                                  </div>
                                                </div>
                                              ))}
                                            </div>
                                          </div>
                                        )}

                                      {step.stepType === "extract_guidelines" &&
                                        getStepStatus(step.id, index) ===
                                          "completed" && (
                                          <div className="mt-2">
                                            <Button
                                              variant="outline"
                                              size="sm"
                                              className="h-8 text-sm"
                                              onClick={handleGuidelinesClick}
                                            >
                                              View Guidelines
                                            </Button>
                                          </div>
                                        )}

                                      {step.stepType === "check_guidelines" &&
                                        step.auditLink &&
                                        getStepStatus(step.id, index) ===
                                          "completed" && (
                                          <div className="mt-2">
                                            <Button
                                              variant="outline"
                                              size="sm"
                                              className="h-8 text-sm"
                                              onClick={() =>
                                                step.auditLink &&
                                                handleAuditClick(step.auditLink)
                                              }
                                            >
                                              View Output
                                            </Button>
                                          </div>
                                        )}

                                      {step.stepType === "compare" &&
                                        step.compareLink &&
                                        getStepStatus(step.id, index) ===
                                          "completed" && (
                                          <div className="mt-2">
                                            <Button
                                              variant="outline"
                                              size="sm"
                                              className="h-8 text-sm"
                                              onClick={() =>
                                                step.compareLink &&
                                                handleCompareClick(
                                                  step.compareLink
                                                )
                                              }
                                            >
                                              View Output
                                            </Button>
                                          </div>
                                        )}

                                      {step.stepType ===
                                        "compare_with_schema" &&
                                        step.compareLink &&
                                        getStepStatus(step.id, index) ===
                                          "completed" && (
                                          <div className="mt-2">
                                            <Button
                                              variant="outline"
                                              size="sm"
                                              className="h-8 text-sm"
                                              onClick={() =>
                                                step.compareLink &&
                                                handleCompareClick(
                                                  step.compareLink
                                                )
                                              }
                                            >
                                              View Output
                                            </Button>
                                          </div>
                                        )}

                                      {step.stepType === "qa" &&
                                        step.answerSnippets && (
                                          <div className="space-y-2">
                                            <div className="text-sm text-gray-900">
                                              <div className="space-y-2">
                                                {step.answerSnippets.map(
                                                  (snippet, index) => (
                                                    <div
                                                      key={index}
                                                      className="flex items-start"
                                                    >
                                                      <div className="flex-1 inline-flex items-start flex-wrap gap-1">
                                                        <div className="whitespace-pre-wrap break-words">
                                                          {snippet.text}
                                                          {snippet.bbox_values
                                                            .length > 0 && (
                                                            <TooltipProvider
                                                              delayDuration={
                                                                300
                                                              }
                                                            >
                                                              <Tooltip>
                                                                <TooltipTrigger
                                                                  asChild
                                                                >
                                                                  <button
                                                                    type="button"
                                                                    onClick={() =>
                                                                      handleCitationClick(
                                                                        snippet
                                                                      )
                                                                    }
                                                                    className="inline-flex items-center justify-center rounded-full bg-gray-200 w-[18px] h-[18px] text-xs font-medium text-gray-700 hover:bg-gray-300 transition-colors cursor-pointer ml-1 align-baseline"
                                                                  >
                                                                    {index + 1}
                                                                  </button>
                                                                </TooltipTrigger>
                                                                <TooltipContent
                                                                  side="right"
                                                                  align="end"
                                                                  className="bg-white p-1.5"
                                                                >
                                                                  <p className="text-xs">
                                                                    Document:
                                                                    {""}
                                                                    {
                                                                      snippet.document_id
                                                                    }
                                                                    <br />
                                                                    Page:{""}
                                                                    {snippet
                                                                      .bbox_values[0]
                                                                      ?.bbox
                                                                      ?.page ||
                                                                      "N/A"}
                                                                  </p>
                                                                </TooltipContent>
                                                              </Tooltip>
                                                            </TooltipProvider>
                                                          )}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        )}

                                      {step.stepType === "sov_mapping" &&
                                        step.output?.link_to_excel_log &&
                                        getStepStatus(step.id, index) ===
                                          "completed" && (
                                          <div className="mt-2">
                                            <Button
                                              variant="outline"
                                              size="sm"
                                              className="h-8 text-sm"
                                              onClick={() => {
                                                const excelLogId =
                                                  step.output?.link_to_excel_log
                                                    ?.split("/")
                                                    .pop();
                                                handleExcelLogClick(excelLogId);
                                              }}
                                            >
                                              View Output
                                            </Button>
                                          </div>
                                        )}

                                      {(step.stepType === "extract_from_text" ||
                                        step.stepType ===
                                          "extract_from_document" ||
                                        step.stepType ===
                                          "extract_rows_from_multiple_documents") &&
                                        (step.output?.link_to_extract_log ||
                                          step.output?.table_log_id) &&
                                        getStepStatus(step.id, index) ===
                                          "completed" && (
                                          <div className="mt-2">
                                            <Button
                                              variant="outline"
                                              size="sm"
                                              className="h-8 text-sm"
                                              onClick={() => {
                                                if (step.output?.table_log_id) {
                                                  handleTableClick(
                                                    step.output.table_log_id
                                                  );
                                                } else {
                                                  step.output
                                                    ?.link_to_extract_log &&
                                                    handleExtractLogClick(
                                                      step.output
                                                        .link_to_extract_log
                                                    );
                                                }
                                              }}
                                            >
                                              View Output
                                            </Button>
                                          </div>
                                        )}

                                      {step.stepType === "email" &&
                                        getStepStatus(step.id, index) ===
                                          "completed" && (
                                          <div className="mt-2">
                                            <Button
                                              variant="outline"
                                              size="sm"
                                              className="h-8 text-sm"
                                              onClick={() =>
                                                handleEmailClick(
                                                  step.output?.email_body || "",
                                                  step.output
                                                    ?.email_component_title ||
                                                    "Email Content",
                                                  step.output?.attachments,
                                                  step.output?.headers
                                                )
                                              }
                                            >
                                              {step.output
                                                ?.email_body_button_text
                                                ? step.output
                                                    ?.email_body_button_text
                                                : "View Email"}
                                            </Button>
                                          </div>
                                        )}

                                      {step.stepType === "signature_analysis" &&
                                        step.signatureAnalysis && (
                                          <div className="space-y-3">
                                            {step.signatureAnalysis.map(
                                              (doc, docIndex) => (
                                                <div
                                                  key={docIndex}
                                                  className="space-y-3"
                                                >
                                                  <div
                                                    className="flex items-center p-2 hover:bg-gray-50 rounded-md cursor-pointer"
                                                    onClick={() =>
                                                      handleDocumentClick({
                                                        documentId:
                                                          step.input?.document
                                                            ?.user_document_id ||
                                                          "",
                                                        fileType:
                                                          doc.document_name
                                                            .split(".")
                                                            .pop()
                                                            ?.toLowerCase(),
                                                      })
                                                    }
                                                  >
                                                    <div className="w-7 h-7 rounded-lg bg-purple-50 flex items-center justify-center mr-2">
                                                      <FileText className="w-4 h-4 text-purple-600" />
                                                    </div>
                                                    <div className="flex flex-col">
                                                      <span className="text-sm text-gray-900">
                                                        {doc.document_name}
                                                      </span>
                                                    </div>
                                                  </div>
                                                  {doc.signatures.map(
                                                    (sig, sigIndex) => (
                                                      <div
                                                        key={sigIndex}
                                                        className="bg-gray-50 rounded-md p-3 space-y-1.5"
                                                      >
                                                        {sig.signer_name && (
                                                          <div className="flex items-center text-sm">
                                                            <span className="text-gray-500 w-24">
                                                              Signer Name:
                                                            </span>
                                                            <span className="text-gray-900 font-medium">
                                                              {sig.signer_name}
                                                            </span>
                                                          </div>
                                                        )}
                                                        {sig.signature_type &&
                                                          sig.is_signature_present ===
                                                            true && (
                                                            <div className="flex items-center text-sm">
                                                              <span className="text-gray-500 w-24">
                                                                Signature Type:
                                                              </span>
                                                              <span className="text-gray-900 capitalize">
                                                                {
                                                                  sig.signature_type
                                                                }
                                                              </span>
                                                            </div>
                                                          )}
                                                        {sig.signature_date &&
                                                          sig.is_date_present ===
                                                            true && (
                                                            <div className="flex items-center text-sm">
                                                              <span className="text-gray-500 w-24">
                                                                Date:
                                                              </span>
                                                              <span className="text-gray-900">
                                                                {
                                                                  sig.signature_date
                                                                }
                                                              </span>
                                                            </div>
                                                          )}
                                                        {sig.signature_page_number && (
                                                          <div className="flex items-center text-sm">
                                                            <span className="text-gray-500 w-24">
                                                              Page No:
                                                            </span>
                                                            <span className="text-gray-900">
                                                              {
                                                                sig.signature_page_number
                                                              }
                                                            </span>
                                                          </div>
                                                        )}
                                                        {sig.signer_type && (
                                                          <div className="flex items-center text-sm">
                                                            <span className="text-gray-500 w-24">
                                                              Signer Type:
                                                            </span>
                                                            <span className="text-gray-900 capitalize">
                                                              {sig.signer_type}
                                                            </span>
                                                          </div>
                                                        )}
                                                        <div className="flex flex-wrap gap-3 pt-1">
                                                          {sig.is_signature_present !==
                                                            null && (
                                                            <span
                                                              className={cn(
                                                                "inline-flex items-center text-sm rounded-full px-2 py-1",
                                                                sig.is_signature_present ===
                                                                  true
                                                                  ? "bg-green-50 text-green-700"
                                                                  : "bg-yellow-50 text-yellow-700"
                                                              )}
                                                            >
                                                              {sig.is_signature_present ? (
                                                                <CircleCheck className="w-4 h-4 mr-1" />
                                                              ) : (
                                                                <XCircle className="w-4 h-4 mr-1" />
                                                              )}
                                                              {sig.is_signature_present
                                                                ? "Signature present in the slot"
                                                                : "Signature not present in the slot"}
                                                            </span>
                                                          )}
                                                          {sig.is_date_present !==
                                                            null && (
                                                            <span
                                                              className={cn(
                                                                "inline-flex items-center text-xs rounded-full px-2 py-1",
                                                                sig.is_date_present ===
                                                                  true
                                                                  ? "bg-green-50 text-green-700"
                                                                  : "bg-yellow-50 text-yellow-700"
                                                              )}
                                                            >
                                                              {sig.is_date_present ? (
                                                                <CircleCheck className="w-3 h-3 mr-1" />
                                                              ) : (
                                                                <XCircle className="w-3 h-3 mr-1" />
                                                              )}
                                                              {sig.is_date_present
                                                                ? "Date present in the slot"
                                                                : "Date not present in the slot"}
                                                            </span>
                                                          )}
                                                        </div>
                                                      </div>
                                                    )
                                                  )}
                                                </div>
                                              )
                                            )}
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}

            {(messages.length > 0 || workflowId === ASK_AI_WORKFLOW_ID) && (
              <div className="space-y-3">
                {messages.map((message, index) => {
                  const cleanContent = message.content;
                  return (
                    <div
                      key={`${message.timestamp.getTime()}-${index}`}
                      className="space-y-0.5"
                    >
                      {message.role === "user" &&
                        message.attachments &&
                        message.attachments.length > 0 && (
                          <div className="flex flex-col items-end gap-2 mb-2">
                            {message.attachments.map((document) => {
                              const fileExtension = document.filename
                                ? document.filename
                                    .split(".")
                                    .pop()
                                    ?.toLowerCase()
                                : "";

                              let fileType = "pdf";
                              if (
                                fileExtension === "xlsx" ||
                                fileExtension === "xls"
                              ) {
                                fileType = "xlsx";
                              } else if (
                                [
                                  "jpg",
                                  "jpeg",
                                  "png",
                                  "gif",
                                  "bmp",
                                  "webp",
                                ].includes(fileExtension || "")
                              ) {
                                fileType = "image";
                              }

                              return (
                                <div
                                  key={document._id}
                                  className="inline-flex items-center text-gray-700 border border-gray-200 rounded-md text-sm bg-white hover:bg-gray-50 transition-colors px-4 py-2.5 h-12 w-full sm:w-auto sm:min-w-[240px] sm:max-w-[400px] flex-shrink-0 cursor-pointer"
                                  onClick={() =>
                                    handleDocumentClick({
                                      documentId: document._id,
                                      fileType,
                                    })
                                  }
                                >
                                  <div className="flex items-center gap-3 flex-1 min-w-0">
                                    <div className="w-8 h-8 rounded-md bg-purple-50 flex-shrink-0 flex items-center justify-center">
                                      <FileText className="w-4.5 h-4.5 text-purple-600" />
                                    </div>
                                    <span className="truncate text-base flex-1">
                                      {document.filename ||
                                        `${document._id.substring(0, 6)}...`}
                                    </span>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      <div
                        className={cn(
                          "flex",
                          message.role === "assistant"
                            ? "justify-start"
                            : "justify-end"
                        )}
                      >
                        {message.role === "assistant" && (
                          <div className="min-w-[24px] min-h-[24px] w-6 h-6 flex-shrink-0 flex items-center justify-center mr-2">
                            <img
                              src={faiLogo}
                              alt="AI"
                              className="w-full h-full object-contain"
                            />
                          </div>
                        )}
                        <div
                          className={cn(
                            "rounded-xl max-w-[720px]",
                            message.role === "assistant"
                              ? "px-2"
                              : "py-2 px-2 bg-purple-100"
                          )}
                        >
                          <div className="break-words">
                            {message.role === "assistant" &&
                            message.type === "subquestion_details" &&
                            message.subquestions ? (
                              <div className="space-y-2">
                                <div className="text-sm text-gray-900">
                                  {message.content}
                                </div>
                                <ul className="list-decimal pl-5 space-y-1">
                                  {message.subquestions.map((subq, idx) => (
                                    <li
                                      key={idx}
                                      className="text-sm text-gray-700"
                                    >
                                      {subq}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            ) : message.role === "assistant" &&
                              message.type === "subquestion" &&
                              message.subquestion ? (
                              <div className="space-y-2">
                                <div className="text-sm font-bold text-gray-900 dark:text-gray-100">
                                  Answering: {message.subquestion}
                                </div>
                                <div className="text-sm text-gray-900">
                                  {renderMessageContent({
                                    content: cleanContent,
                                    role: message.role,
                                    citations: message.citations,
                                    setIsFeedbackOpen,
                                    executionLog,
                                  })}
                                </div>
                              </div>
                            ) : message.role === "assistant" &&
                              message.citations ? (
                              <div className="break-words">
                                {renderMessageContent({
                                  content: cleanContent,
                                  role: message.role,
                                  citations: message.citations,
                                  setIsFeedbackOpen,
                                  executionLog,
                                })}
                              </div>
                            ) : (
                              <div className="break-words">
                                {renderMessageContent({
                                  content: cleanContent,
                                  role: message.role,
                                  setIsFeedbackOpen,
                                })}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      {message.role === "assistant" && message.tableLogId && (
                        <div className="pb-4 flex justify-start pl-8">
                          <Button
                            variant="outline"
                            size="sm"
                            onClick={() =>
                              handleTableClick(
                                message.tableLogId || "",
                                message.tableRows
                              )
                            }
                          >
                            View Relevant Rows
                          </Button>
                        </div>
                      )}

                      {message.role === "assistant" && (
                        <div className="group">
                          <div
                            className={cn(
                              "flex gap-2 ml-8",
                              feedbackSubmitted[`message-${index}`]
                                ? "opacity-100"
                                : index === messages.length - 1
                                ? "opacity-100"
                                : "opacity-0 group-hover:opacity-100"
                            )}
                          >
                            {/* Only show feedback icons for final answers */}
                            {(!message.type ||
                              message.type === "final_answer") && (
                              <>
                                <button
                                  onClick={() => {
                                    const messageId = `message-${index}`;
                                    if (messageFeedbackTypes[messageId] === 1) {
                                      return;
                                    }
                                    setActiveFeedbackId(messageId);
                                    setMessageFeedbackTypes((prev) => ({
                                      ...prev,
                                      [messageId]: 1,
                                    }));
                                    setFeedbackSubmitted((prev) => ({
                                      ...prev,
                                      [messageId]: true,
                                    }));
                                    handleFeedbackSubmit(undefined, 1);
                                  }}
                                  className="p-1 rounded-full hover:bg-gray-100 group/thumbup transition-colors"
                                >
                                  <ThumbsUp
                                    className={cn(
                                      "w-3.5 h-3.5 transition-colors",
                                      feedbackSubmitted[`message-${index}`] &&
                                        messageFeedbackTypes[
                                          `message-${index}`
                                        ] === 1
                                        ? "text-gray-600 fill-gray-600"
                                        : "text-gray-400 group-hover/thumbup:text-gray-600 group-hover/thumbup:bg-gray-100"
                                    )}
                                  />
                                </button>
                                <button
                                  onClick={() => {
                                    const messageId = `message-${index}`;
                                    if (
                                      messageFeedbackTypes[messageId] === -1 &&
                                      !feedbackSubmitted[messageId]
                                    ) {
                                      return;
                                    }
                                    setActiveFeedbackId(messageId);
                                    setMessageFeedbackTypes((prev) => ({
                                      ...prev,
                                      [messageId]: -1,
                                    }));
                                    setFeedbackSubmitted((prev) => ({
                                      ...prev,
                                      [messageId]: true,
                                    }));
                                    if (
                                      messageFeedbackTypes[messageId] === 1 ||
                                      !feedbackSubmitted[messageId]
                                    ) {
                                      setIsFeedbackOpen(true);
                                    }
                                  }}
                                  className="p-1 rounded-full hover:bg-gray-100 group/thumbdown transition-colors"
                                >
                                  <ThumbsDown
                                    className={cn(
                                      "w-3.5 h-3.5 transition-colors",
                                      feedbackSubmitted[`message-${index}`] &&
                                        messageFeedbackTypes[
                                          `message-${index}`
                                        ] === -1
                                        ? "text-gray-600 fill-gray-600"
                                        : "text-gray-400 group-hover/thumbdown:text-gray-600 group-hover/thumbdown:bg-gray-100"
                                    )}
                                  />
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
                {isStreaming && (
                  <div className="flex items-center space-x-2 justify-center py-2">
                    <div className="w-1.5 h-1.5 bg-gray-400 rounded-full animate-bounce [animation-delay:-0.3s]"></div>
                    <div className="w-1.5 h-1.5 bg-gray-400 rounded-full animate-bounce [animation-delay:-0.15s]"></div>
                    <div className="w-1.5 h-1.5 bg-gray-400 rounded-full animate-bounce"></div>
                  </div>
                )}
                <div ref={messagesEndRef} />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="sticky bottom-6 left-0 right-0 px-6 z-20">
        <div className="max-w-3xl mx-auto">
          <div className="max-w-3xl">
            <div className="mx-2">
              <div className="mt-24">
                <div className="w-full mx-auto">
                  <div className="transition-all duration-200 ease-out">
                    <PromptInput
                      onSend={handleSendMessage}
                      isStreaming={isStreaming}
                      uploadedDocuments={uploadedDocuments}
                      onFileUpload={handleFileUpload}
                      onDeleteDocument={removeFile}
                      isUploading={isUploading}
                      promptCategory={`workflow-${executionLog?.request?.workflow_id}`}
                      onUploadFromSharePoint={handleSharePointUpload}
                      isSharePointLoading={isSharePointLoading}
                      workflowExecutionId={executionId}
                      onMentionedDocumentsChange={setFilteredDocumentIds}
                      invalidateCache={shouldInvalidateCache}
                      isInCanvas={isInCanvas}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <DocumentPreviewDialog
        isOpen={!!previewDocument}
        onClose={() => setPreviewDocument(null)}
        previewUrl={previewDocument?.url || ""}
        originalPdfUrl={originalPdfUrl}
        fileType={previewDocument?.fileType as "pdf" | "excel" | "image"}
      />
      <PDFPreviewSlider
        isOpen={isPdfSliderOpen}
        onClose={() => {
          setIsPdfSliderOpen(false);
          setCurrentPdfUrl(null);
          setOriginalPdfUrl(null);
          setSelectedCitations([]);
          setCurrentFileType("pdf");
        }}
        pdfUrl={currentPdfUrl}
        originalPdfUrl={originalPdfUrl}
        citations={selectedCitations}
        isLoading={isLoadingPdf}
        fileType={currentFileType}
      />
      {isEmailSliderOpen && (
        <EmailPreviewSlider
          isOpen={isEmailSliderOpen}
          onClose={() => {
            setIsEmailSliderOpen(false);
            setCurrentEmailHtml(null);
            // setEmailHighlightText(null);
            setEmailHighlightRange(null);
          }}
          emailHtml={currentEmailHtml}
          // highlightText={emailHighlightText}
          highlightRange={emailHighlightRange}
          isLoading={isLoadingPdf}
        />
      )}
      <FeedbackDialog
        isOpen={isFeedbackOpen}
        onClose={() => setIsFeedbackOpen(false)}
        onSubmit={handleFeedbackSubmit}
      />
    </div>
  );

  useEffect(() => {
    const handleCitationClick = async (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      if (target.classList.contains("citation-link")) {
        e.preventDefault();
        e.stopPropagation();

        const index = parseInt(
          target.getAttribute("data-citation-index") || "0",
          10
        );
        const documentId = target.getAttribute("data-document-id");

        if (documentId) {
          setIsLoadingPdf(true);

          const message = messages.find((m) =>
            m.citations?.some(
              (doc) =>
                doc.document_id === documentId &&
                doc.bbox_values[index]?.content ===
                  target.getAttribute("data-citation-content")
            )
          );

          const citation = message?.citations?.find(
            (doc) => doc.document_id === documentId
          )?.bbox_values[index];

          if (citation) {
            setSelectedCitations([mapCitation(documentId, citation)]);

            try {
              if (!pdfUrls.has(documentId)) {
                const documentDetails = await fetchUserDocument(documentId);
                const fileType = documentDetails.filename
                  .split(".")
                  .pop()
                  ?.toLowerCase();

                if (["eml", "msg"].includes(fileType || "")) {
                  const emailDetails = await fetchEmailDetails(documentId);
                  const mappedCitation = mapCitation(documentId, citation);

                  setSelectedCitations([]);
                  setCurrentPdfUrl(null);

                  if (
                    mappedCitation.citationType === "email" &&
                    typeof mappedCitation.start === "number" &&
                    typeof mappedCitation.end === "number"
                  ) {
                    setEmailHighlightRange({
                      start: mappedCitation.start,
                      end: mappedCitation.end,
                    });
                  } else {
                    setEmailHighlightRange(null);
                  }
                  setCurrentEmailHtml(emailDetails.body_html);
                  setIsEmailSliderOpen(true);
                  setIsPdfSliderOpen(false);
                  setIsLoadingPdf(false);
                  return;
                }

                setIsPdfSliderOpen(true);

                const url = await getPdfUrl(documentId);
                setPdfUrls(
                  produce((draft) => {
                    draft.set(documentId, url);
                  })
                );
                setCurrentPdfUrl(url);
                setCurrentFileType(fileType === "xlsx" ? "excel" : "pdf");
              } else {
                const url = pdfUrls.get(documentId) || null;
                setCurrentPdfUrl(url);

                const documentDetails = await fetchUserDocument(documentId);
                const fileType = documentDetails.filename
                  .split(".")
                  .pop()
                  ?.toLowerCase();

                if (["eml", "msg"].includes(fileType || "")) {
                  const emailDetails = await fetchEmailDetails(documentId);
                  // const mappedCitation = mapCitation(documentId, citation);
                  setSelectedCitations([]);
                  setCurrentPdfUrl(null);

                  setCurrentEmailHtml(emailDetails.body_html);
                  setIsEmailSliderOpen(true);
                  setIsPdfSliderOpen(false);
                  setIsLoadingPdf(false);
                  return;
                }

                setIsPdfSliderOpen(true);
                setCurrentFileType(fileType === "xlsx" ? "excel" : "pdf");
              }
            } catch (error) {
              console.error("Failed to fetch document:", error);
              toast.error("Unable to preview document");
            } finally {
              setIsLoadingPdf(false);
            }
          }
        }
      }
    };

    document.addEventListener("click", handleCitationClick);
    return () => document.removeEventListener("click", handleCitationClick);
  }, [
    messages,
    getPdfUrl,
    fetchUserDocument,
    pdfUrls,
    fetchEmailDetails,
    mapCitation,
  ]);

  useEffect(() => {
    if (executionLog?.title) {
      setWorkflowTitle(executionLog.title);
    }
  }, [executionLog]);

  // Don't show shimmer if workflow status is not_started
  const shouldShowShimmer =
    isLoadingStable && // Use the stable loading state instead
    (!executionLog ||
      executionLog.status !== WorkflowExecutionStatus.NOT_STARTED);

  // Automatically process initial message for Ask AI workflows
  useEffect(() => {
    // Check if this is an Ask AI workflow and we have the execution log
    // Also check if we've already processed the initial message
    if (
      workflowId === ASK_AI_WORKFLOW_ID &&
      executionLog &&
      messages.length === 0 &&
      !initialMessageProcessedRef.current
    ) {
      // Set the ref to true to prevent this effect from running again
      initialMessageProcessedRef.current = true;

      // Get the initial message and documents from the execution log
      const initialInstruction = executionLog.request?.instruction || "";
      const initialDocuments = executionLog.request?.documents || [];

      // Load document information for the initial documents
      const loadDocumentInfo = async () => {
        try {
          const documentPromises = initialDocuments.map(async (docId) => {
            // Check if we already have this document in chatDocuments
            const existingDoc = chatDocuments.find((doc) => doc._id === docId);
            if (existingDoc) {
              return existingDoc;
            }

            // Otherwise, fetch the document information
            try {
              const docInfo = await fetchUserDocument(docId);
              return {
                _id: docId,
                filename: docInfo.filename,
                blob_url: docInfo.blob_url || "",
                category: "chat",
                origin: "workflow",
                owner_uid: "",
                owner_oid: "",
                run_id: executionId,
                description: "",
                user_data: {},
              };
            } catch (error) {
              console.error(
                `Failed to fetch document info for ${docId}:`,
                error
              );
              return null;
            }
          });

          const loadedDocs = (await Promise.all(documentPromises)).filter(
            (doc): doc is UserDocument => doc !== null
          );

          // Update chatDocuments with the loaded documents
          setChatDocuments((prev) => {
            // Filter out duplicates
            const newDocs = loadedDocs.filter(
              (doc) => !prev.some((prevDoc) => prevDoc._id === doc._id)
            );
            return [...prev, ...newDocs];
          });

          // Create a user message with the initial instruction and documents
          const userMessage: Message = {
            role: "user",
            content: initialInstruction,
            timestamp: new Date(),
            attachments: loadedDocs,
          };

          const useSubquestions =
            executionLog.request?.workflow_settings?.use_subquestions;

          // Process the message using the handleSendMessage function directly
          // This will add both the user message and the AI response
          if (initialInstruction) {
            // First add the user message to the messages array
            setMessages([userMessage]);

            // Then call handleSendMessage to get the AI response
            // We set skipUserMessage to true because we've already added the user message
            handleSendMessage(
              initialInstruction,
              initialDocuments,
              useSubquestions,
              true // Skip adding the user message
            );
          } else if (loadedDocs.length > 0) {
            // If there's no instruction but there are documents, just add the user message
            setMessages([userMessage]);
          }
        } catch (error) {
          console.error("Failed to load document information:", error);
        }
      };

      loadDocumentInfo();
    }
  }, [
    workflowId,
    executionLog,
    messages.length,
    chatDocuments,
    ASK_AI_WORKFLOW_ID,
    handleSendMessage,
    fetchUserDocument,
    executionId,
  ]);

  useEffect(() => {
    return () => {
      // Clear uploaded documents when unmounting
      setUploadedDocuments([]);
    };
  }, []);

  return shouldShowShimmer ? (
    renderShimmer()
  ) : isInCanvas ? (
    <Canvas
      leftContent={workflowContent}
      rightContent={
        rightContent || (
          <div className="p-10">Select an action to view details</div>
        )
      }
      onClose={handleCanvasClose}
    />
  ) : (
    <div>{workflowContent}</div>
  );
};

const FeedbackDialog = ({
  isOpen,
  onClose,
  onSubmit,
}: {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (feedback: string) => void;
}) => {
  const [feedback, setFeedback] = useState("");

  const handleSubmit = () => {
    onSubmit(feedback);
    setFeedback("");
    onClose();
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[450px] p-6">
        <DialogHeader>
          <DialogTitle>Feedback</DialogTitle>
          <DialogDescription>
            How can we improve this response? (Optional)
          </DialogDescription>
        </DialogHeader>
        <div>
          <textarea
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            className="w-full h-32 p-3 text-sm border border-gray-200 rounded-md focus:border-purple-600 focus:ring-purple-600"
            placeholder="Enter your feedback here..."
          />
        </div>
        <DialogFooter>
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={handleSubmit}>Send Feedback</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export const WorkflowV1 = withRequiredAuthInfo(
  ({
    accessToken,
    userClass,
    isInCanvas,
  }: {
    accessToken: string;
    userClass: UserClass;
    isInCanvas?: boolean;
  }) => {
    const { executionId } = useParams<{ executionId: string }>();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const fromLanding = queryParams.get("fromLanding") === "true";

    if (!executionId) {
      return <div>No execution ID provided</div>;
    }

    return (
      <div className="min-h-screen">
        <div className="max-w-5xl mx-auto w-full px-4 flex justify-center">
          <div className="w-full max-w-3xl">
            <WorkflowPresentation
              executionId={executionId}
              accessToken={accessToken}
              userClass={userClass}
              isInCanvas={isInCanvas}
              fromLandingPage={fromLanding}
            />
          </div>
        </div>
      </div>
    );
  }
);
