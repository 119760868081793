import { useState, useMemo, useCallback, useRef, useEffect } from "react";
import { PDFPreviewSlider } from "../pdf/PDFPreviewSlider";
import { TableCell as TableCellType, Citation } from "../../../types/table";
import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm'
import rehypeRaw from "rehype-raw";
import { markdownComponents } from "../../core/MarkdownComponents";
import {
  FileText,
  Search,
  ExternalLink,
  PanelLeftClose,
  PanelLeftOpen,
} from "lucide-react";
import { useNodeApi } from "../../../hooks/useNodeApi";
import { toast } from "react-hot-toast";
import DocumentPreviewDialog from "../../DocumentPreviewDialog";
import { useAuthInfo } from "@propelauth/react";
import { Input } from "../../ui/input";
import { getUserDocument } from "../../../services/api";
import { Button } from "../../ui/button";
import { AgGridReact } from "ag-grid-react";
import {
  ColDef,
  GridReadyEvent,
  CellClickedEvent,
  ModuleRegistry,
  AllCommunityModule,
  ValueFormatterParams,
  ICellRendererParams,
  GetMainMenuItemsParams,
  MenuItemDef,
  DefaultMenuItem,
  ValueGetterParams,
} from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "../../table/AgGrid.css";

interface TableMetadata {
  _id?: string;
  title?: string;
  description?: string | null;
  summary?: string | null;
  document_ids?: string[];
  [key: string]: unknown;
}

interface GenericTableProps {
  headers: string[];
  rows: { cells: TableCellType[] }[];
  onCitationClick?: (citations: Citation[]) => void;
  pdfUrl?: string | null;
  filteredRows?: number[];
  title?: string;
  documentIds?: string[];
  accessToken?: string | null;
  metadata?: TableMetadata | null;
  isSimplifiedView?: boolean;
}

export function GenericTable({
  headers,
  rows,
  onCitationClick,
  pdfUrl,
  filteredRows: externalFilteredRows,
  title = "Table Results",
  documentIds = [],
  accessToken = null,
  metadata = null,
  isSimplifiedView = false,
}: GenericTableProps) {
  // Add state for tooltip/popover
  const [tooltipContent, setTooltipContent] = useState<string | null>(null);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const tooltipTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const CitationCellRenderer = (props: ICellRendererParams) => {
    if (!props.colDef?.field) return null;

    const cellData = props.data[`_cellData_${props.colDef.field}`];
    const hasCitations =
      cellData?.citations_with_documents &&
      cellData.citations_with_documents.length > 0;

    if (hasCitations) {
      props.eGridCell?.classList.add("ag-cell-has-citations");
    }

    return (
      <div
        className={`cell-content whitespace-pre-wrap break-words ${
          isSimplifiedView ? "simplified-cell-content" : ""
        }`}
      >
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          rehypePlugins={[rehypeRaw]}
          components={markdownComponents}
          className="prose prose-sm max-w-none"
        >
          {cellData?.value || ""}
        </ReactMarkdown>
      </div>
    );
  };

  // Create simplified cell renderer with tooltip functionality
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const SimplifiedCellRenderer = (props: ICellRendererParams) => {
    const cellData = props.data[`_cellData_${props.colDef?.field}`];
    if (!cellData) return null;

    const cellValue = String(cellData.value || "");
    const hasCitations =
      cellData?.citations_with_documents &&
      cellData.citations_with_documents.length > 0;

    if (hasCitations) {
      props.eGridCell?.classList.add("ag-cell-has-citations");
    }

    const handleMouseEnter = (e: React.MouseEvent) => {
      if (tooltipTimeoutRef.current) {
        clearTimeout(tooltipTimeoutRef.current);
      }

      // Only show tooltip if text is likely to be truncated (longer than ~30 chars)
      if (cellValue.length > 30) {
        const rect = (e.target as HTMLElement).getBoundingClientRect();
        setTooltipPosition({
          x: rect.left,
          y: rect.bottom + window.scrollY + 5,
        });

        // Just show the text without citation information
        setTooltipContent(cellValue);
      }
    };

    const handleMouseLeave = () => {
      tooltipTimeoutRef.current = setTimeout(() => {
        setTooltipContent(null);
      }, 200); // Small delay to prevent flickering
    };

    return (
      <div
        className="simplified-cell-content"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {cellValue}
      </div>
    );
  };

  const [isSliderOpen, setIsSliderOpen] = useState(false);
  const [selectedCitations, setSelectedCitations] = useState<Citation[] | null>(
    null
  );
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [previewUrl, setPreviewUrl] = useState<string>("");
  const [previewFileType, setPreviewFileType] = useState<
    "pdf" | "image" | "excel"
  >("pdf");
  const [filterQuery, setFilterQuery] = useState("");
  const [isExporting, setIsExporting] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const authInfo = useAuthInfo();
  let authToken = accessToken || authInfo?.accessToken || null;

  if (!authToken) {
    const storedToken =
      typeof window !== "undefined"
        ? localStorage.getItem("authToken") ||
          localStorage.getItem("token") ||
          sessionStorage.getItem("token")
        : null;
    if (storedToken) {
      authToken = storedToken;
    }
  }

  const { getPdfUrl, exportTableLog } = useNodeApi(authToken || "");

  const actualDocumentIds = useMemo(() => {
    if (documentIds && documentIds.length > 0) {
      return documentIds;
    }

    if (
      metadata?.document_ids !== undefined &&
      metadata?.document_ids !== null
    ) {
      return metadata.document_ids;
    }

    const citationDocIds = new Set<string>();
    rows.forEach((row) => {
      row.cells.forEach((cell) => {
        if (cell.citations_with_documents?.length) {
          cell.citations_with_documents.forEach((citation) => {
            if (citation.document) {
              citationDocIds.add(citation.document);
            }
          });
        }
      });
    });

    if (citationDocIds.size > 0) {
      return Array.from(citationDocIds);
    }

    return [];
  }, [documentIds, metadata, rows]);

  const filteredRows = useMemo(() => {
    if (!filterQuery.trim()) {
      return externalFilteredRows;
    }

    const query = filterQuery.toLowerCase().trim();

    const indices = rows.reduce<number[]>((filtered, row, index) => {
      const matches = row.cells.some(
        (cell) => cell.value && String(cell.value).toLowerCase().includes(query)
      );

      if (
        matches &&
        (!externalFilteredRows || externalFilteredRows.includes(index))
      ) {
        filtered.push(index);
      }

      return filtered;
    }, []);

    return indices;
  }, [rows, filterQuery, externalFilteredRows]);

  const handleCellClick = useCallback(
    (params: CellClickedEvent) => {
      if (!params.data) return;

      // Hide any active tooltip when clicking cells
      setTooltipContent(null);

      const cellData = params.data[`_cellData_${params.colDef?.field}`];
      if (!cellData?.citations_with_documents?.length) return;

      setSelectedCitations(cellData.citations_with_documents);
      setIsSliderOpen(true);
      onCitationClick?.(cellData.citations_with_documents);
    },
    [onCitationClick]
  );

  const handleDocumentClick = async (docId: string) => {
    if (!authToken) {
      toast.error(
        "Authentication required to view documents. Please log in or refresh the page."
      );
      return;
    }

    try {
      const documentResponse = await getUserDocument(docId, authToken);

      const filename = documentResponse.filename || "";
      let fileType: "pdf" | "image" | "excel" = "pdf";

      if (filename.toLowerCase().match(/\.(xlsx|xls)$/i)) {
        fileType = "excel";
      } else if (filename.toLowerCase().match(/\.(jpe?g|png|gif|bmp|webp)$/i)) {
        fileType = "image";
      }

      const url = await getPdfUrl(docId);

      setPreviewUrl(url);
      setPreviewFileType(fileType);
      setIsPreviewOpen(true);
    } catch (error) {
      console.error("Error loading document:", error);
      toast.error(
        "Failed to load document preview. Please try refreshing the page."
      );
    }
  };

  const handleExport = async () => {
    if (!tableId || !authToken) {
      toast.error("Unable to export: missing table ID or authentication");
      return;
    }

    setIsExporting(true);
    try {
      const url = await exportTableLog(tableId);

      const link = document.createElement("a");
      link.href = url;
      link.download = `${title}.xlsx`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      toast.success("Export successful");
    } catch (error) {
      console.error("Export failed:", error);
      toast.error("Failed to export. Please try again.");
    } finally {
      setIsExporting(false);
    }
  };

  const documentNames = useMemo(() => {
    return headers.length > 1 ? headers.slice(1) : [];
  }, [headers]);

  const tableId = metadata?._id;

  // Register AG Grid modules
  ModuleRegistry.registerModules([AllCommunityModule]);

  // Prepare row data for AG Grid
  const rowData = useMemo(() => {
    const displayRows = filteredRows
      ? rows.filter((_, index) => filteredRows.includes(index))
      : rows;

    return displayRows.map((row) => {
      const rowObj: Record<string, unknown> = {};
      row.cells.forEach((cell, index) => {
        const fieldName = headers[index];
        if (fieldName) {
          // Store the text value for sorting
          rowObj[fieldName] = cell.value || "";
          // Store the full cell data for citations separately
          rowObj[`_cellData_${fieldName}`] = cell;
        }
      });
      return rowObj;
    });
  }, [rows, filteredRows, headers]);

  // Column definitions
  const columnDefs = useMemo(() => {
    return headers.map((header) => ({
      field: header,
      headerName: header,
      editable: false,
      cellRenderer: isSimplifiedView
        ? SimplifiedCellRenderer
        : CitationCellRenderer,
      // Add valueGetter to ensure sorting uses the text value
      valueGetter: (params: ValueGetterParams) => {
        return params.data[params.column.getColId()] || "";
      },
      valueFormatter: (params: ValueFormatterParams) => {
        return params.value !== undefined ? String(params.value) : "";
      },
      sortable: true,
      unSortIcon: false,
      sort: null,
      // Update comparator to handle text values
      comparator: (valueA: string, valueB: string) => {
        // Handle null/undefined values
        if (!valueA) return 1;
        if (!valueB) return -1;
        // Compare the text values
        return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
      },
      minWidth: isSimplifiedView ? 200 : 150,
      menuTabs: ["generalMenuTab", "columnsMenuTab"],
      suppressHeaderFilterButton: true,
    })) as ColDef[];
  }, [headers, isSimplifiedView, CitationCellRenderer, SimplifiedCellRenderer]);

  // Default column settings
  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      filter: true,
      minWidth: isSimplifiedView ? 200 : 150,
      width: isSimplifiedView ? 250 : undefined,
      cellStyle: { borderRight: "" },
      headerClass: "header-cell",
      wrapText: true,
      autoHeight: true,
      menuTabs: ["generalMenuTab", "columnsMenuTab"],
      suppressHeaderFilterButton: true,
      // Add sorting related properties
      unSortIcon: true,
      sort: null,
    } as ColDef;
  }, [isSimplifiedView]);

  // Add getMainMenuItems to customize menu items
  const getMainMenuItems = useCallback(
    (_params: GetMainMenuItemsParams): (MenuItemDef | DefaultMenuItem)[] => {
      return [
        "pinSubMenu" as DefaultMenuItem,
        "separator" as DefaultMenuItem,
        "autoSizeThis" as DefaultMenuItem,
        "autoSizeAll" as DefaultMenuItem,
        "separator" as DefaultMenuItem,
        "resetColumns" as DefaultMenuItem,
        "separator" as DefaultMenuItem,
        "columnChooser" as DefaultMenuItem,
      ];
    },
    []
  );

  // Add a reference to the grid API
  const gridRef = useRef<AgGridReact>(null);

  // Update the isFullScreen handler to include grid resize
  const handleFullScreenChange = () => {
    setIsFullScreen(!isFullScreen);
    // Add small delay to ensure DOM has updated
    setTimeout(() => {
      if (gridRef.current?.api) {
        gridRef.current.api.sizeColumnsToFit();
        updateScrollbarVisibility();
      }
    }, 100);
  };

  // Function to update scrollbar visibility
  const updateScrollbarVisibility = useCallback(() => {
    if (gridRef.current?.api) {
      const gridElement =
        document.querySelector<HTMLElement>(".ag-theme-quartz");
      const horizontalScrollElement = document.querySelector<HTMLElement>(
        ".ag-body-horizontal-scroll"
      );
      const horizontalScrollViewport = document.querySelector<HTMLElement>(
        ".ag-horizontal-scroll-viewport"
      );

      if (gridElement && horizontalScrollElement && horizontalScrollViewport) {
        const gridWidth = gridElement.clientWidth;
        const contentWidth =
          gridRef.current.api.getDisplayedRowCount() > 0
            ? Array.from(gridRef.current.api.getAllDisplayedColumns()).reduce(
                (total, col) => total + col.getActualWidth(),
                0
              )
            : 0;

        if (contentWidth > gridWidth) {
          horizontalScrollElement.classList.add("ag-scrollbar-visible");
          horizontalScrollViewport.classList.add("ag-scrollbar-visible");
        } else {
          horizontalScrollElement.classList.remove("ag-scrollbar-visible");
          horizontalScrollViewport.classList.remove("ag-scrollbar-visible");
        }
      }
    }
  }, []);

  // Add a resize handler to maintain proper sizing
  useEffect(() => {
    const handleResize = () => {
      if (gridRef.current?.api) {
        gridRef.current.api.sizeColumnsToFit();
        updateScrollbarVisibility();
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [updateScrollbarVisibility]);

  // Use ResizeObserver to check for size changes
  useEffect(() => {
    const gridElement = document.querySelector<HTMLElement>(".ag-theme-quartz");

    if (gridElement && window.ResizeObserver) {
      const resizeObserver = new ResizeObserver(() => {
        updateScrollbarVisibility();
      });

      resizeObserver.observe(gridElement);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [updateScrollbarVisibility]);

  // Update the onGridReady callback to check for scrollbar visibility
  const onGridReady = useCallback(
    (params: GridReadyEvent) => {
      // Add a small delay to ensure DOM is ready
      setTimeout(() => {
        // First auto-size columns based on content
        params.api.autoSizeAllColumns();

        // Then adjust to fit the container
        const gridElement =
          document.querySelector<HTMLElement>(".ag-theme-quartz");
        if (gridElement) {
          const containerWidth = gridElement.clientWidth;
          const columnWidths =
            params.api
              .getColumnDefs()
              ?.reduce(
                (total: number, col: ColDef) => total + (col.width || 0),
                0
              ) || 0;

          // If columns are wider than container, resize again
          if (columnWidths > containerWidth) {
            params.api.sizeColumnsToFit();
          }

          // Check if scrollbar is needed
          updateScrollbarVisibility();
        }
      }, 100);
    },
    [updateScrollbarVisibility]
  );

  // Component cleanup
  useEffect(() => {
    return () => {
      if (tooltipTimeoutRef.current) {
        clearTimeout(tooltipTimeoutRef.current);
      }
    };
  }, []);

  return (
    <>
      <div
        className={`w-full space-y-4 ${
          isFullScreen
            ? "fixed inset-0 bg-white z-50 p-6 overflow-auto"
            : "relative"
        }`}
      >
        {!isSimplifiedView &&
          actualDocumentIds &&
          actualDocumentIds.length > 0 && (
            <div className="mb-0">
              <div className="flex items-center gap-2 mb-12 pt-8 ml-0">
                <button
                  onClick={handleFullScreenChange}
                  className="p-1 hover:bg-gray-100 transition-colors duration-200"
                  title={isFullScreen ? "Collapse view" : "Expand view"}
                >
                  {isFullScreen ? (
                    <PanelLeftOpen className="h-5 w-5 text-black" />
                  ) : (
                    <PanelLeftClose className="h-5 w-5 text-black" />
                  )}
                </button>
                <div className="h-5 w-px bg-gray-500/60" />
                <h3 className="text-lg font-semibold">Uploaded Documents</h3>
              </div>
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3">
                {actualDocumentIds.map((docId: string, index: number) => (
                  <div
                    key={docId}
                    onClick={() => handleDocumentClick(docId)}
                    className="flex items-center p-3 rounded border border-gray-200 bg-white hover:bg-gray-50 cursor-pointer"
                  >
                    <div className="w-9 h-9 rounded-full bg-purple-100 flex items-center justify-center mr-3">
                      <FileText className="w-5 h-5 text-purple-600" />
                    </div>
                    <div className="flex-1 overflow-hidden">
                      <span className="text-sm font-medium text-gray-900 block truncate">
                        {index < documentNames.length
                          ? documentNames[index]
                          : `Document ${index + 1}`}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

        <div
          className={`flex justify-between items-center ${
            !isSimplifiedView &&
            actualDocumentIds &&
            actualDocumentIds.length > 0
              ? "pt-2"
              : isSimplifiedView
              ? "pt-4 mx-0 mb-4"
              : "pt-10 ml-0 mb-8"
          }`}
        >
          <div className="flex items-center gap-2">
            {!isSimplifiedView &&
              (!actualDocumentIds || actualDocumentIds.length === 0) && (
                <>
                  <button
                    onClick={handleFullScreenChange}
                    className="p-1 hover:bg-gray-100 transition-colors duration-200"
                    title={isFullScreen ? "Collapse view" : "Expand view"}
                  >
                    {isFullScreen ? (
                      <PanelLeftOpen className="h-5 w-5 text-black" />
                    ) : (
                      <PanelLeftClose className="h-5 w-5 text-black" />
                    )}
                  </button>
                  <div className="h-5 w-px bg-gray-500/60" />
                </>
              )}
            {!isSimplifiedView && (
              <h3 className="text-lg font-semibold tracking-tight">{title}</h3>
            )}
          </div>
          <div className="flex items-center space-x-4">
            {!isSimplifiedView && (
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Search className="h-4 w-4 text-gray-400" />
                </div>
                <Input
                  type="text"
                  placeholder="Filter Fields..."
                  className="pl-10 py-2 w-[250px]"
                  value={filterQuery}
                  onChange={(e) => setFilterQuery(e.target.value)}
                />
              </div>
            )}
            {!isSimplifiedView && (
              <Button
                variant="outline"
                size="sm"
                className="text-gray-700 hover:text-gray-900 border border-gray-300 hover:bg-white"
                onClick={handleExport}
                disabled={isExporting || !tableId || !authToken}
              >
                <ExternalLink className="h-4 w-4 mr-2" />
                {isExporting ? "Exporting..." : "Export"}
              </Button>
            )}
          </div>
        </div>
        <div className="w-full">
          <div
            className={`ag-theme-quartz w-full ${
              isSimplifiedView ? "simplified-view" : ""
            }`}
          >
            <AgGridReact
              ref={gridRef}
              rowData={rowData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              headerHeight={60}
              animateRows={true}
              rowSelection={undefined}
              enableCellTextSelection={true}
              suppressRowClickSelection={true}
              suppressCellFocus={true}
              domLayout="autoHeight"
              onGridReady={onGridReady}
              onFirstDataRendered={(params) => {
                if (isSimplifiedView) {
                  params.api.sizeColumnsToFit();
                } else {
                  params.api.autoSizeAllColumns();
                  params.api.sizeColumnsToFit();
                }
                updateScrollbarVisibility();
              }}
              onCellClicked={handleCellClick}
              getRowHeight={isSimplifiedView ? () => 48 : undefined}
              suppressRowTransform={true}
              getMainMenuItems={getMainMenuItems}
              suppressMenuHide={true}
              suppressHorizontalScroll={false}
            />
          </div>
        </div>
      </div>
      {pdfUrl && (
        <PDFPreviewSlider
          isOpen={isSliderOpen}
          onClose={() => {
            setIsSliderOpen(false);
            setSelectedCitations(null);
          }}
          pdfUrl={pdfUrl}
          citations={
            selectedCitations?.map((citation) => ({
              citation: citation.citation,
              state: "match",
            })) ?? null
          }
          isLoading={false}
        />
      )}

      <DocumentPreviewDialog
        isOpen={isPreviewOpen}
        onClose={() => {
          setPreviewUrl("");
          setIsPreviewOpen(false);
        }}
        previewUrl={previewUrl}
        fileType={previewFileType}
      />

      {/* Add tooltip/popover element */}
      {tooltipContent && (
        <div
          className="tooltip-content"
          style={{
            position: "absolute",
            left: `${tooltipPosition.x}px`,
            top: `${tooltipPosition.y}px`,
            zIndex: 1000,
          }}
          onMouseEnter={() => {
            if (tooltipTimeoutRef.current) {
              clearTimeout(tooltipTimeoutRef.current);
            }
          }}
          onMouseLeave={() => {
            tooltipTimeoutRef.current = setTimeout(() => {
              setTooltipContent(null);
            }, 200);
          }}
        >
          {tooltipContent}
        </div>
      )}
    </>
  );
}
