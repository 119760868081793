import { useState, useCallback, useRef, useMemo, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useNodeApi } from "../../hooks/useNodeApi";
import {
  Citation,
  CitationWithState,
  ExtractMultiLogResponse,
  UserDocumentDetails,
} from "../../services/api";
import { Button } from "../ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table";
import {
  ExternalLink,
  Search,
  FileText,
  ChevronDown,
  PanelLeftClose,
  PanelLeftOpen,
} from "lucide-react";
import toast from "react-hot-toast";
import { Input } from "../ui/input";
import { PDFPreviewSlider } from "../common/pdf/PDFPreviewSlider";
import { produce } from "immer";
import {
  WithLoggedInAuthInfoProps,
  withRequiredAuthInfo,
} from "@propelauth/react";
import axios from "axios";
import { usePolling } from "../../lib/usePolling";
import { POLLING_CONFIG } from "../../config/pollingConfig";
import DocumentPreviewDialog from "../DocumentPreviewDialog";
import { cn } from "../../lib/utils";
import React from "react";
import { Breadcrumbs } from "../core/Breadcrumbs";

type SimpleValue = string | number | boolean | null;
type NestedValue = SimpleValue | Record<string, unknown> | Array<unknown>;

interface ExtractedRow {
  title: string;
  value: SimpleValue | NestedValue;
  citations: Citation[];
  documentIndex: number;
}

interface ProcessedRow {
  title: string;
  value: SimpleValue | NestedValue;
  citations: Citation[];
  documentIndex: number;
}

type TraversableData =
  | Citation
  | { [key: string]: TraversableData | TraversableData[] }
  | TraversableData[];

const TableRowWithExpansion = ({ row, rowIndex, handleCellClick, getColumnWidth }: {
  row: ProcessedRow;
  rowIndex: number;
  handleCellClick: (rowIndex: number, citations: Citation[], documentIndex: number) => void;
  getColumnWidth: (columnName: string, rows: ProcessedRow[]) => string;
}) => {
  const [nestedStates, setNestedStates] = useState<Record<string, boolean>>({});

  const toggleNestedState = useCallback((path: string) => {
    setNestedStates((prev) => ({
      ...prev,
      [path]: prev[path] === undefined ? false : !prev[path]
    }));
  }, []);

  const getFieldCitations = useCallback((
    citations: Citation[] | Record<string, Citation[]> | null,
    parentTitle: string,
    key: string
  ): Citation[] => {
    if (!citations) return [];

    if (Array.isArray(citations)) {
      return citations.filter(citation => citation && typeof citation === 'object');
    }

    const fieldCitations = citations[key];
    if (!fieldCitations) return [];

    return Array.isArray(fieldCitations)
      ? fieldCitations.filter(citation => citation && typeof citation === 'object')
      : [];
  }, []);

  const renderNestedValue = useCallback((
    nestedValue: unknown,
    nestedKey: string,
    depth = 1,
    parentPath = ''
  ) => {
    const currentPath = parentPath ? `${parentPath}.${nestedKey}` : nestedKey;

    const fieldCitations = getFieldCitations(row.citations, row.title, currentPath)
      .filter(citation => citation && typeof citation === 'object');

    const isNestedObject = typeof nestedValue === 'object' && nestedValue !== null;
    const isOpen = nestedStates[currentPath] ?? true;

    const currentRows: JSX.Element[] = [];

    if (isNestedObject) {
      if (depth === 1) {
        currentRows.push(
          //Row Hover for table Parent element of nested table
          //className="hover:bg-transparent data-[state=selected]:bg-transparent"
          <TableRow key={`${rowIndex}-${currentPath}-parent`} className="hover:bg-transparent data-[state=selected]:bg-transparent">
            <TableCell className="border-y border-gray-200 hover:bg-gray-50">
              <div
                className={cn(
                  "flex items-center justify-between w-full cursor-pointer"
                )}
                style={{ paddingLeft: `${depth * 2}rem` }}
                onClick={() => toggleNestedState(currentPath)}
              >
                <span className="font-medium">
                  {nestedKey.split('_').map(word =>
                    word.charAt(0).toUpperCase() + word.slice(1)
                  ).join(' ')}
                </span>
                <ChevronDown
                  className={cn(
                    "h-4 w-4 text-gray-500 transition-transform duration-200",
                    isOpen ? "transform rotate-180" : ""
                  )}
                />
              </div>
            </TableCell>
            <TableCell
              className={cn(
                "border border-gray-200 hover:bg-gray-50",
                fieldCitations.length > 0 && "cursor-pointer"
              )}
              onClick={() => {
                if (fieldCitations.length > 0) {
                  handleCellClick(rowIndex, fieldCitations, row.documentIndex);
                }
              }}
            />
          </TableRow>
        );

        if (!isOpen) {
          return currentRows;
        }
      } else {
        currentRows.push(
          //Does not effect table hover
          <TableRow key={`${rowIndex}-${currentPath}`}>
            <TableCell className="border-y border-gray-200">
              <span className="font-medium" style={{ paddingLeft: `${depth * 2}rem` }}>
                {nestedKey.split('_').map(word =>
                  word.charAt(0).toUpperCase() + word.slice(1)
                ).join(' ')}
              </span>
            </TableCell>
            <TableCell
              className={cn(
                "border border-gray-200",
                fieldCitations.length > 0 && "cursor-pointer hover:bg-gray-50"
              )}
              onClick={() => {
                if (fieldCitations.length > 0) {
                  handleCellClick(rowIndex, fieldCitations, row.documentIndex);
                }
              }}
            />
          </TableRow>
        );
      }

      if (Array.isArray(nestedValue)) {
        nestedValue.forEach((item) => {
          if (typeof item === 'object' && item !== null) {
            Object.entries(item as Record<string, unknown>).forEach(([itemKey, itemValue]) => {
              const nestedRows = renderNestedValue(itemValue, itemKey, depth + 1, currentPath);
              currentRows.push(...nestedRows);
            });
          } else {
            const nestedRows = renderNestedValue(item, String(item), depth + 1, currentPath);
            currentRows.push(...nestedRows);
          }
        });
      } else {
        Object.entries(nestedValue as Record<string, unknown>).forEach(([childKey, childValue]) => {
          const nestedRows = renderNestedValue(childValue, childKey, depth + 1, currentPath);
          currentRows.push(...nestedRows);
        });
      }
    } else {
      currentRows.push(
        //Row Hover for table Children row of nested table and general rows of normal table
        <TableRow key={`${rowIndex}-${currentPath}`} className="hover:bg-transparent data-[state=selected]:bg-transparent">
          <TableCell className="border-y border-gray-200 hover:bg-gray-50">
            <span className="font-medium" style={{ paddingLeft: `${depth * 2}rem` }}>
              {nestedKey.split('_').map(word =>
                word.charAt(0).toUpperCase() + word.slice(1)
              ).join(' ')}
            </span>
          </TableCell>
          <TableCell
            className={cn(
              "border border-gray-200 hover:bg-gray-50",
              fieldCitations.length > 0 && "cursor-pointer",  //hover on citations
              getColumnWidth(nestedKey, [row])
            )}
            onClick={() => {
              if (fieldCitations.length > 0) {
                handleCellClick(rowIndex, fieldCitations, row.documentIndex);
              }
            }}
          >
            {String(nestedValue ?? '')}
          </TableCell>
        </TableRow>
      );
    }

    return currentRows;
  }, [nestedStates, toggleNestedState, row, rowIndex, handleCellClick, getColumnWidth, getFieldCitations]);

  return <>{renderNestedValue(row.value, row.title, 1)}</>;
};

interface ExtractDetailCanvasBaseProps {
  initialExtractId?: string;
  isEmbedded?: boolean;
  extractTitle?: string;
}

const ExtractDetailCanvasBase = ({
  initialExtractId,
  isEmbedded = false,
  extractTitle = "Extracted Data",
  accessToken,
}: WithLoggedInAuthInfoProps & ExtractDetailCanvasBaseProps) => {
  const navigate = useNavigate();
  const { id: urlExtractId } = useParams<{ id: string }>();
  const extractId = initialExtractId || urlExtractId;

  const {
    getMultiExtractionResults,
    getPdfUrl,
    exportMultiExtractLog,
    fetchUserDocument,
  } = useNodeApi(accessToken);
  const [extractLog, setExtractLog] = useState<ExtractMultiLogResponse | null>(
    null
  );
  const [error, setError] = useState<string | null>(null);
  const lastPollTimeRef = useRef(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isSliderOpen, setIsSliderOpen] = useState(false);
  const [pdfUrls, setPdfUrls] = useState<Map<number, string>>(new Map());
  const [currentPdfUrl, setCurrentPdfUrl] = useState<string | null>(null);
  const [isLoadingPdf, setIsLoadingPdf] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [selectedCitations, setSelectedCitations] = useState<
    CitationWithState[]
  >([]);
  const [filterQuery, setFilterQuery] = useState("");
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [previewUrl, setPreviewUrl] = useState("");
  const [previewFileType, setPreviewFileType] = useState<"pdf" | "image">(
    "pdf"
  );
  const [documentDetails, setDocumentDetails] = useState<
    Record<string, UserDocumentDetails>
  >({});
  const [isLoadingDocument, setIsLoadingDocument] = useState(false);
  const [documentError, setDocumentError] = useState<string | null>(null);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const showOutputOnGrid = extractLog?.request?.options?.outputAsGrid ?? false;

  const extractedData = useMemo((): ExtractedRow[] => {
    if (!extractLog?.result?.results) return [];

    if (showOutputOnGrid) {
      const allResults = extractLog.result.results;
      if (!allResults.length) return [];

      const dataKey = Object.keys(allResults[0].data)[0];

      return [
        {
          title: dataKey,
          value: allResults.flatMap((result, documentIndex) => {
            const nameData = result.data[dataKey];
            if (Array.isArray(nameData)) {
              return nameData.map((item) => {
                if (typeof item === "object" && item !== null) {
                  return {
                    ...item,
                    _documentIndex: documentIndex,
                  };
                }
                return {
                  value: item,
                  _documentIndex: documentIndex,
                };
              });
            }
            if (typeof nameData === "object" && nameData !== null) {
              return {
                ...nameData,
                _documentIndex: documentIndex,
              };
            }
            return {
              value: nameData,
              _documentIndex: documentIndex,
            };
          }),
          citations: allResults
            .map((result) => result.citations?.[dataKey] || [])
            .flat(),
          documentIndex: 0,
        },
      ];
    }

    // Non-grid view logic remains the same
    return extractLog.result.results.flatMap((result, documentIndex) =>
      Object.entries(result.data).map(([key, value]) => ({
        title: key,
        value: value as SimpleValue | NestedValue,
        citations: result.citations?.[key] || [],
        documentIndex,
      }))
    );
  }, [extractLog, showOutputOnGrid]);
  const filteredRows = useMemo(() => {
    if (!extractedData) return [];

    const query = filterQuery.toLowerCase().trim();
    if (!query) return extractedData;

    return extractedData.filter((row) => {
      const stringValue = String(row.value ?? "");
      return (
        (row.title || "").toLowerCase().includes(query) ||
        stringValue.toLowerCase().includes(query)
      );
    });
  }, [extractedData, filterQuery]);

  usePolling<ExtractMultiLogResponse>(
    () => {
      if (!extractId) {
        throw new Error("No extraction ID provided.");
      }
      return getMultiExtractionResults(extractId);
    },
    {
      interval: POLLING_CONFIG.INITIAL_INTERVAL,
      maxInterval: POLLING_CONFIG.MAX_INTERVAL,
      backoffMultiplier: POLLING_CONFIG.BACKOFF_MULTIPLIER,
      enabled: true,
      lastPollTimeRef,
      continuePollingOnSuccess: true,
      isComplete: (result) => {
        return result.status === "completed" || result.status === "failed";
      },
      onSuccess: (result) => {
        setExtractLog(result);
        setError(null);
        setIsLoading(false);

        if (result.status === "failed") {
          toast.error("Extraction failed");
          return false;
        }

        if (result.status === "completed") {
          return true;
        }

        return false;
      },
      onError: (error) => {
        setIsLoading(false);
        if (axios.isAxiosError(error) && error.response?.status === 404) {
          navigate("/404");
          return false;
        }
        setError(error instanceof Error ? error.message : "Unknown error");
        return true;
      },
      onOfflineChange: (isOffline) => {
        if (isOffline) {
          toast.error("Failed to fetch extraction results. Please try again.");
        } else {
          toast.success("Connection restored. Extract log updated.");
        }
      },
    }
  );

  useEffect(() => {
    const fetchDocumentDetails = async () => {
      if (!extractLog?.request?.documents?.length) return;

      setIsLoadingDocument(true);
      try {
        const details: Record<string, UserDocumentDetails> = {};
        for (const documentId of extractLog.request.documents) {
          const documentDetail = await fetchUserDocument(documentId);
          details[documentId] = documentDetail;
        }
        setDocumentDetails(details);
        setDocumentError(null);
      } catch (error) {
        setDocumentError("Failed to load document details");
      } finally {
        setIsLoadingDocument(false);
      }
    };

    fetchDocumentDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extractLog?.request?.documents]);

  const handleExport = async () => {
    if (!extractLog) return;

    setIsExporting(true);
    try {
      const downloadUrl = await exportMultiExtractLog(extractLog._id);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.target = "_blank";
      link.download = `extraction_${extractLog._id}.xlsx`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      toast.success("Export successful. Your download should begin shortly.");
    } catch (error) {
      console.error("Export failed:", error);
      toast.error("Failed to export extraction. Please try again.");
    } finally {
      setIsExporting(false);
    }
  };

  type CitationData =
    | Citation[]
    | Record<string, Citation[]>
    | Record<string, Record<string, Citation[]>>;

  const handleCellClick = useCallback(async (
    rowIndex: number,
    citations: CitationData,
    documentIndex: number
  ) => {
    const extractCitations = (data: TraversableData): Citation[] => {
      const results: Citation[] = [];

      const traverse = (obj: TraversableData): void => {
        if (!obj) return;

        if (typeof obj === 'object' && 'bbox' in obj && 'type' in obj) {
          results.push(obj as Citation);
          return;
        }

        if (Array.isArray(obj)) {
          obj.forEach(item => traverse(item));
          return;
        }

        if (typeof obj === 'object') {
          Object.values(obj).forEach(value => traverse(value as TraversableData));
        }
      };

      traverse(data);
      return results;
    };

    setIsSliderOpen(true);
    setIsLoadingPdf(true);
    setCurrentPdfUrl(null);

    const flattenedCitations = extractCitations(citations);

    setSelectedCitations(flattenedCitations.map(citation => ({
      citation,
      state: 'match'
    })));

    try {
      const documentId = extractLog?.request?.documents?.[documentIndex];
      if (!documentId) throw new Error('Document ID not found');

      if (!pdfUrls.has(documentIndex)) {
        const url = await getPdfUrl(documentId);
        setPdfUrls(produce(draft => {
          draft.set(documentIndex, url);
        }));
        setCurrentPdfUrl(url);
      } else {
        setCurrentPdfUrl(pdfUrls.get(documentIndex) || null);
      }
    } catch (error) {
      toast.error("Failed to load PDF preview. Please try again.");
    } finally {
      setIsLoadingPdf(false);
    }
  }, [extractLog, getPdfUrl, pdfUrls, setPdfUrls, setCurrentPdfUrl]);

  const handleDocumentClick = async (index: number) => {
    try {
      const documentId = extractLog?.request?.documents?.[index];
      if (documentId) {
        const url = await getPdfUrl(documentId);
        setPreviewFileType("pdf");
        setPreviewUrl(url);
        setIsPreviewOpen(true);
      }
    } catch (error) {
      console.error("Failed to fetch PDF URL:", error);
      toast.error("Failed to load PDF preview");
    }
  };

  const documentTitles = useMemo(() => {
    return (
      extractLog?.request?.documents?.map((docId, index) => {
        if (documentError) return "Document";
        if (documentDetails[docId]?.filename)
          return documentDetails[docId].filename;
        if (isLoadingDocument) return "Loading...";
        return `Document ${index + 1}`;
      }) || []
    );
  }, [
    documentDetails,
    isLoadingDocument,
    documentError,
    extractLog?.request?.documents,
  ]);

  const getColumnWidth = useMemo(() => {
    return (columnName: string, rows: ProcessedRow[]) => {
      // Find maximum length in the column
      const maxLength = Math.max(
        ...rows.map((row) => {
          if (Array.isArray(row.value)) {
            return Math.max(
              ...row.value.map((item) => {
                const value = (item as Record<string, unknown>)[columnName];
                return String(value ?? "").length;
              })
            );
          }
          return 0;
        })
      );

      if (maxLength > 350) {
        return "min-w-[600px]";
      }
      if (maxLength > 100) {
        return "min-w-[350px]";
      }
      return ""; // Default table column width
    };
  }, []);

  if (isLoading) {
    return <ExtractDetailSkeleton isEmbedded={isEmbedded} />;
  }

  if (error) {
    return <ExtractDetailError error={error} />;
  }

  if (!extractLog || !extractLog.result) {
    return <ExtractDetailSkeleton isEmbedded={isEmbedded} />;
  }

  return (
    <div className="h-full bg-white">
      <div className="mx-auto px-2">
        <div
          className={cn(
            "bg-white h-full overflow-y-auto",
            isFullScreen ? "fixed inset-0 z-50 p-6" : ""
          )}
        >
          <div className="p-6">
            <div>
              <Breadcrumbs module="" path="/extract" isEmbedded={isEmbedded} />

              {extractLog?.request?.documents?.length > 0 ? (
                <div className="mb-6 pt-1">
                  <div className="flex items-center gap-2 mb-4">
                    <button
                      onClick={() => setIsFullScreen(!isFullScreen)}
                      className="p-1 hover:bg-gray-100 transition-colors duration-200"
                      title={isFullScreen ? "Collapse view" : "Expand view"}
                    >
                      {isFullScreen ? (
                        <PanelLeftOpen className="h-5 w-5 text-black" />
                      ) : (
                        <PanelLeftClose className="h-5 w-5 text-black" />
                      )}
                    </button>
                    <div className="h-5 w-px bg-gray-500/60" />
                    <h3 className="text-lg font-semibold">
                      Uploaded documents
                    </h3>
                  </div>
                  <div className="flex gap-4 pt-4">
                    {extractLog?.request?.documents?.map((docId, index) => (
                      <div
                        key={docId}
                        onClick={() => handleDocumentClick(index)}
                        className="flex items-center p-4 rounded-lg border border-gray-200 bg-white hover:bg-gray-50 cursor-pointer"
                      >
                        <div className="w-8 h-8 rounded-full bg-purple-100 flex items-center justify-center mr-3">
                          <FileText className="w-4 h-4 text-purple-600" />
                        </div>
                        <div className="flex flex-col">
                          <span className="text-sm font-medium text-gray-900">
                            {documentTitles[index]}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}

              <div className="flex items-center justify-between mb-4">
                <div className="flex items-center gap-2">
                  {!extractLog?.request?.documents?.length && (
                    <>
                      <button
                        onClick={() => setIsFullScreen(!isFullScreen)}
                        className="p-1 hover:bg-gray-100 transition-colors duration-200"
                        title={isFullScreen ? "Collapse view" : "Expand view"}
                      >
                        {isFullScreen ? (
                          <PanelLeftOpen className="h-5 w-5 text-black" />
                        ) : (
                          <PanelLeftClose className="h-5 w-5 text-black" />
                        )}
                      </button>
                      <div className="h-5 w-px bg-gray-500/60" />
                    </>
                  )}
                  <h3 className="text-lg font-semibold">{extractTitle}</h3>
                </div>
                <div className="flex items-center space-x-4">
                  <div className="relative">
                    <Input
                      type="text"
                      placeholder="Filter fields..."
                      className="pl-8 pr-4 py-1 w-48 h-9"
                      value={filterQuery}
                      onChange={(e) => setFilterQuery(e.target.value)}
                    />
                    <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
                  </div>
                  <Button
                    variant="outline"
                    size="sm"
                    className="text-gray-700 hover:text-gray-900 border border-gray-300 hover:bg-white"
                    onClick={handleExport}
                    disabled={isExporting}
                  >
                    <ExternalLink className="h-4 w-4 mr-2" />
                    {isExporting ? "Exporting..." : "Export"}
                  </Button>
                </div>
              </div>
            </div>

            <div className="overflow-x-auto">
              {showOutputOnGrid ? (
                <Table className="w-full border-collapse border border-gray-200">
                  <TableHeader>
                    <TableRow>
                      <TableHead className="w-[40px] border-y border-l border-gray-200">
                        #
                      </TableHead>
                      {filteredRows[0]?.value &&
                        Array.isArray(filteredRows[0].value) &&
                        typeof filteredRows[0].value[0] === "object" &&
                        filteredRows[0].value[0] &&
                        Object.keys(
                          filteredRows[0].value[0] as Record<string, unknown>
                        )
                          .filter(
                            (columnName) => columnName !== "_documentIndex"
                          )
                          .map((columnName, index) => (
                            <TableHead
                              key={index}
                              className={cn(
                                "border border-gray-200",
                                getColumnWidth(columnName, filteredRows)
                              )}
                            >
                              {columnName}
                            </TableHead>
                          ))}
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {filteredRows.map((row) => {
                      if (!Array.isArray(row.value)) return null;

                      return (row.value as Array<Record<string, unknown>>).map(
                        (item, index) => {
                          const rowCitations =
                            row.citations && Array.isArray(row.citations)
                              ? row.citations[index]
                              : null;
                          const documentIndex = item._documentIndex as number;

                          return (
                            <TableRow key={index}>
                              <TableCell className="border-y border-l border-gray-200">
                                {index + 1}
                              </TableCell>
                              {Object.entries(item)
                                .filter(([key]) => key !== "_documentIndex")
                                .map(([key, value], cellIndex) => {
                                  const citations =
                                    rowCitations &&
                                      typeof rowCitations === "object"
                                      ? (
                                        rowCitations as unknown as Record<
                                          string,
                                          Citation[]
                                        >
                                      )[key] || []
                                      : [];

                                  return (
                                    <TableCell
                                      key={cellIndex}
                                      className={cn(
                                        "border border-gray-200",
                                        citations.length > 0 &&
                                        "cursor-pointer hover:bg-gray-50",
                                        getColumnWidth(key, filteredRows)
                                      )}
                                      onClick={() => {
                                        if (citations.length > 0) {
                                          handleCellClick(
                                            index,
                                            citations,
                                            documentIndex
                                          );
                                        }
                                      }}
                                    >
                                      {String(value ?? "")}
                                    </TableCell>
                                  );
                                })}
                            </TableRow>
                          );
                        }
                      );
                    })}
                  </TableBody>
                </Table>
              ) : (
                <Table className="w-full border-collapse border border-gray-200">
                  <TableHeader>
                    <TableRow>
                      <TableHead className="border-y border-gray-200">
                        Field
                      </TableHead>
                      <TableHead className="border border-gray-200">
                        Value
                      </TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {filteredRows.map((row, rowIndex) => (
                      <TableRowWithExpansion
                        key={rowIndex}
                        row={row}
                        rowIndex={rowIndex}
                        handleCellClick={handleCellClick}
                        getColumnWidth={getColumnWidth}
                      />
                    ))}
                  </TableBody>
                </Table>
              )}
            </div>
          </div>
        </div>
      </div>
      <PDFPreviewSlider
        isOpen={isSliderOpen}
        onClose={() => {
          setIsSliderOpen(false);
          setCurrentPdfUrl(null);
          setSelectedCitations([]);
        }}
        pdfUrl={currentPdfUrl}
        citations={selectedCitations}
        isLoading={isLoadingPdf}
      />
      <DocumentPreviewDialog
        isOpen={isPreviewOpen}
        onClose={() => {
          setPreviewUrl("");
          setIsPreviewOpen(false);
        }}
        previewUrl={previewUrl}
        fileType={previewFileType}
      />
    </div>
  );
};

export const ExtractDetailCanvas = withRequiredAuthInfo(
  ExtractDetailCanvasBase
) as (props: ExtractDetailCanvasBaseProps) => JSX.Element;

function ExtractDetailSkeleton({ isEmbedded }: { isEmbedded?: boolean }) {
  return (
    <div className="h-full bg-white">
      <div className="mx-auto px-2">
        <div className="bg-white h-full overflow-y-auto">
          <div className="p-6">
            <div className="flex items-center justify-between mb-6">
              <Breadcrumbs
                module="Extract"
                path="/extract"
                isEmbedded={isEmbedded}
              />
            </div>

            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center space-x-2">
                <div className="relative">
                  <Input
                    type="text"
                    placeholder="Filter fields..."
                    className="pl-8 pr-4 py-1 w-48 h-9"
                    disabled
                  />
                  <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
                </div>
              </div>
              <Button
                variant="outline"
                size="sm"
                className="text-gray-700 hover:text-gray-900 border border-gray-300 hover:bg-white"
                disabled
              >
                <ExternalLink className="h-4 w-4 mr-2" />
                Export
              </Button>
            </div>

            <div className="mb-6">
              <h3 className="text-lg font-semibold mb-4">Uploaded document</h3>
              <div className="grid grid-cols-1 gap-4">
                <div className="flex items-center p-4 rounded-lg border border-gray-200 bg-white">
                  <div className="w-8 h-8 rounded-full bg-gray-100 animate-pulse flex items-center justify-center mr-3">
                    <FileText className="w-4 h-4 text-gray-400" />
                  </div>
                  <div className="h-4 bg-gray-200 rounded animate-pulse w-48" />
                </div>
              </div>
            </div>

            <div className="mb-6">
              <Table className="border-collapse border border-gray-200">
                <TableHeader>
                  <TableRow>
                    <TableHead className="border-y border-gray-200">
                      Field
                    </TableHead>
                    <TableHead className="border border-gray-200">
                      Value
                    </TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {[...Array(8)].map((_, index) => (
                    <TableRow key={index}>
                      <TableCell className="border-y border-gray-200">
                        <div className="h-4 bg-gray-200 rounded animate-pulse w-32" />
                      </TableCell>
                      <TableCell className="border border-gray-200">
                        <div
                          className="h-4 bg-gray-200 rounded animate-pulse"
                          style={{ width: `${Math.random() * 30 + 50}%` }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function ExtractDetailError({ error }: { error: string }) {
  return (
    <div className="h-full bg-white">
      <div className="mx-auto px-2">
        <div className="bg-white h-full overflow-y-auto">
          <div className="p-6">
            <h1 className="text-xl font-semibold mb-6">Error</h1>
            <p className="text-red-500 mb-4">{error}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
