import { Button } from "./ui/button";
import {
  Sparkles,
  PhoneCall,
  ChartColumnStacked,
  Table2,
  Blocks,
  LogIn,
  LogOut,
  MoreVertical,
  SearchCheck,
  PanelLeft,
  FileOutput,
  Telescope,
  Plane,
  Bot,
  FileInput,
  ShieldCheck,
  ClipboardCheck,
  MessagesSquare,
  ListChecks,
  Brain,
  Database,
  FileJson,
  FileText,
  Filter,
  FolderSearch,
  GitBranch,
  History,
  LineChart,
  Mail,
  MessageCircle,
  Network,
  PenTool,
  Puzzle,
  QrCode,
  Receipt,
  Replace,
  ScrollText,
  Settings2,
  Sigma,
  Sliders,
  ScanLine,
  Webhook,
  Workflow,
  Zap,
  Binary,
  TableProperties,
} from "lucide-react";
import { Separator } from "../components/ui/separator";
import furtherAiLogo from "../assets/fai-logo.svg";
import furtherAiLogoShort from "../assets/fai-logo-short.svg";
import { useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  User,
  withAuthInfo,
  useLogoutFunction,
  useRedirectFunctions,
  UserClass,
} from "@propelauth/react";
import toast from "react-hot-toast";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu";
import userDefaultImg from "../assets/avatars/user-img.png";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./ui/tooltip";
import { useNodeApi } from "../hooks/useNodeApi";
import { WorkflowV1 } from "../services/api";
import React from "react";

export const SIDEBAR_WIDTH = "14rem";
export const SIDEBAR_WIDTH_ICON = "4.5rem";

interface SidebarButtonProps {
  icon: React.ReactNode;
  label: string;
  isActive: boolean;
  onClick: () => void;
  isCollapsed: boolean;
}

const SidebarButton = ({
  icon,
  label,
  isActive,
  onClick,
  isCollapsed,
}: SidebarButtonProps) => {
  const ButtonContent = (
    <Button
      variant="ghost"
      className={`w-full justify-${isCollapsed ? "center" : "start"} ${
        isActive
          ? "bg-gray-100 text-blue-700 hover:text-blue-700"
          : "text-gray-700 hover:bg-gray-100 hover:text-gray-700"
      } ${isCollapsed ? "px-2" : ""}`}
      onClick={onClick}
    >
      {icon}
      {!isCollapsed && (
        <span className="text-sm font-semibold truncate overflow-hidden">
          {label}
        </span>
      )}
    </Button>
  );

  if (isCollapsed) {
    return (
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>{ButtonContent}</TooltipTrigger>
          <TooltipContent
            side="right"
            align="center"
            sideOffset={10}
            className="bg-gray-50"
          >
            <p className="font-medium">{label}</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    );
  }

  return ButtonContent;
};

interface HomeSidebarProps {
  accessToken: string | null;
  isLoggedIn: boolean;
  user: User | null;
  userClass: UserClass | null;
  onSidebarToggle?: (isCollapsed: boolean) => void;
}

const HomeSidebarComponent = ({
  accessToken,
  isLoggedIn,
  user,
  userClass,
  onSidebarToggle,
}: HomeSidebarProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeButton, setActiveButton] = useState("");
  const [workflows, setWorkflows] = useState<WorkflowV1[]>([]);
  const { fetchWorkflowsV1 } = useNodeApi(accessToken);
  const { redirectToLoginPage } = useRedirectFunctions();
  const logoutFn = useLogoutFunction();
  const org = userClass?.getOrgs()[0];
  const isFurtherAI =
    org?.orgMetadata["6296ccd4-3aa5-4475-bb9b-4a005612990b_FAI"];
  const isMSI = org?.orgMetadata["e18fc946-b145-4f5a-8c07-60abf5952af8_MSI"];

  const [isCollapsed, setIsCollapsed] = useState(() => {
    const saved = localStorage.getItem("sidebarCollapsed");
    return saved ? JSON.parse(saved) : false;
  });

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    onSidebarToggle?.(isCollapsed);
  }, [isCollapsed, onSidebarToggle]);

  useEffect(() => {
    const path = location.pathname.replace(/^\//, "");
    setActiveButton(path || "playground");
  }, [location]);

  const ShimmerAssistant = ({ isCollapsed }: { isCollapsed: boolean }) => (
    <div className="px-2 animate-pulse">
      <div
        className={`flex items-center w-full p-2 ${
          isCollapsed ? "justify-center" : ""
        }`}
      >
        <div
          className={`h-[18px] w-[18px] bg-gray-200 rounded ${
            isCollapsed ? "" : "mr-2"
          }`}
        />
        {!isCollapsed && <div className="h-4 bg-gray-200 rounded w-24" />}
      </div>
    </div>
  );

  async function fetchWorkflows() {
    try {
      setIsLoading(true);
      const workflows = await fetchWorkflowsV1();
      setWorkflows(workflows);
    } catch (error) {
      console.error("Failed to fetch workflows:", error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchWorkflows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getIconProps = (route: string) => ({
    size: 18,
    strokeWidth: 1.5,
    className: `flex-shrink-0 ${isCollapsed ? "" : "mr-2"}`,
    color: activeButton === route ? "#1d4ed8" : "#18181B",
  });

  const handleButtonClick = (route: string) => {
    if (route === "integrations" && !isFurtherAI && !isMSI) {
      toast.error("Contact your admin to enable this feature.");
    } else {
      setActiveButton(route);
      navigate(`/${route}`);
    }
  };

  const toggleSidebar = useCallback(() => {
    const newState = !isCollapsed;
    setIsCollapsed(newState);
    localStorage.setItem("sidebarCollapsed", JSON.stringify(newState));
    onSidebarToggle?.(newState);
  }, [isCollapsed, onSidebarToggle]);

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.ctrlKey && e.key.toLowerCase() === "b") {
        e.preventDefault();
        toggleSidebar();
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [toggleSidebar]);

  const WORKFLOW_ICONS = [
    Bot, // AI/Chat related
    FileInput, // Document/File Input
    FileOutput, // Document/File Output
    ShieldCheck, // Security/Verification
    ClipboardCheck, // Audit/Check
    MessagesSquare, // Communication/Chat
    ListChecks, // Guidelines/Rules
    SearchCheck, // Search/Verify
    Telescope, // Analysis/Research
    Plane, // Transport/Delivery
    ChartColumnStacked, // Analytics/Data
    Table2, // Data/Tables
    PhoneCall, // Communication
    Blocks, // Integration/System
    Brain, // Intelligence/Learning
    Database, // Data Storage
    FileJson, // API/Config
    FileText, // Document Processing
    Filter, // Data Filtering
    FolderSearch, // Document Search
    GitBranch, // Version Control
    History, // Timeline/History
    LineChart, // Analytics
    Mail, // Email Processing
    MessageCircle, // Messaging
    Network, // Network/Connection
    PenTool, // Design/Custom
    Puzzle, // Integration Pieces
    QrCode, // Scanning/Codes
    Receipt, // Invoice/Billing
    Replace, // Transform
    ScrollText, // Long Documents
    Settings2, // Configuration
    Sigma, // Calculations
    Sliders, // Settings/Controls
    ScanLine, // Scanning
    Webhook, // Integrations
    Workflow, // Process Flow
    Zap, // Automation
    Binary, // Code/Technical
  ] as const;

  const usedIconIndices = new Set<number>();

  const RESERVED_TOOL_ICONS = {
    "q-and-a": MessageCircle,
    extract: FileOutput,
    compare: ChartColumnStacked,
    excel: Table2,
    call: PhoneCall,
    integrations: Blocks,
    vault: TableProperties,
  } as const;

  Object.values(RESERVED_TOOL_ICONS).forEach((icon) => {
    const index = WORKFLOW_ICONS.indexOf(icon);
    if (index !== -1) {
      usedIconIndices.add(index);
    }
  });

  const getWorkflowIcon = (workflowName: string, isCollapsed: boolean) => {
    const iconProps = {
      size: 18,
      strokeWidth: 1.5,
      className: `flex-shrink-0 ${isCollapsed ? "mx-auto" : "mr-2"}`,
    };

    const hashString = (str: string) => {
      let hash = 0;
      for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash = hash & hash;
      }
      return Math.abs(hash);
    };

    let iconIndex =
      hashString(workflowName.toLowerCase()) % WORKFLOW_ICONS.length;
    let attempts = 0;

    while (usedIconIndices.has(iconIndex) && attempts < WORKFLOW_ICONS.length) {
      iconIndex = (iconIndex + 1) % WORKFLOW_ICONS.length;
      attempts++;
    }

    if (attempts === WORKFLOW_ICONS.length) {
      return <MessageCircle {...iconProps} />;
    }

    usedIconIndices.add(iconIndex);
    const IconComponent = WORKFLOW_ICONS[iconIndex];

    return <IconComponent {...iconProps} />;
  };

  const aiAssistants = workflows.map((wf) => ({
    link: `workflows/${wf.workflow_id}`,
    label: wf.name,
    icon: (isCollapsed: boolean) => getWorkflowIcon(wf.name, isCollapsed),
  }));

  const aiTools = [
    { link: "q-and-a", label: "Q&A", icon: RESERVED_TOOL_ICONS["q-and-a"] },
    { link: "extract", label: "Extract", icon: RESERVED_TOOL_ICONS["extract"] },
    { link: "compare", label: "Compare", icon: RESERVED_TOOL_ICONS["compare"] },
    { link: "excel", label: "Excel", icon: RESERVED_TOOL_ICONS["excel"] },
    { link: "call", label: "Call", icon: RESERVED_TOOL_ICONS["call"] },
    ...(isFurtherAI
      ? [{ link: "vault", label: "Vault", icon: RESERVED_TOOL_ICONS["vault"] }]
      : []),
    {
      link: "integrations",
      label: "Integrations",
      icon: RESERVED_TOOL_ICONS["integrations"],
    },
  ];

  return (
    <div
      className={`fixed top-0 left-0 flex flex-col h-screen bg-white border-r border-gray-200 overflow-y-auto overflow-x-hidden`}
      style={{
        width: isCollapsed ? SIDEBAR_WIDTH_ICON : SIDEBAR_WIDTH,
        transition: "width 300ms cubic-bezier(0.4, 0, 0.2, 1)",
      }}
    >
      <div
        className={`${
          !isCollapsed ? "p-4" : "py-4 px-2"
        } mt-4 flex justify-between items-center`}
      >
        {!isCollapsed && (
          <img
            src={furtherAiLogo}
            alt="Further AI"
            className="h-6 max-w-full cursor-pointer"
            width={160}
            height={34}
            onClick={() => navigate("/home")}
          />
        )}
        {!isCollapsed ? (
          <Button
            variant="ghost"
            size="icon"
            onClick={toggleSidebar}
            className="hover:bg-transparent focus-visible:ring-0 focus-visible:ring-offset-0 ml-2"
          >
            <PanelLeft className="h-4 w-4" />
          </Button>
        ) : (
          <TooltipProvider>
            <Tooltip delayDuration={100}>
              <TooltipTrigger asChild>
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={toggleSidebar}
                  className="hover:bg-transparent focus-visible:ring-0 focus-visible:ring-offset-0"
                >
                  <img
                    src={furtherAiLogoShort}
                    alt="Expand"
                    className="h-5 w-5 cursor-pointer"
                  />
                </Button>
              </TooltipTrigger>
              <TooltipContent
                side="right"
                align="center"
                sideOffset={10}
                className="bg-gray-50"
              >
                <p className="font-medium">Expand Sidebar</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}
      </div>
      <nav className="flex-1 overflow-y-auto overflow-x-hidden">
        <TooltipProvider>
          <div className="mb-4 mt-2">
            <ul className="space-y-1 px-2">
              <SidebarButton
                icon={<Sparkles {...getIconProps("home")} />}
                label="Assistant"
                isActive={activeButton === "home"}
                onClick={() => handleButtonClick("home")}
                isCollapsed={isCollapsed}
              />
            </ul>
          </div>
          {(isLoading || aiAssistants.length > 0) && (
            <div className="mb-6">
              {!isCollapsed ? (
                <h2 className="px-4 text-xs font-medium text-gray-500 uppercase tracking-wider mb-2 truncate whitespace-nowrap overflow-hidden">
                  Workflows
                </h2>
              ) : (
                <div className="px-2 py-3">
                  <Separator className="border-t border-gray-200" />
                </div>
              )}
              <ul className="space-y-1 px-2">
                {isLoading ? (
                  <>
                    <ShimmerAssistant isCollapsed={isCollapsed} />
                    <ShimmerAssistant isCollapsed={isCollapsed} />
                  </>
                ) : (
                  aiAssistants.map((assistant) => (
                    <SidebarButton
                      key={assistant.link}
                      icon={assistant.icon(isCollapsed)}
                      label={assistant.label}
                      isActive={activeButton === assistant.link}
                      onClick={() => handleButtonClick(assistant.link)}
                      isCollapsed={isCollapsed}
                    />
                  ))
                )}
              </ul>
            </div>
          )}

          <div className="mb-6">
            {!isCollapsed ? (
              <h2 className="px-4 text-xs font-medium text-gray-500 uppercase tracking-wider mb-2 truncate whitespace-nowrap overflow-hidden">
                Tools
              </h2>
            ) : (
              <div className="px-2 py-3">
                <Separator className="border-t border-gray-200" />
              </div>
            )}
            <ul className="space-y-1 px-2">
              {aiTools.map((tool) => (
                <SidebarButton
                  key={tool.link}
                  icon={<tool.icon {...getIconProps(tool.link)} />}
                  label={tool.label}
                  isActive={activeButton === tool.link}
                  onClick={() => handleButtonClick(tool.link)}
                  isCollapsed={isCollapsed}
                />
              ))}
            </ul>
          </div>
        </TooltipProvider>
      </nav>

      <Separator className="border-t border-gray-200" />
      {isLoggedIn && user && (
        <div className={`px-4 py-3 ${isCollapsed ? "items-center" : ""}`}>
          <div className="flex items-center justify-between">
            {isCollapsed ? (
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <div className="cursor-pointer">
                    <img
                      src={userDefaultImg}
                      alt={user.firstName}
                      className="rounded-full"
                      width={32}
                      height={32}
                    />
                  </div>
                </DropdownMenuTrigger>
                <DropdownMenuContent
                  className="w-52 p-1 bg-white"
                  align="center"
                  sideOffset={8}
                  forceMount
                >
                  <DropdownMenuItem
                    onClick={() => logoutFn(false)}
                    className="flex items-center px-2 py-2 text-sm text-red-600 hover:bg-red-50 hover:text-red-700 focus:bg-red-50 focus:text-red-700 rounded-md cursor-pointer"
                  >
                    <LogOut className="mr-2 h-4 w-4" />
                    <span>Log out</span>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            ) : (
              <div className="flex items-center space-x-2">
                <img
                  src={userDefaultImg}
                  alt={user.firstName}
                  className="rounded-full"
                  width={32}
                  height={32}
                />
                <div className="flex flex-col items-start overflow-hidden">
                  <span className="text-sm font-medium text-gray-900 truncate max-w-[120px]">
                    {user.firstName} {user.lastName}
                  </span>
                  <span className="text-xs text-gray-600 truncate max-w-[120px]">
                    {user.email}
                  </span>
                </div>
              </div>
            )}
            {!isCollapsed && (
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button
                    variant="ghost"
                    className="h-8 w-8 p-0 hover:bg-gray-100 rounded-full focus:ring-0 focus-visible:ring-0 focus:ring-offset-0"
                  >
                    <MoreVertical className="h-4 w-4 text-gray-400" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent
                  className="w-52 p-1 bg-white"
                  align="end"
                  alignOffset={-5}
                  sideOffset={8}
                  forceMount
                >
                  <DropdownMenuItem
                    onClick={() => logoutFn(false)}
                    className="flex items-center px-2 py-2 text-sm text-red-600 hover:bg-red-50 hover:text-red-700 focus:bg-red-50 focus:text-red-700 rounded-md cursor-pointer"
                  >
                    <LogOut className="mr-2 h-4 w-4" />
                    <span>Log out</span>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            )}
          </div>
        </div>
      )}
      {!isLoggedIn && (
        <div className="px-2 py-1 mb-4">
          <Button
            variant="ghost"
            className="w-full justify-start text-gray-700 hover:bg-gray-100 hover:text-gray-700"
            onClick={() => redirectToLoginPage()}
          >
            <LogIn {...getIconProps("login")} />
            <span className="text-sm font-medium text-gray-800">Login</span>
          </Button>
        </div>
      )}
    </div>
  );
};

export const HomeSidebarV1 = withAuthInfo(
  HomeSidebarComponent
) as unknown as (props: {
  onSidebarToggle?: (isCollapsed: boolean) => void;
}) => JSX.Element;
