import React, { useRef } from "react";
import { Grid } from "../../table/Grid";
import { PDFPreview } from "../pdf/PDFPreview";
import { EmailDetail } from "../../submissions/EmailDetail";
import {
  ResizablePanelGroup,
  ResizablePanel,
  ResizableHandle,
} from "../../ui/resizable";
import { TableCell, Citation } from "../../../types/table";
import { CitationWithState } from "../../../services/api";
import { X } from "lucide-react";

export interface GridPdfDialogProps {
  isOpen: boolean;
  onClose: () => void;

  tableData: {
    headers: string[];
    rows: { cells: TableCell[] }[];
    metadata?: {
      title?: string;
      [key: string]: unknown;
    };
  };
  filteredRows?: number[];
  onCellEdited: (
    cell: [number, number],
    newValue: string
  ) => Promise<void> | void;
  onCitationClick: (citations: Citation[] | null) => Promise<void> | void;
  tableLogId?: string;

  pdfUrl: string | null;
  emailHtml: string | null;
  emailHighlightRanges: Array<{ start: number; end: number }> | null;
  emailHeaders?: Record<string, string>;
  onClearPdf: () => void;
  citations: CitationWithState[] | null;
  isLoading?: boolean;
  fileType?: "pdf" | "excel" | "email";
  originalPdfUrl?: string | null;
  fetchTableLog: () => Promise<void>;
}

export const GridPdfDialog: React.FC<GridPdfDialogProps> = ({
  isOpen,
  onClose,
  tableData,
  filteredRows,
  onCellEdited,
  onCitationClick,
  pdfUrl,
  emailHtml,
  emailHighlightRanges,
  emailHeaders,
  onClearPdf,
  citations,
  isLoading = false,
  fileType = "pdf",
  originalPdfUrl = null,
  fetchTableLog,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  if (!isOpen) return null;

  return (
    <div
      className="px-2 fixed inset-0 z-50 bg-white"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="relative h-full w-full">
        <button
          aria-label="Close dialog"
          onClick={onClose}
          className="absolute top-2 right-2 z-50 p-2 text-gray-900 hover:text-gray-700"
        >
          <X className="h-5 w-5" />
        </button>
        <ResizablePanelGroup direction="horizontal">
          <ResizablePanel minSize={50}>
            <div className="h-full px-8 py-6 overflow-auto">
              {tableData.metadata?.title && (
                <h3 className="text-xl font-semibold mb-4 text-gray-900">
                  {tableData.metadata.title}
                </h3>
              )}
              <div className="border border-gray-200 rounded-md">
                <Grid
                  tableData={tableData}
                  filteredRows={filteredRows}
                  onCellEdited={onCellEdited}
                  onCitationClick={onCitationClick}
                  disableInlinePdfPreview={true}
                  editable={true}
                  filterQuery={""}
                  fetchTableLog={fetchTableLog}
                />
              </div>
            </div>
          </ResizablePanel>

          <ResizableHandle withHandle />

          <ResizablePanel minSize={40}>
            <div ref={containerRef} className="relative h-full overflow-auto">
              <div className="absolute top-4 left-4 z-20 flex items-center">
                <span className="bg-gray-200 text-gray-700 px-2 py-1 text-xs rounded">
                  {fileType.toUpperCase()}
                </span>
                {originalPdfUrl && (
                  <button
                    onClick={() =>
                      window.open(
                        originalPdfUrl,
                        "_blank",
                        "noopener,noreferrer"
                      )
                    }
                    className="ml-2 bg-blue-500 text-white px-2 py-1 text-xs rounded"
                  >
                    View Original
                  </button>
                )}
              </div>
              {isLoading ? (
                <div className="flex items-center justify-center h-full">
                  <span>Loading document...</span>
                </div>
              ) : pdfUrl ? (
                <PDFPreview
                  pdfUrl={pdfUrl}
                  onClear={onClearPdf}
                  citations={citations}
                />
              ) : emailHtml ? (
                <div className="mt-2">
                  <EmailDetail
                    emailBody={emailHtml}
                    highlightRanges={emailHighlightRanges || []}
                    emailHeaders={emailHeaders}
                    isEmbedded={true}
                  />
                </div>
              ) : (
                <div className="flex flex-col items-center justify-center h-full bg-gray-100">
                  <span className="text-gray-500 mb-2">No document loaded</span>
                  <span className="text-gray-400 text-sm">
                    Choose a table entry to view the document
                  </span>
                </div>
              )}
            </div>
          </ResizablePanel>
        </ResizablePanelGroup>
      </div>
    </div>
  );
};
