"use client";

import { useState, useEffect, useRef, useCallback } from "react";
import { Button } from "../ui/button";
import {
  Bot,
  FileInput,
  FileOutput,
  ShieldCheck,
  ClipboardCheck,
  MessagesSquare,
  ListChecks,
  SearchCheck,
  Telescope,
  Plane,
  ChartColumnStacked,
  Table2,
  PhoneCall,
  Blocks,
  Brain,
  Database,
  FileJson,
  FileText,
  Filter,
  FolderSearch,
  GitBranch,
  LineChart,
  Mail,
  MessageCircle,
} from "lucide-react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import {
  withRequiredAuthInfo,
  UserClass,
  WithLoggedInAuthInfoProps,
} from "@propelauth/react";
import { useNodeApi } from "../../hooks/useNodeApi";
import { WorkflowV1, UserDocument } from "../../services/api";
import toast from "react-hot-toast";
import { useSharePoint } from "../../lib/sharepoint";
import { PromptInput } from "../core/PromptInput";
import DocumentPreviewDialog from "../DocumentPreviewDialog";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "../ui/tabs";
import { ChatHistory } from "./ChatHistory";

interface LandingPageProps {
  accessToken: string | null;
  userClass: UserClass | null;
}

interface UploadedDocument {
  id: string;
  filename: string;
}

export const LandingPage = withRequiredAuthInfo(
  ({
    accessToken,
    userClass,
    user,
  }: LandingPageProps & WithLoggedInAuthInfoProps) => {
    const [inputValue, setInputValue] = useState("");
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const navigate = useNavigate();
    const [workflows, setWorkflows] = useState<WorkflowV1[]>([]);
    const [uploadedDocuments, setUploadedDocuments] = useState<
      UploadedDocument[]
    >([]);
    const [isUploading, setIsUploading] = useState(false);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [sharePointDocs, setSharePointDocs] = useState<UserDocument[]>([]);

    // Tab state
    const [activeTab, setActiveTab] = useState(() => {
      return localStorage.getItem("landingPageActiveTab") || "chat";
    });

    // Effect to save tab selection to localStorage whenever it changes
    useEffect(() => {
      localStorage.setItem("landingPageActiveTab", activeTab);
    }, [activeTab]);

    // Add state variables for document preview
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [previewUrl, setPreviewUrl] = useState("");
    const [previewFileType, setPreviewFileType] = useState<
      "pdf" | "image" | "excel"
    >("pdf");

    const {
      fetchWorkflowsV1,
      executeWorkflowV1Request,
      uploadDocument,
      deleteDocument,
      getPdfUrl,
      fetchWorkflowExecutionLogs,
      deleteLogByCategory,
    } = useNodeApi(accessToken);

    // Get organization from userClass
    const org = userClass?.getOrgs()[0];

    // Ask AI streaming workflow ID
    const ASK_AI_WORKFLOW_ID = process.env.REACT_APP_ASK_AI_WORKFLOW_ID;
    if (!ASK_AI_WORKFLOW_ID) {
      throw new Error("REACT_APP_ASK_AI_WORKFLOW_ID is not defined");
    }

    // Use the SharePoint hook
    const { selectAndUploadFile, isLoading: isSharePointLoading } =
      useSharePoint(
        uploadDocument,
        setSharePointDocs,
        process.env.REACT_APP_SHAREPOINT_CLIENT_ID || "",
        process.env.REACT_APP_TENANT_ID || "",
        org?.orgMetadata["SHAREPOINT_HOSTNAME"] || ""
      );

    // Convert SharePoint documents to UploadedDocument format when they change
    useEffect(() => {
      if (sharePointDocs.length > 0) {
        const convertedDocs = sharePointDocs.map((doc: UserDocument) => ({
          id: doc._id,
          filename: doc.filename,
        }));
        setUploadedDocuments((prev) => [...prev, ...convertedDocs]);
        // Clear the SharePoint docs after converting them
        setSharePointDocs([]);
      }
    }, [sharePointDocs]);

    useEffect(() => {
      async function fetchWorkflows() {
        try {
          const workflowsData = await fetchWorkflowsV1();
          setWorkflows(workflowsData);
        } catch (error) {
          console.error("Failed to fetch workflows:", error);
        }
      }

      fetchWorkflows();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // Function to adjust textarea height based on content
    const adjustTextareaHeight = useCallback(() => {
      if (textareaRef.current) {
        // Reset height to auto to get the correct scrollHeight
        textareaRef.current.style.height = "auto";

        // Calculate if content exceeds max height
        const scrollHeight = textareaRef.current.scrollHeight;
        const maxHeight = 200; // Max height in pixels

        if (scrollHeight > maxHeight) {
          // If content exceeds max height, set to max height and enable scrolling
          textareaRef.current.style.height = `${maxHeight}px`;
          textareaRef.current.style.overflowY = "scroll";
        } else {
          // Otherwise, set height to fit content and disable scrolling
          textareaRef.current.style.height = `${Math.max(scrollHeight, 48)}px`;
          textareaRef.current.style.overflowY = "hidden";
        }
      }
    }, []);

    // Adjust height when input value changes
    useEffect(() => {
      adjustTextareaHeight();
    }, [inputValue, adjustTextareaHeight]);

    // Focus the textarea when the component mounts
    useEffect(() => {
      if (textareaRef.current) {
        textareaRef.current.focus();
      }
    }, []);

    const validateFile = (file: File): boolean => {
      const fileType = file.type.toLowerCase();
      const fileName = file.name.toLowerCase();
      const isValidFile =
        fileType === "application/pdf" ||
        fileType ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        fileType ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        fileType === "message/rfc822" ||
        fileType === "application/vnd.ms-outlook" ||
        fileType === "application/zip" ||
        fileType === "application/x-zip-compressed" ||
        fileType === "image/jpeg" ||
        fileType === "image/png" ||
        fileName.endsWith(".eml") ||
        fileName.endsWith(".msg") ||
        fileName.endsWith(".zip");

      if (!isValidFile) {
        toast.error(`Unsupported file type: ${file.name}`);
        return false;
      }
      return true;
    };

    const processFiles = async (files: FileList | File[]) => {
      const validFiles = Array.from(files).filter(validateFile);

      if (validFiles.length === 0) return;

      setIsUploading(true);

      try {
        for (const file of validFiles) {
          try {
            const uploadRequest = {
              filename: file.name,
              blob_url: "",
              origin: "upload",
              category: "workflow",
              owner_uid: "",
              owner_oid: "",
              run_id: "",
            };

            const { user_document_id } = await uploadDocument(
              file,
              uploadRequest
            );

            // Add the document to the state
            setUploadedDocuments((prev) => [
              ...prev,
              {
                id: user_document_id,
                filename: file.name,
              },
            ]);
          } catch (error) {
            console.error(`Failed to upload file ${file.name}:`, error);
            toast.error(`Failed to upload file ${file.name}`);
          }
        }

        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      } catch (error) {
        console.error("Failed to process files:", error);
      } finally {
        setIsUploading(false);
      }
    };

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files && e.target.files.length > 0) {
        await processFiles(e.target.files);
      }
    };

    const handleWorkflowClick = async (workflowId: string) => {
      document.body.classList.add("page-transition");
      try {
        const execute = await executeWorkflowV1Request(
          workflowId,
          "", // Empty message
          [] // Empty document IDs array
        );

        setTimeout(() => {
          navigate(
            `/workflow-execution/${execute.workflow_execution_id}?fromLanding=true`
          );
        }, 300);
      } catch (error) {
        console.error("Failed to start workflow:", error);
        toast.error("Failed to start workflow");
        // Remove the transition class if there was an error
        document.body.classList.remove("page-transition");
      }
    };

    const handleSendMessage = async (message: string) => {
      if (!message.trim() && uploadedDocuments.length === 0) return;

      document.body.classList.add("page-transition");
      try {
        const execute = await executeWorkflowV1Request(
          ASK_AI_WORKFLOW_ID,
          message,
          uploadedDocuments.map((doc) => doc.id)
        );

        setInputValue("");
        setUploadedDocuments([]);

        setTimeout(() => {
          navigate(
            `/workflow-execution/${execute.workflow_execution_id}?fromLanding=true`
          );
        }, 300);
      } catch (error) {
        console.error("Failed to execute Ask AI workflow:", error);
        toast.error("Failed to send message");
        document.body.classList.remove("page-transition");
      }
    };

    const handleUploadFromSharePoint = async () => {
      try {
        setIsUploading(true);
        await selectAndUploadFile();
      } catch (error) {
        console.error("Failed to upload from SharePoint:", error);
        toast.error("Failed to upload from SharePoint");
      } finally {
        setIsUploading(false);
      }
    };

    const handleDeleteDocument = async (filename: string) => {
      const documentToRemove = uploadedDocuments.find(
        (doc) => doc.filename === filename
      );
      if (documentToRemove) {
        try {
          await deleteDocument(documentToRemove.id);
          setUploadedDocuments((prev) =>
            prev.filter((doc) => doc.id !== documentToRemove.id)
          );
        } catch (error) {
          console.error(`Failed to delete document ${filename}:`, error);
          toast.error(`Failed to delete document ${filename}`);
        }
      }
    };

    // Add this useEffect to clear documents when component unmounts
    useEffect(() => {
      return () => {
        setUploadedDocuments([]); // Clear documents on unmount
      };
    }, []);

    useEffect(() => {
      const handleDocumentPreviewClick = async (e: MouseEvent) => {
        const target = e.target as HTMLElement;

        if (
          target.closest("button") &&
          (target.closest("button")?.classList.contains("ml-2") ||
            target.closest("button")?.querySelector(".h-4.w-4"))
        ) {
          return;
        }

        const documentElement = target.closest(".document-preview-link");

        if (documentElement) {
          e.preventDefault();
          e.stopPropagation();

          // Find the document filename from the element
          const filenameElement = documentElement.querySelector(".truncate");
          if (filenameElement) {
            const filename = filenameElement.textContent?.trim();
            if (filename) {
              // Find the document in uploadedDocuments
              const document = uploadedDocuments.find(
                (doc) => doc.filename === filename
              );
              if (document) {
                // Get the document URL and preview it
                const fileExtension = filename.split(".").pop()?.toLowerCase();
                if (fileExtension === "pdf") {
                  setPreviewFileType("pdf");
                } else if (
                  ["jpg", "jpeg", "png", "gif"].includes(fileExtension || "")
                ) {
                  setPreviewFileType("image");
                } else if (
                  ["xlsx", "xls", "csv"].includes(fileExtension || "")
                ) {
                  setPreviewFileType("excel");
                } else {
                  return;
                }

                try {
                  // Get the document URL
                  const url = await getPdfUrl(document.id);
                  setPreviewUrl(url);
                  setIsPreviewOpen(true);
                } catch (error) {
                  console.error("Failed to get document URL:", error);
                  toast.error("Failed to preview document");
                }
              }
            }
          }
        }
      };

      document.addEventListener("click", handleDocumentPreviewClick);
      return () => {
        document.removeEventListener("click", handleDocumentPreviewClick);
      };
    }, [uploadedDocuments, getPdfUrl]);

    // Array of workflow icons to use
    const WORKFLOW_ICONS = [
      Bot, // AI/Chat related
      FileInput, // Document/File Input
      FileOutput, // Document/File Output
      ShieldCheck, // Security/Verification
      ClipboardCheck, // Audit/Check
      MessagesSquare, // Communication/Chat
      ListChecks, // Guidelines/Rules
      SearchCheck, // Search/Verify
      Telescope, // Analysis/Research
      Plane, // Transport/Delivery
      ChartColumnStacked, // Analytics/Data
      Table2, // Data/Tables
      PhoneCall, // Communication
      Blocks, // Integration/System
      Brain, // Intelligence/Learning
      Database, // Data Storage
      FileJson, // API/Config
      FileText, // Document Processing
      Filter, // Data Filtering
      FolderSearch, // Document Search
      GitBranch, // Version Control
      LineChart, // Analytics
      Mail, // Email Processing
      MessageCircle, // Messaging
    ];

    const getWorkflowIcon = (workflowName: string) => {
      const hashString = (str: string) => {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
          const char = str.charCodeAt(i);
          hash = (hash << 5) - hash + char;
          hash = hash & hash;
        }
        return Math.abs(hash);
      };

      const iconIndex =
        hashString(workflowName.toLowerCase()) % WORKFLOW_ICONS.length;
      const IconComponent = WORKFLOW_ICONS[iconIndex];

      return <IconComponent className="h-6 w-6 mb-2 text-blue-500" />;
    };

    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="flex flex-col min-h-screen bg-white text-gray-800 p-3 sm:p-6"
      >
        <div className="w-full max-w-3xl mx-auto flex flex-col">
          <div className="pt-16 sm:pt-24 pb-4 sm:pb-6">
            <motion.h1
              initial={{ y: -20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.2, duration: 0.5 }}
              className="text-2xl sm:text-3xl md:text-4xl font-semibold text-center mb-8 sm:mb-10"
            >
              Welcome Back, {user?.firstName || "there"}
            </motion.h1>

            <motion.div
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.4, duration: 0.5 }}
              className="relative"
            >
              <Tabs
                defaultValue="chat"
                value={activeTab}
                onValueChange={setActiveTab}
                className="focus:outline-none focus-visible:outline-none focus-visible:ring-0 focus:ring-0 ring-0 outline-none"
              >
                <div className="flex justify-start mb-4">
                  <TabsList className="inline-flex p-0 bg-transparent focus:outline-none focus-visible:outline-none focus-visible:ring-0 focus:ring-0 ring-0 outline-none">
                    <TabsTrigger
                      value="chat"
                      className="bg-transparent focus:outline-none focus-visible:outline-none focus-visible:ring-0 focus:ring-0 ring-0 outline-none rounded-none border-b-2 border-transparent px-4 py-2 text-gray-500 hover:text-gray-700 data-[state=active]:border-blue-500 data-[state=active]:text-gray-900 data-[state=active]:bg-transparent data-[state=active]:shadow-none transition-all"
                    >
                      <span className="text-center text-sm font-medium">
                        Ask
                      </span>
                    </TabsTrigger>
                    <TabsTrigger
                      value="history"
                      className="bg-transparent focus:outline-none focus-visible:outline-none focus-visible:ring-0 focus:ring-0 ring-0 outline-none rounded-none border-b-2 border-transparent px-4 py-2 text-gray-500 hover:text-gray-700 data-[state=active]:border-blue-500 data-[state=active]:text-gray-900 data-[state=active]:bg-transparent data-[state=active]:shadow-none transition-all"
                    >
                      <span className="text-center text-sm font-medium">
                        Past Queries
                      </span>
                    </TabsTrigger>
                  </TabsList>
                </div>

                <TabsContent
                  value="chat"
                  className="focus:outline-none focus-visible:outline-none focus-visible:ring-0 focus:ring-0 ring-0 outline-none"
                >
                  <PromptInput
                    onSend={handleSendMessage}
                    isStreaming={false}
                    uploadedDocuments={uploadedDocuments.map((doc) => ({
                      _id: doc.id,
                      filename: doc.filename,
                      blob_url: "",
                    }))}
                    onFileUpload={handleFileChange}
                    onDeleteDocument={handleDeleteDocument}
                    isUploading={isUploading}
                    promptCategory="landing-page"
                    onUploadFromSharePoint={handleUploadFromSharePoint}
                    isSharePointLoading={isSharePointLoading}
                    initiallyExpanded={true}
                    noRing={true}
                    alwaysExpanded={true}
                    placeholder="Ask anything..."
                  />
                </TabsContent>

                <TabsContent
                  value="history"
                  className="focus:outline-none focus-visible:outline-none focus-visible:ring-0 focus:ring-0 ring-0 outline-none"
                >
                  {ASK_AI_WORKFLOW_ID && (
                    <ChatHistory
                      workflowId={ASK_AI_WORKFLOW_ID}
                      fetchWorkflowExecutionLogs={fetchWorkflowExecutionLogs}
                      deleteLogByCategory={deleteLogByCategory}
                    />
                  )}
                </TabsContent>
              </Tabs>
            </motion.div>
          </div>

          {/* Only show workflow buttons when on chat tab */}
          {activeTab === "chat" && (
            <div className="flex-1 mt-2">
              {workflows.length > 0 && (
                <motion.div
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ delay: 0.6, duration: 0.5 }}
                  className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 sm:gap-5 max-w-3xl mx-auto"
                >
                  {workflows.slice(0, 6).map((workflow) => (
                    <Button
                      key={workflow.workflow_id}
                      variant="outline"
                      className="bg-white border border-gray-200 hover:bg-gray-50 text-gray-700 transition-all duration-300 hover:scale-105 rounded-xl text-base p-4 sm:p-5 h-auto w-full flex flex-col items-center shadow-sm"
                      onClick={() => {
                        if (workflow.disabled) {
                          toast.error(
                            "This workflow is disabled.\nPlease contact support to enable it"
                          );
                          return;
                        }
                        handleWorkflowClick(workflow.workflow_id);
                      }}
                    >
                      {getWorkflowIcon(workflow.name)}
                      <span className="text-center truncate w-full">
                        {workflow.name}
                      </span>
                    </Button>
                  ))}
                </motion.div>
              )}
            </div>
          )}
        </div>

        <DocumentPreviewDialog
          isOpen={isPreviewOpen}
          onClose={() => {
            setPreviewUrl("");
            setIsPreviewOpen(false);
          }}
          previewUrl={previewUrl}
          fileType={previewFileType}
        />
      </motion.div>
    );
  }
);
