import React, { useEffect, useRef, useState } from "react";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { MentionTextbox } from "../MentionTextbox";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
} from "../ui/dropdown-menu";
import {
  ArrowRight,
  CloudUpload,
  FileText,
  Pencil,
  Plus,
  Save,
  Search,
  Sparkle,
  Trash2,
  X,
} from "lucide-react";
import toast from "react-hot-toast";
import { ScrollArea, ScrollBar } from "../ui/scroll-area";
import { useNodeApi } from "../../hooks/useNodeApi";
import {
  WithLoggedInAuthInfoProps,
  withRequiredAuthInfo,
} from "@propelauth/react";
import sharepointimg from "../../assets/icons/sharepoint.svg";
import { cn } from "../../lib/utils";

const useDragAndDrop = (
  onFileUpload?: (event: React.ChangeEvent<HTMLInputElement>) => void,
  isDisabled = false
) => {
  const [isDragging, setIsDragging] = useState(false);
  const dragCounter = useRef(0);

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (isDisabled) return;

    dragCounter.current += 1;
    if (dragCounter.current === 1) {
      setIsDragging(true);
    }
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    dragCounter.current -= 1;
    if (dragCounter.current === 0) {
      setIsDragging(false);
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    // Keep the drag state active while dragging over
    if (dragCounter.current > 0 && !isDragging) {
      setIsDragging(true);
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    dragCounter.current = 0;
    setIsDragging(false);

    if (isDisabled || !onFileUpload) return;

    const files = Array.from(e.dataTransfer.files);
    if (files.length > 0) {
      const syntheticEvent = {
        target: {
          files: e.dataTransfer.files,
        },
      } as React.ChangeEvent<HTMLInputElement>;
      onFileUpload(syntheticEvent);
    }
  };

  // Reset drag counter when disabled state changes
  useEffect(() => {
    if (isDisabled) {
      dragCounter.current = 0;
      setIsDragging(false);
    }
  }, [isDisabled]);

  return {
    isDragging,
    handleDragEnter,
    handleDragLeave,
    handleDragOver,
    handleDrop,
  };
};

// Updated DragOverlay component to adapt to parent container
const DragOverlay = ({
  isDragging,
  isInactive = false,
}: {
  isDragging: boolean;
  isInactive?: boolean;
}) => {
  if (!isDragging) return null;

  return (
    <div
      className={cn(
        "absolute inset-0 flex items-center justify-center bg-blue-50/80 border-2 border-dashed border-blue-500 z-10",
        isInactive ? "rounded-full" : "rounded-xl"
      )}
    >
      <div className="text-center px-4">
        <CloudUpload className="h-6 w-6 text-blue-500 mx-auto mb-1" />
        <p
          className={cn(
            "text-blue-600 font-medium",
            isInactive ? "text-xs" : "text-sm"
          )}
        >
          Drop files here
        </p>
        {!isInactive && (
          <p className="text-xs text-blue-500 mt-0.5">
            Supported formats: PDF, DOCX, XLSX, JPG, PNG, ZIP
          </p>
        )}
      </div>
    </div>
  );
};

interface Template {
  _id: string;
  name: string;
  question: string;
  isFavorite: boolean;
  isDeleted?: boolean;
}

interface UserDocument {
  _id: string;
  filename: string;
  blob_url: string;
}

interface ChatInputProps extends WithLoggedInAuthInfoProps {
  accessToken: string;
  onSend: (message: string, documentIds?: string[]) => void;
  isStreaming?: boolean;
  uploadedDocuments?: UserDocument[];
  onFileUpload?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onDeleteDocument?: (fileName: string) => void;
  isUploading?: boolean;
  promptCategory?: string;
  onUploadFromSharePoint?: () => void;
  isSharePointLoading?: boolean;
  workflowExecutionId?: string;
  onMentionedDocumentsChange?: (documentIds: string[]) => void;
  invalidateCache?: boolean;
  isInCanvas?: boolean;
  initiallyExpanded?: boolean;
  noShadow?: boolean;
  noRing?: boolean;
  alwaysExpanded?: boolean;
  placeholder?: string;
}

const InactivePromptInput = ({
  onExpand,
  onSend,
  onAttachmentClick,
  disabled = true,
  onFileUpload,
}: {
  onExpand: () => void;
  onSend: () => void;
  onAttachmentClick: (e: React.MouseEvent) => void;
  disabled?: boolean;
  onFileUpload?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  const {
    isDragging,
    handleDragEnter,
    handleDragLeave,
    handleDragOver,
    handleDrop,
  } = useDragAndDrop(onFileUpload, disabled);

  return (
    <div
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      className={cn(
        "flex items-center gap-1 sm:gap-2 p-1.5 sm:p-2 rounded-full border border-gray-200",
        "shadow-lg hover:shadow-xl focus-within:shadow-xl focus-within:border-gray-300",
        "ring-8 ring-gray-100 relative",
        "transition-all duration-200 ease-out bg-white",
        isDragging && "border-blue-500 ring-blue-100 bg-blue-50"
      )}
    >
      <DragOverlay isDragging={isDragging} isInactive={true} />
      <button
        onClick={(e) => {
          onAttachmentClick(e);
          onExpand();
        }}
        className="p-1.5 sm:p-2 rounded-full hover:bg-gray-50 text-gray-500 hover:text-gray-600 transition-colors"
        disabled={disabled}
      >
        <Plus className="h-3.5 w-3.5 sm:h-4 sm:w-4" />
      </button>

      <div
        onClick={onExpand}
        className="flex-1 px-2 sm:px-3 py-1.5 text-xs sm:text-sm text-gray-500 cursor-pointer rounded-full transition-colors truncate"
      >
        Ask a question
      </div>

      <Button
        onClick={() => {
          onExpand();
          onSend();
        }}
        disabled={true}
        className="rounded-full h-8 w-8 sm:h-10 sm:w-10 flex-shrink-0 p-0 flex items-center justify-center text-white transition-colors"
      >
        <ArrowRight className="h-3.5 w-3.5 sm:h-4 sm:w-4" strokeWidth={2} />
      </Button>
    </div>
  );
};

export const PromptInput = withRequiredAuthInfo(
  ({
    accessToken,
    onSend,
    isStreaming = false,
    uploadedDocuments = [],
    onFileUpload,
    onDeleteDocument,
    isUploading = false,
    promptCategory = "prompt",
    onUploadFromSharePoint,
    isSharePointLoading = false,
    workflowExecutionId,
    onMentionedDocumentsChange,
    invalidateCache = false,
    isInCanvas = false,
    initiallyExpanded = false,
    noShadow = false,
    noRing = false,
    alwaysExpanded = false,
    placeholder = "Ask a follow-up question",
  }: ChatInputProps) => {
    const [searchQuery, setSearchQuery] = useState("");
    const [message, setMessage] = useState("");
    const [isSaveDropdownOpen, setIsSaveDropdownOpen] = useState(false);
    const [newTemplateName, setNewTemplateName] = useState("");
    const [templates, setTemplates] = useState<Template[]>([]);
    const { createPrompt, fetchUserPrompts, updatePrompt, deletePrompt } =
      useNodeApi(accessToken);
    const [isExpanded, setIsExpanded] = useState(initiallyExpanded);
    const [isEditing, setIsEditing] = useState(false);
    const [editingTemplate, setEditingTemplate] = useState<Template | null>(
      null
    );

    const dropZoneRef = useRef<HTMLDivElement>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const mentionInputRef = useRef<HTMLDivElement>(null);

    // Use the common drag and drop hook
    const {
      isDragging,
      handleDragEnter,
      handleDragLeave,
      handleDragOver,
      handleDrop,
    } = useDragAndDrop(onFileUpload, isStreaming || isUploading || isEditing);

    async function fetchTemplates() {
      try {
        const prompts = await fetchUserPrompts(promptCategory);
        setTemplates(
          prompts.map((prompt) => ({
            _id: prompt._id,
            name: prompt.name,
            question: prompt.content,
            isFavorite: false,
          }))
        );
      } catch (error) {
        console.error("Failed to fetch templates.", error);
      }
    }

    useEffect(() => {
      fetchTemplates();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Focus the input field when the component is mounted if alwaysExpanded is true
    useEffect(() => {
      if (alwaysExpanded && mentionInputRef.current) {
        const textarea = mentionInputRef.current.querySelector("textarea");
        textarea?.focus();
      }
    }, [alwaysExpanded]);

    const handleSend = () => {
      if (!message.trim()) return;
      // Pass document IDs to the parent component if there are uploaded documents
      const documentIds =
        uploadedDocuments.length > 0
          ? uploadedDocuments.map((doc) => doc._id)
          : undefined;
      onSend(message.trim(), documentIds);
      setMessage("");
    };

    const handleSaveTemplate = async () => {
      if (!newTemplateName.trim()) {
        toast.error("Template name cannot be empty.");
        return;
      }
      try {
        await createPrompt(
          /*name*/ newTemplateName,
          /*content*/ message,
          /*category*/ promptCategory
        );
        await fetchTemplates();
      } catch (error) {
        toast.error("Failed to save template.");
        return;
      }

      toast.success("Template saved successfully.");
      setNewTemplateName("");
      setIsSaveDropdownOpen(false);
    };

    const handleUploadFromComputer = () => {
      fileInputRef.current?.click();
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if ((e.metaKey || e.ctrlKey) && e.key === "Enter") {
        e.preventDefault();
        if (message.trim() && !isStreaming && !isUploading && !isEditing) {
          // Pass document IDs to the parent component if there are uploaded documents
          const documentIds =
            uploadedDocuments.length > 0
              ? uploadedDocuments.map((doc) => doc._id)
              : undefined;
          onSend(message, documentIds);
          setMessage("");
        }
      }
    };

    const handleInputFocus = () => {
      setIsExpanded(true);
    };

    const handleClickOutside = React.useCallback(
      (e: MouseEvent) => {
        if (alwaysExpanded) return;

        const target = e.target as HTMLElement;

        const isDropdownClick =
          target.closest('[role="menu"]') ||
          target.closest('[role="menuitem"]') ||
          target.closest('[role="dialog"]') ||
          target.closest(".dropdown-trigger");

        const isInsideComponent =
          target.closest(".prompt-input-container") || isDropdownClick;

        if (!isInsideComponent && !message.trim() && !isSaveDropdownOpen) {
          setIsExpanded(false);
        }
      },
      [message, isSaveDropdownOpen, alwaysExpanded]
    );

    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [handleClickOutside]);

    const handleExpand = () => {
      setIsExpanded(true);
      setTimeout(() => {
        if (mentionInputRef.current) {
          const textarea = mentionInputRef.current.querySelector("textarea");
          textarea?.focus();
        }
      }, 0);
    };

    const handleAttachmentClick = (e: React.MouseEvent) => {
      e.stopPropagation();
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
    };

    const handleEditPrompt = (template: Template) => {
      setMessage(template.question);
      setNewTemplateName(template.name);
      setEditingTemplate(template);
      setIsEditing(true);
    };

    const handleDeletePrompt = async (template: Template) => {
      try {
        await deletePrompt(template._id);
        setTemplates((prevTemplates) =>
          prevTemplates.map((t) =>
            t._id === template._id ? { ...t, isDeleted: true } : t
          )
        );
        toast.success("Prompt deleted successfully");
        await fetchTemplates(); // Refresh the list
      } catch (error) {
        toast.error("Failed to delete prompt");
      }
    };

    const clearEditMode = () => {
      setIsEditing(false);
      setEditingTemplate(null);
      setMessage("");
      setNewTemplateName("");
      setIsSaveDropdownOpen(false);
    };

    if (!alwaysExpanded && !isExpanded && !message.trim()) {
      return (
        <div className="mt-24">
          <div className="w-full mx-auto">
            <div className="transition-all duration-200 ease-out">
              <InactivePromptInput
                onExpand={handleExpand}
                onSend={handleSend}
                onAttachmentClick={handleAttachmentClick}
                disabled={isStreaming || isUploading}
                onFileUpload={onFileUpload}
              />
            </div>
          </div>
        </div>
      );
    }

    return (
      <>
        <div
          ref={dropZoneRef}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          className={cn(
            "prompt-input-container flex flex-col gap-3 p-3 border border-gray-200 rounded-xl w-full mx-auto transition-all duration-200 ease-out bg-white relative",
            !noShadow &&
              !noRing &&
              "shadow-lg hover:shadow-xl ring-8 ring-gray-100",
            !noShadow && noRing && "shadow-sm hover:shadow-md",
            isDragging && "border-blue-500 ring-blue-100 bg-blue-50"
          )}
        >
          <DragOverlay isDragging={isDragging} isInactive={false} />

          {isEditing ? (
            <div className="flex items-center justify-between text-sm">
              <div className="flex items-center gap-2 text-blue-700">
                <Pencil className="h-3.5 w-3.5" />
                <span className="font-medium">Editing:</span>
                <Input
                  value={newTemplateName}
                  onChange={(e) => setNewTemplateName(e.target.value)}
                  placeholder="Enter new name"
                  className="w-[200px] h-8 text-sm border-blue-200 focus:border-blue-400 focus:ring-blue-400"
                />
              </div>
              <div className="flex items-center gap-2">
                <Button
                  size="sm"
                  onClick={async () => {
                    if (!newTemplateName.trim()) {
                      toast.error("Template name cannot be empty.");
                      return;
                    }
                    try {
                      if (!editingTemplate?._id) {
                        throw new Error("No template ID found");
                      }
                      await updatePrompt(
                        editingTemplate._id,
                        newTemplateName,
                        message,
                        promptCategory
                      );
                      await fetchTemplates();
                      toast.success("Prompt updated successfully");
                      clearEditMode();
                    } catch (error) {
                      toast.error("Failed to update prompt");
                    }
                  }}
                >
                  <Save className="h-3.5 w-3.5 mr-2" />
                  Update
                </Button>
                <button
                  onClick={clearEditMode}
                  className="p-1 hover:bg-gray-100 rounded-full text-gray-500 hover:text-gray-700 transition-colors"
                >
                  <X className="h-3.5 w-3.5" />
                </button>
              </div>
            </div>
          ) : (
            <div className="flex flex-wrap gap-2">
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button
                    variant="outline"
                    size="sm"
                    className="h-8 sm:h-9 px-2 sm:px-3 text-xs sm:text-sm font-medium border-gray-200 dropdown-trigger"
                  >
                    <Sparkle className="h-3.5 w-3.5 sm:h-4 sm:w-4 mr-1.5 sm:mr-2" />
                    <span className="hidden sm:inline">Load prompts</span>
                    <span className="sm:hidden">Load</span>
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-64 bg-white" align="start">
                  <div className="p-2">
                    <div className="relative">
                      <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
                      <Input
                        type="text"
                        placeholder="Search for a prompt"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="pl-8 pr-2 py-1 w-full text-sm focus:ring-0"
                      />
                    </div>
                  </div>
                  {templates
                    .filter((template) => !template.isDeleted)
                    .filter((template) =>
                      template.name
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase())
                    )
                    .map((template) => (
                      <DropdownMenuItem
                        key={template._id}
                        className="flex items-center justify-between group px-3 py-2"
                      >
                        <div
                          className="flex-1 cursor-pointer"
                          onClick={() => {
                            setMessage(template.question);
                            setIsSaveDropdownOpen(false);
                          }}
                        >
                          {template.name}
                        </div>
                        <div className="flex items-center gap-1 opacity-0 group-hover:opacity-100 transition-opacity">
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              handleEditPrompt(template);
                            }}
                            className="p-1 hover:bg-gray-100 rounded-full text-gray-500 hover:text-gray-700"
                          >
                            <Pencil className="h-3.5 w-3.5" />
                          </button>
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              if (
                                window.confirm(
                                  "Are you sure you want to delete this prompt?"
                                )
                              ) {
                                handleDeletePrompt(template);
                              }
                            }}
                            className="p-1 hover:bg-gray-100 rounded-full text-gray-500 hover:text-red-600"
                          >
                            <Trash2 className="h-3.5 w-3.5" />
                          </button>
                        </div>
                      </DropdownMenuItem>
                    ))}
                </DropdownMenuContent>
              </DropdownMenu>

              <DropdownMenu
                open={isSaveDropdownOpen}
                onOpenChange={setIsSaveDropdownOpen}
              >
                <DropdownMenuTrigger asChild>
                  <Button
                    variant="outline"
                    size="sm"
                    className="h-8 sm:h-9 px-2 sm:px-3 text-xs sm:text-sm font-medium border-gray-200"
                  >
                    <Save className="h-3.5 w-3.5 sm:h-4 sm:w-4 mr-1.5 sm:mr-2" />
                    <span className="hidden sm:inline">Save prompt</span>
                    <span className="sm:hidden">Save</span>
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent
                  className="w-[400px] p-4 bg-white"
                  align="start"
                >
                  <div className="space-y-4">
                    <p className="text-sm text-gray-600">
                      This will save the current{" "}
                      <span className="font-medium text-gray-900">prompt</span>{" "}
                      state as a preset which you can access later or share with
                      others.
                    </p>

                    <div className="space-y-2">
                      <label className="text-sm">Name</label>
                      <div className="flex gap-2">
                        <Input
                          value={newTemplateName}
                          onChange={(e) => setNewTemplateName(e.target.value)}
                          placeholder="Enter template name"
                          className="flex-1"
                        />
                        <Button onClick={handleSaveTemplate}>Save</Button>
                      </div>
                    </div>
                  </div>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          )}

          <div className="relative flex-grow">
            <MentionTextbox
              ref={mentionInputRef}
              value={message}
              onChange={setMessage}
              documentNames={uploadedDocuments.map((doc) => doc.filename)}
              placeholder={isEditing ? "Edit your prompt here..." : placeholder}
              className={cn(
                "w-full focus:outline-none text-sm resize-none transition-all duration-200",
                isEditing,
                isExpanded
                  ? "max-h-[200px] px-2 py-1"
                  : "h-10 px-2 py-1 cursor-pointer hover:bg-gray-50"
              )}
              onKeyDown={handleKeyDown}
              onFocus={handleInputFocus}
              workflowExecutionId={workflowExecutionId}
              accessToken={accessToken}
              onMentionedDocumentsChange={onMentionedDocumentsChange}
              invalidateCache={invalidateCache}
              isInCanvas={isInCanvas}
            />
          </div>

          {(isExpanded || message.trim()) && (
            <div className="flex items-center gap-2">
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button
                    variant="outline"
                    size="sm"
                    className={cn(
                      "flex-shrink-0 relative h-8 sm:h-9 px-2 sm:px-3 text-xs sm:text-sm",
                      "disabled:opacity-100",
                      "disabled:cursor-not-allowed",
                      isEditing && "opacity-50 cursor-not-allowed"
                    )}
                    disabled={
                      isStreaming ||
                      isUploading ||
                      isSharePointLoading ||
                      isEditing
                    }
                  >
                    {isUploading ? (
                      <>
                        <span className="h-3.5 w-3.5 sm:h-4 sm:w-4 mr-1.5 sm:mr-2 animate-spin rounded-full border-2 border-gray-300 border-t-blue-600" />
                        <span className="text-gray-900 hidden sm:inline">
                          Uploading...
                        </span>
                        <span className="text-gray-900 sm:hidden">Upload</span>
                      </>
                    ) : (
                      <>
                        <Plus className="h-3.5 w-3.5 sm:h-4 sm:w-4 mr-1.5 sm:mr-2" />
                        <span className="hidden sm:inline">
                          Add attachments
                        </span>
                        <span className="sm:hidden">Add</span>
                      </>
                    )}
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent
                  align="start"
                  className="w-[240px] p-1 bg-white"
                >
                  <DropdownMenuItem
                    onSelect={onUploadFromSharePoint}
                    disabled={isSharePointLoading || isUploading || isEditing}
                  >
                    <div className="flex items-center">
                      <img
                        src={sharepointimg}
                        alt="SharePoint"
                        className="h-4 w-4 mr-2"
                      />
                      <span>
                        {isSharePointLoading
                          ? "Uploading..."
                          : "Upload from SharePoint"}
                      </span>
                    </div>
                  </DropdownMenuItem>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem
                    onClick={handleUploadFromComputer}
                    disabled={isEditing}
                  >
                    <div className="flex items-center">
                      <CloudUpload className="h-4 w-4 mr-2" />
                      <span>Upload from computer</span>
                    </div>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>

              <ScrollArea className="flex-1">
                <div className="flex items-center gap-2 w-full whitespace-nowrap">
                  {uploadedDocuments.map((document) => (
                    <div
                      key={document._id}
                      className="inline-flex items-center text-gray-900 border border-gray-300 rounded-md text-sm bg-white hover:bg-gray-50 transition-colors px-3 py-2 h-9 flex-shrink-0 document-preview-link"
                    >
                      <div className="flex items-center gap-3 flex-1 min-w-0">
                        <div className="w-7 h-7 rounded-lg bg-purple-50 flex-shrink-0 flex items-center justify-center">
                          <FileText className="w-4 w-4 text-purple-600" />
                        </div>
                        <span className="truncate max-w-[150px]">
                          {document.filename}
                        </span>
                      </div>
                      <button
                        onClick={() =>
                          onDeleteDocument &&
                          onDeleteDocument(document.filename)
                        }
                        className="ml-2 text-gray-500 hover:text-gray-900"
                      >
                        <X className="h-4 w-4" />
                      </button>
                    </div>
                  ))}
                </div>
                <ScrollBar orientation="horizontal" />
              </ScrollArea>

              <Button
                onClick={handleSend}
                disabled={
                  isStreaming || !message.trim() || isUploading || isEditing
                }
                className="rounded-full h-8 w-8 sm:h-10 sm:w-10 flex-shrink-0 p-0 flex items-center justify-center"
              >
                <ArrowRight
                  className="h-3.5 w-3.5 sm:h-4 sm:w-4"
                  strokeWidth={2}
                />
              </Button>
            </div>
          )}
        </div>

        <input
          type="file"
          ref={fileInputRef}
          onChange={onFileUpload}
          className="hidden"
          multiple
          accept=".jpg,.jpeg,.png,.pdf,.xlsx,.docx,.eml,.msg,.zip"
        />
      </>
    );
  }
);
