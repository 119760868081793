import { X } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { cn } from "../../lib/utils";

interface CanvasProps {
  leftContent: React.ReactNode;
  rightContent: React.ReactNode;
  onClose?: () => void;
}

export const Canvas = ({ leftContent, rightContent, onClose }: CanvasProps) => {
  const navigate = useNavigate();
  const [isClosing, setIsClosing] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    requestAnimationFrame(() => {
      setIsVisible(true);
    });
  }, []);

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      if (onClose) {
        onClose();
      } else {
        navigate(-1);
      }
    }, 200);
  };

  return (
    <div
      className={cn(
        "fixed inset-0 bg-white transition-all duration-200 ease-in-out",
        isVisible ? "scale-100 opacity-100" : "scale-95 opacity-0",
        isClosing ? "scale-95 opacity-0" : ""
      )}
    >
      <div className="flex h-full">
        <div className="border-r border-gray-200 overflow-auto w-[30%]">
          {leftContent}
        </div>

        <div className="relative w-[70%]">
          <div className="absolute top-8 right-4 z-50 flex items-center">
            <button
              onClick={handleClose}
              className="p-2 hover:bg-gray-100 rounded-full transition-colors duration-200"
              title="Close"
            >
              <X className="h-5 w-5 text-gray-500" />
            </button>
          </div>

          <div className="h-full overflow-auto">{rightContent}</div>
        </div>
      </div>
    </div>
  );
};
