import type React from "react";

import { useSearchParams, useNavigate } from "react-router-dom";
import { Button } from "../ui/button";
import {
  CheckCircle2,
  ChevronRight,
  FileText,
  Loader2,
  Send,
  Upload,
  X,
} from "lucide-react";
import { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Input } from "../ui/input";
import { UserClass } from "@propelauth/react";

interface Step {
  id: string;
  text: string;
  isCompleted: boolean;
  hasDetails?: boolean;
  details?: string;
}

interface ChatProps {
  accessToken?: string | null; // Kept for API compatibility
  userClass?: UserClass | null; // Kept for API compatibility
  executionId?: string;
}

export default function ChatPage(
  {
    accessToken: _accessToken,
    userClass: _userClass,
    executionId,
  }: ChatProps = {} as ChatProps
) {
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type") || "default";
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [showProcessing, setShowProcessing] = useState(false);
  const [typedText, setTypedText] = useState("");
  const [showUpload, setShowUpload] = useState(false);
  const [steps, setSteps] = useState<Step[]>([]);
  const [chatInput, setChatInput] = useState("");
  const chatInputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  // Map type to title and prompt
  const chatConfig = {
    comparison: {
      title: "Policy Comparison",
      prompt: "Upload the policies you want to compare.",
    },
    intake: {
      title: "Submission Intake",
      prompt: "Upload the submission you want to work with.",
    },
    audit: {
      title: "UW Audit",
      prompt: "Upload the UW audit you want to work with.",
    },
    lossrun: {
      title: "Loss Run",
      prompt: "Upload the loss run you want to work with.",
    },
    policychecking: {
      title: "Policy Checking",
      prompt: "Upload the policy you want to check.",
    },
    default: {
      title: "Policy Checking",
      prompt: "Upload the policy you want to check.",
    },
  };

  const { title, prompt } =
    chatConfig[type as keyof typeof chatConfig] || chatConfig.default;

  // Get file icon based on type
  const getFileIcon = (fileName: string) => {
    const extension = fileName.split(".").pop()?.toLowerCase() || "";

    switch (extension) {
      case "pdf":
        return (
          <div className="w-12 h-12 bg-red-100 rounded-lg border border-red-200 flex items-center justify-center">
            <svg
              className="w-7 h-7 text-red-500"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20 8V20C20 21.1046 19.1046 22 18 22H6C4.89543 22 4 21.1046 4 20V4C4 2.89543 4.89543 2 6 2H14L20 8Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14 2V8H20"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10.5 13.5H9V17H10.5"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M13 15.5C13 16.3284 12.3284 17 11.5 17C10.6716 17 10 16.3284 10 15.5C10 14.6716 10.6716 14 11.5 14C12.3284 14 13 14.6716 13 15.5Z"
                stroke="currentColor"
                strokeWidth="2"
              />
              <path
                d="M15 13.5H16.5V17H15"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        );
      case "doc":
      case "docx":
        return (
          <div className="w-12 h-12 bg-blue-100 rounded-lg border border-blue-200 flex items-center justify-center">
            <svg
              className="w-7 h-7 text-blue-600"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20 8V20C20 21.1046 19.1046 22 18 22H6C4.89543 22 4 21.1046 4 20V4C4 2.89543 4.89543 2 6 2H14L20 8Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14 2V8H20"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8 13H16"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8 17H16"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        );
      case "xls":
      case "xlsx":
        return (
          <div className="w-12 h-12 bg-green-100 rounded-lg border border-green-200 flex items-center justify-center">
            <svg
              className="w-7 h-7 text-green-600"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20 8V20C20 21.1046 19.1046 22 18 22H6C4.89543 22 4 21.1046 4 20V4C4 2.89543 4.89543 2 6 2H14L20 8Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14 2V8H20"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8 13H16"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8 17H16"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        );
      case "jpg":
      case "jpeg":
      case "png":
      case "gif":
        return (
          <div className="w-12 h-12 bg-purple-100 rounded-lg border border-purple-200 flex items-center justify-center">
            <svg
              className="w-7 h-7 text-purple-600"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20 8V20C20 21.1046 19.1046 22 18 22H6C4.89543 22 4 21.1046 4 20V4C4 2.89543 4.89543 2 6 2H14L20 8Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14 2V8H20"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <circle
                cx="12"
                cy="13"
                r="3"
                stroke="currentColor"
                strokeWidth="2"
              />
              <path
                d="M18 18L15 15"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        );
      default:
        return (
          <div className="w-12 h-12 bg-gray-100 rounded-lg border border-gray-200 flex items-center justify-center">
            <FileText className="w-7 h-7 text-gray-500" />
          </div>
        );
    }
  };

  // Format file size
  const formatFileSize = (bytes: number) => {
    if (bytes < 1024) return bytes + " bytes";
    else if (bytes < 1048576) return (bytes / 1024).toFixed(1) + " KB";
    else return (bytes / 1048576).toFixed(2) + " MB";
  };

  // Simulate processing steps
  const processFile = async () => {
    // First show just the file upload confirmation
    setIsProcessing(true);

    // After a delay, show the processing steps
    setTimeout(() => {
      setShowProcessing(true);

      const processingSteps = [
        { id: "1", text: "Analyzing document format", isCompleted: false },
        {
          id: "2",
          text: "Extracting content",
          isCompleted: false,
          hasDetails: true,
        },
        { id: "3", text: "Building knowledge base", isCompleted: false },
        {
          id: "4",
          text: "Processing document structure",
          isCompleted: false,
          hasDetails: true,
        },
        { id: "5", text: "Preparing final output", isCompleted: false },
      ];

      setSteps(processingSteps);

      // Simulate step completion
      const completeSteps = async () => {
        for (let i = 0; i < processingSteps.length; i++) {
          await new Promise((resolve) => setTimeout(resolve, 1500));
          setSteps((prev) =>
            prev.map((step, index) =>
              index === i ? { ...step, isCompleted: true } : step
            )
          );
        }
        setIsProcessing(false);

        // Focus the chat input after processing is complete
        setTimeout(() => {
          if (chatInputRef.current) {
            chatInputRef.current.focus();
          }
        }, 500);
      };

      completeSteps();
    }, 1500);
  };

  // Typing animation effect
  useEffect(() => {
    let currentText = "";
    let currentIndex = 0;

    const typingInterval = setInterval(() => {
      if (currentIndex < prompt.length) {
        currentText += prompt[currentIndex];
        setTypedText(currentText);
        currentIndex++;
      } else {
        clearInterval(typingInterval);
        setTimeout(() => {
          setShowUpload(true);
        }, 400);
      }
    }, 30);

    return () => clearInterval(typingInterval);
  }, [prompt]);

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = async (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      setSelectedFile(e.dataTransfer.files[0]);
      await processFile();
    }
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedFile(e.target.files[0]);
      await processFile();
    }
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
    setSteps([]);
    setIsProcessing(false);
    setShowProcessing(false);
    setShowUpload(true);
  };

  const handleSendMessage = () => {
    if (chatInput.trim()) {
      setChatInput("");
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  // If we have an executionId, redirect to the workflow page
  useEffect(() => {
    if (executionId) {
      navigate(`/workflow/${executionId}`);
    }
  }, [executionId, navigate]);

  // Render the Chat UI
  return (
    <div className="min-h-screen bg-white">
      <div className="max-w-4xl mx-auto p-6">
        {/* Title */}
        <div className="mb-6">
          <div className="flex items-center justify-between">
            <h1 className="text-3xl font-bold">{title}</h1>
          </div>
        </div>

        {/* Chat message */}
        <div className="flex mb-8">
          <div className="w-8 h-8 rounded-full bg-gray-800 text-white flex items-center justify-center mr-4 flex-shrink-0">
            F
          </div>
          <div>
            <p className="text-gray-800 text-lg">
              {typedText}
              {typedText.length < prompt.length && (
                <span className="inline-block w-1 h-4 bg-gray-800 ml-1 animate-pulse"></span>
              )}
            </p>
          </div>
        </div>

        {/* Upload area */}
        <AnimatePresence mode="wait">
          {!selectedFile && showUpload ? (
            <motion.div
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: -20, opacity: 0 }}
              className={`border-2 border-dashed rounded-lg p-8 ${
                isDragging ? "border-blue-500 bg-blue-50" : "border-gray-300"
              }`}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
            >
              <div className="flex flex-col items-center">
                <div className="mb-4">
                  <Upload className="w-8 h-8 text-gray-500 mx-auto mb-2" />
                  <p className="text-xl font-medium text-center">
                    Drag and drop document
                  </p>
                </div>

                <p className="text-gray-500 text-center mb-6">
                  Supported file types: CSV, Email, Excel,
                  <br />
                  PDF, RTF, Text, Word, Zip
                </p>

                <div className="cursor-pointer">
                  <input
                    id="file-upload"
                    type="file"
                    className="hidden"
                    onChange={handleFileChange}
                    accept=".csv,.eml,.xls,.xlsx,.pdf,.rtf,.txt,.doc,.docx,.zip"
                  />
                  <Button
                    variant="default"
                    className="bg-black text-white hover:bg-gray-800"
                    type="button"
                    onClick={() =>
                      document.getElementById("file-upload")?.click()
                    }
                  >
                    Choose file
                  </Button>
                </div>
              </div>
            </motion.div>
          ) : (
            selectedFile && (
              <div className="space-y-6">
                {/* File Preview - Always shown after upload */}
                <motion.div
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  className="bg-white rounded-lg border border-gray-200 overflow-hidden shadow-sm p-5"
                >
                  <div className="flex items-start justify-between">
                    <div className="flex items-start">
                      {getFileIcon(selectedFile.name)}
                      <div className="ml-4">
                        <div className="flex items-center">
                          <h3 className="font-medium text-gray-900 text-base">
                            {selectedFile.name}
                          </h3>
                          {isProcessing && (
                            <span className="ml-3 px-2 py-0.5 bg-blue-100 text-blue-800 text-xs rounded-full">
                              Processing
                            </span>
                          )}
                          {!isProcessing &&
                            steps.length > 0 &&
                            steps.every((step) => step.isCompleted) && (
                              <span className="ml-3 px-2 py-0.5 bg-green-100 text-green-800 text-xs rounded-full">
                                Processed
                              </span>
                            )}
                        </div>
                        <div className="mt-1 space-y-1">
                          <p className="text-sm text-gray-500">
                            {formatFileSize(selectedFile.size)}
                          </p>
                          <p className="text-sm text-gray-500">
                            Uploaded {new Date().toLocaleTimeString()}
                          </p>
                        </div>
                      </div>
                    </div>
                    <Button
                      variant="ghost"
                      size="icon"
                      className="text-gray-400 hover:text-gray-600 hover:bg-gray-100 rounded-full"
                      onClick={handleRemoveFile}
                    >
                      <X className="h-5 w-5" />
                    </Button>
                  </div>

                  {/* Initial loading indicator */}
                  {isProcessing && !showProcessing && (
                    <div className="mt-4 flex items-center justify-center">
                      <Loader2 className="w-5 h-5 text-blue-500 animate-spin mr-2" />
                      <span className="text-sm text-gray-600">
                        Preparing document for processing...
                      </span>
                    </div>
                  )}
                </motion.div>

                {/* Processing Steps - Shown after a delay */}
                {showProcessing && (
                  <motion.div
                    initial={{ y: 20, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    className="bg-white rounded-lg border border-gray-200 overflow-hidden shadow-sm"
                  >
                    <div className="px-5 py-4 border-b border-gray-100">
                      <h3 className="font-medium text-gray-900">
                        Processing Steps
                      </h3>
                    </div>

                    <div className="p-5">
                      <div className="space-y-4">
                        {steps.map((step, index) => (
                          <motion.div
                            key={step.id}
                            initial={{ opacity: 0, y: 10 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: index * 0.1 }}
                            className="flex items-start"
                          >
                            <div className="relative">
                              <div
                                className={`w-6 h-6 rounded-full flex items-center justify-center ${
                                  step.isCompleted
                                    ? "bg-purple-100 text-purple-600"
                                    : isProcessing &&
                                      steps[index - 1]?.isCompleted
                                    ? "bg-blue-100 text-blue-600"
                                    : "bg-gray-100 text-gray-400"
                                }`}
                              >
                                {step.isCompleted ? (
                                  <CheckCircle2 className="w-4 h-4" />
                                ) : isProcessing &&
                                  steps[index - 1]?.isCompleted ? (
                                  <Loader2 className="w-4 h-4 animate-spin" />
                                ) : (
                                  <div className="w-2 h-2 rounded-full bg-current" />
                                )}
                              </div>
                              {index < steps.length - 1 && (
                                <div className="absolute top-6 left-1/2 bottom-0 w-px bg-gray-200 -translate-x-1/2" />
                              )}
                            </div>
                            <div className="ml-3 flex-1">
                              <div className="flex items-center justify-between">
                                <p
                                  className={`text-sm ${
                                    step.isCompleted
                                      ? "text-gray-900"
                                      : isProcessing &&
                                        steps[index - 1]?.isCompleted
                                      ? "text-gray-900"
                                      : "text-gray-500"
                                  }`}
                                >
                                  {step.text}
                                </p>
                                {step.hasDetails && (
                                  <Button
                                    variant="ghost"
                                    size="sm"
                                    className="text-gray-400 hover:text-gray-600"
                                  >
                                    <ChevronRight className="h-4 w-4" />
                                  </Button>
                                )}
                              </div>
                            </div>
                          </motion.div>
                        ))}
                      </div>

                      {/* View Output button appears when all steps are complete */}
                      {steps.every((step) => step.isCompleted) && (
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          transition={{ delay: 0.5 }}
                          className="mt-6"
                        >
                          <Button
                            className="bg-black text-white hover:bg-gray-800 w-full"
                            onClick={() => {
                              /* Handle view output */
                            }}
                          >
                            View Output
                          </Button>
                        </motion.div>
                      )}
                    </div>
                  </motion.div>
                )}

                {/* Chat input - Appears after processing is complete */}
                {!isProcessing &&
                  steps.length > 0 &&
                  steps.every((step) => step.isCompleted) && (
                    <motion.div
                      initial={{ y: 20, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      transition={{ delay: 0.3 }}
                      className="sticky bottom-0 left-0 right-0  p-4 z-10"
                    >
                      <div className="max-w-4xl mx-auto">
                        <div className="flex items-end gap-2">
                          <div className="flex-1 relative">
                            <Input
                              ref={chatInputRef}
                              placeholder="Ask Anything..."
                              className="border border-gray-300 rounded-full py-6 px-4 pr-12 focus-visible:ring-1 focus-visible:ring-gray-400 focus-visible:ring-offset-0"
                              value={chatInput}
                              onChange={(e) => setChatInput(e.target.value)}
                              onKeyDown={handleKeyDown}
                            />
                            <Button
                              variant="ghost"
                              size="icon"
                              className="absolute right-2 bottom-1/2 transform translate-y-1/2 text-gray-400 hover:text-gray-600 hover:bg-transparent"
                              onClick={handleSendMessage}
                            >
                              <Send className="h-5 w-5" />
                            </Button>
                          </div>
                        </div>
                        <p className="text-xs text-gray-500 mt-2 ml-2">
                          Ask questions about the document or request specific
                          information
                        </p>
                      </div>
                    </motion.div>
                  )}
              </div>
            )
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}
