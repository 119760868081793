import React, { useEffect, useRef } from "react";
import { MentionsInput, Mention, SuggestionDataItem } from "react-mentions";
import { useNodeApi } from "../hooks/useNodeApi";
import { FileText, Loader2 } from "lucide-react";

interface MentionTextboxProps {
  value: string;
  onChange: (value: string) => void;
  documentNames?: string[];
  placeholder?: string;
  className?: string;
  onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  onFocus?: () => void;
  workflowExecutionId?: string;
  accessToken: string | null;
  onMentionedDocumentsChange?: (documentIds: string[]) => void;
  invalidateCache?: boolean;
  isInCanvas?: boolean;
}

export const MentionTextbox = React.forwardRef<
  HTMLDivElement,
  MentionTextboxProps
>(
  (
    {
      value,
      onChange,
      placeholder = "Type your message...",
      className = "",
      onKeyDown,
      onFocus,
      workflowExecutionId,
      accessToken,
      onMentionedDocumentsChange,
      invalidateCache = false,
      isInCanvas = false,
    },
    ref
  ) => {
    const textareaRef = useRef<HTMLTextAreaElement | null>(null);

    const setRefs = (element: HTMLDivElement | null) => {
      if (typeof ref === "function") {
        ref(element);
      } else if (ref) {
        ref.current = element;
      }

      if (element) {
        const textarea = element.querySelector("textarea");
        textareaRef.current = textarea;
      }
    };

    useEffect(() => {
      const textarea = textareaRef.current;
      if (textarea) {
        const adjustHeight = () => {
          textarea.style.height = "auto";
          const newHeight = Math.min(textarea.scrollHeight, 200);
          textarea.style.height = `${newHeight}px`;
        };

        adjustHeight();

        const resizeObserver = new ResizeObserver(adjustHeight);
        resizeObserver.observe(textarea);

        return () => {
          resizeObserver.disconnect();
        };
      }
    }, [value]);

    // const [cachedDocuments, setCachedDocuments] = useState<SuggestionDataItem[]>([]);
    // const [hasFetched, setHasFetched] = useState(false);
    const { fetchWorkflowExecutionDocuments } = useNodeApi(accessToken);

    const fetchDocuments = async (
      query: string,
      callback: (data: SuggestionDataItem[]) => void
    ) => {
      if (!workflowExecutionId) return;

      // Always show loading state
      callback([{ id: "loading", display: "loading" }]);

      try {
        const response = await fetchWorkflowExecutionDocuments(
          workflowExecutionId
        );
        if (response?.documents) {
          const documents = response.documents.map((doc) => ({
            id: doc.user_document_id,
            display: doc.filename,
          }));

          // Store documents for potential future use but don't rely on cache
          // setCachedDocuments(documents);
          // setHasFetched(true);

          const filteredDocs = documents.filter((doc) =>
            doc.display.toLowerCase().includes(query.toLowerCase())
          );
          callback(filteredDocs);
        }
      } catch (error) {
        console.error("Failed to fetch documents:", error);
        callback([]);
      }
    };

    useEffect(() => {
      if (invalidateCache) {
        // setHasFetched(false);
        // setCachedDocuments([]);
      }
    }, [invalidateCache]);

    const handleChange = (
      event: { target: { value: string } },
      newValue: string,
      newPlainTextValue: string,
      mentions: SuggestionDataItem[]
    ) => {
      onChange(newValue);
      if (onMentionedDocumentsChange) {
        onMentionedDocumentsChange(
          mentions.map((mention) => mention.id.toString())
        );
      }
    };

    return (
      <div className="relative w-full" ref={setRefs}>
        <MentionsInput
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          className={className}
          onFocus={onFocus}
          onKeyDown={(
            e: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => {
            if (onKeyDown && e.target instanceof HTMLTextAreaElement) {
              onKeyDown(e as React.KeyboardEvent<HTMLTextAreaElement>);
            }
          }}
          style={{
            control: {
              backgroundColor: "#fff",
              fontSize: 14,
              fontWeight: "normal",
              position: "relative",
              width: "100%",
            },
            input: {
              margin: 0,
              padding: "0",
              overflow: "auto",
              minHeight: "44px",
              maxHeight: "200px",
              outline: "none",
              border: "none",
              backgroundColor: "#fff",
              fontSize: "14px",
              lineHeight: "1.5",
              width: "100%",
              display: "block",
              position: "relative",
              boxSizing: "border-box",
              resize: "none",
              whiteSpace: "pre-wrap",
              wordWrap: "break-word",
              overflowWrap: "break-word",
              height: "auto",
              transition: "height 0.1s ease-out",
            },
            suggestions: {
              list: {
                backgroundColor: "white",
                border: "1px solid #e2e8f0",
                fontSize: "14px",
                borderRadius: "6px",
                boxShadow:
                  "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
                overflow: "auto",
                position: "absolute",
                bottom: "100%",
                left: 0,
                right: 0,
                zIndex: 1000,
                marginBottom: "4px",
                minWidth: "320px",
                maxWidth: "400px",
                maxHeight: "200px",
              },
              item: {
                padding: "8px 12px",
                borderBottom: "1px solid #e2e8f0",
                "&focused": {
                  backgroundColor: "#f7fafc",
                  color: "#1a202c",
                },
              },
            },
            highlighter: {
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              overflow: "hidden",
              border: "none",
              padding: "0",
              boxSizing: "border-box",
              color: "transparent",
              whiteSpace: "pre-wrap",
              wordWrap: "break-word",
              overflowWrap: "break-word",
            },
          }}
          suggestionsPortalHost={isInCanvas ? document.body : undefined}
          forceSuggestionsAboveCursor
          singleLine={false}
        >
          <Mention
            trigger="@"
            data={fetchDocuments}
            displayTransform={(id, display) => `@${display}`}
            markup="@__display__<document_start>__id__<document_end>"
            appendSpaceOnAdd
            renderSuggestion={(
              suggestion,
              search,
              highlightedDisplay,
              index,
              focused
            ) => {
              if (suggestion.id === "loading") {
                return (
                  <div className="flex items-center gap-2 p-2 text-sm text-gray-600">
                    <Loader2 className="w-4 h-4 animate-spin text-purple-600 mr-2" />
                    <span>Fetching documents...</span>
                  </div>
                );
              }

              return (
                <div
                  className={`flex items-center gap-3 ${
                    focused ? "bg-purple-50" : ""
                  }`}
                >
                  <div className="w-7 h-7 rounded-lg bg-purple-50 flex-shrink-0 flex items-center justify-center">
                    <FileText className="w-4 h-4 text-purple-600" />
                  </div>
                  <span className="truncate max-w-[320px]">
                    {suggestion.display}
                  </span>
                </div>
              );
            }}
            style={{
              backgroundColor: "#edf2f7",
              borderRadius: "4px",
              padding: "2px 4px",
              color: "#4a5568",
              fontWeight: 500,
            }}
          />
        </MentionsInput>
      </div>
    );
  }
);

MentionTextbox.displayName = "MentionTextbox";

export default MentionTextbox;
