import { IconType } from "../types/workflowTypes";
import {
  Workflow,
  Route,
  Clock,
  LayoutGrid,
  Code,
  Wrench,
  Filter,
} from "lucide-react";

import outlookimg from "../assets/icons/outlook.svg";
import autoimg from "../assets/icons/auto.svg";
import homeimg from "../assets/icons/home.svg";
import pdfimg from "../assets/icons/pdf.svg";
import portalimg from "../assets/icons/portal.svg";
import typeimg from "../assets/icons/type.svg";
import triggerimg from "../assets/icons/trigger.svg";
import extractimg from "../assets/icons/extract.svg";
import verifyimg from "../assets/icons/verify.svg";
import excelimg from "../assets/icons/excel.svg";

import salesforceIcon from "../assets/icons/integrations/salesforce.svg";
import microsoftIcon from "../assets/icons/integrations/microsoft.svg";
import guidewireIcon from "../assets/icons/integrations/guidewire.svg";
import majescoIcon from "../assets/icons/integrations/majesco.svg";
import duckCreekIcon from "../assets/icons/integrations/duck-creek.svg";
import appliedEpicIcon from "../assets/icons/integrations/applied-epic.svg";
import ams360Icon from "../assets/icons/integrations/ams360.svg";
import ezlynxIcon from "../assets/icons/integrations/ezlynx.svg";
import travelersIcon from "../assets/icons/integrations/travelers-logo.svg";
import progressiveIcon from "../assets/icons/integrations/progressive.svg";
import delosIcon from "../assets/icons/integrations/delos.svg";
import bambooIcon from "../assets/icons/integrations/bamboo.svg";
import sharepointIcon from "../assets/icons/integrations/sharepoint.svg";

export function getWorkflowNodeIcon(type: string): IconType {
  switch (type) {
    case "trigger":
      return triggerimg;
    case "extract":
      return extractimg;
    case "verify":
      return verifyimg;
    case "outlook":
      return outlookimg;
    case "auto":
      return autoimg;
    case "home":
      return homeimg;
    case "docs":
      return pdfimg;
    case "portal":
      return portalimg;
    case "type":
      return typeimg;
    case "filter":
      return Filter;
    case "formatter":
      return LayoutGrid;
    case "paths":
      return Route;
    case "delay":
      return Clock;
    case "code":
      return Code;
    case "email":
      return Workflow;
    case "fill":
      return excelimg;
    default:
      return Wrench; // Default icon
  }
}

export function getIntegrationIcon(name: string): IconType {
  switch (name) {
    case "Salesforce":
      return salesforceIcon;
    case "Microsoft CRM":
      return microsoftIcon;
    case "Guidewire":
      return guidewireIcon;
    case "Majesco":
      return majescoIcon;
    case "Duck Creek":
      return duckCreekIcon;
    case "Applied Epic":
      return appliedEpicIcon;
    case "AMS360":
      return ams360Icon;
    case "EZLynx":
      return ezlynxIcon;
    case "Travelers":
      return travelersIcon;
    case "Progressive":
      return progressiveIcon;
    case "Delos":
      return delosIcon;
    case "Bamboo":
      return bambooIcon;
    case "SharePoint":
      return sharepointIcon;
    default:
      return Wrench; // Default icon
  }
}
