import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "../ui/dialog"
import { Button } from "../ui/button"
import { PlusCircle, ArrowRight, FileText, X, CloudUpload } from "lucide-react"
import { useRef, useState } from "react"
import { ScrollArea } from "../ui/scroll-area"
import { cn } from "../../lib/utils"
import { UserDocument, UserDocumentUploadRequest } from "../../services/api"
import toast from "react-hot-toast"
import sharepointimg from '../../assets/icons/sharepoint.svg';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
} from "../ui/dropdown-menu"
import { useSharePoint } from '../../lib/sharepoint';


interface WorkflowDialogProps {
  isOpen: boolean
  onClose: () => void
  onSend: (message: string, documentIds: string[]) => Promise<void>
  uploadDocument: (file: File, request: UserDocumentUploadRequest) => Promise<{ user_document_id: string; sas_url: string }>
  deleteDocument: (documentId: string) => Promise<void>
  sharePointHostname: string
}

export function WorkflowDialog({
  isOpen,
  onClose,
  onSend,
  uploadDocument,
  deleteDocument,
  sharePointHostname
}: WorkflowDialogProps) {
  const [uploadedDocuments, setUploadedDocuments] = useState<UserDocument[]>([])
  const [isSending, setIsSending] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [isDraggingOver, setIsDraggingOver] = useState(false)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const dragCounter = useRef(0)
  const { selectAndUploadFile, isLoading: isSharePointLoading } = useSharePoint(
    uploadDocument,
    setUploadedDocuments,
    process.env.REACT_APP_SHAREPOINT_CLIENT_ID || '',
    process.env.REACT_APP_TENANT_ID || '',
    sharePointHostname || 'https://example.com'
  );

  const processFiles = async (files: FileList | File[]) => {
    if (files.length > 0) {
      setIsUploading(true)
      try {
        const validFiles = Array.from(files).filter(file => {
          const fileType = file.type.toLowerCase();
          const fileName = file.name.toLowerCase();
          return fileType === 'application/pdf' ||
            fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
            fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
            fileType === 'message/rfc822' ||
            fileType === 'application/vnd.ms-outlook' ||
            fileType === 'application/zip' ||
            fileType === 'application/x-zip-compressed' ||
            fileType === 'image/jpeg' ||
            fileType === 'image/png' ||
            fileName.endsWith('.eml') ||
            fileName.endsWith('.msg') ||
            fileName.endsWith('.zip');
        });

        for (const file of validFiles) {
          try {
            const uploadRequest: UserDocumentUploadRequest = {
              filename: file.name,
              blob_url: "",
              origin: "upload",
              category: "workflow",
              owner_uid: "",
              owner_oid: "",
              run_id: "",
            };

            const { user_document_id, sas_url } = await uploadDocument(file, uploadRequest);
            setUploadedDocuments(prev => [...prev, { _id: user_document_id, filename: file.name, blob_url: sas_url } as UserDocument]);
          } catch (error) {
            console.error(`Failed to upload file ${file.name}:`, error);
            toast.error(`Failed to upload file ${file.name}`);
          }
        }

        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      } catch (error) {
        console.error(`Failed to upload file:`, error);
        toast.error(`Failed to upload file`);
      } finally {
        setIsUploading(false)
      }
    }
  }

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFiles = event.target.files
    if (newFiles) {
      await processFiles(newFiles);
    }
  }

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragEnter = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();

    dragCounter.current += 1;

    if (!isDraggingOver) {
      setIsDraggingOver(true);
    }
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();

    dragCounter.current -= 1;

    if (dragCounter.current < 0) {
      dragCounter.current = 0;
    }

    if (dragCounter.current === 0) {
      setIsDraggingOver(false);
    }
  };

  const handleDrop = async (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();

    dragCounter.current = 0;
    setIsDraggingOver(false);

    const files = e.dataTransfer.files;
    if (files) {
      await processFiles(files);
    }
  };

  const removeFile = async (documentId: string) => {
    try {
      await deleteDocument(documentId);
      setUploadedDocuments((prevDocs) => prevDocs.filter((doc) => doc._id !== documentId));

      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    } catch (error) {
      console.error(`Failed to delete document:`, error);
      toast.error(`Failed to delete document`);
    }
  }

  const handleSend = async () => {
    if (uploadedDocuments.length === 0) return

    setIsSending(true)
    try {
      await onSend("", uploadedDocuments.map(doc => doc._id))
      setUploadedDocuments([])
      onClose()
    } catch (error) {
      console.error('Failed to send:', error)
      toast.error('Failed to send message')
    } finally {
      setIsSending(false)
    }
  }

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent
        className={cn(
          "sm:max-w-lg w-[95vw] flex flex-col overflow-hidden p-0 rounded-lg",
          uploadedDocuments.length > 0 ? "max-h-[90vh] min-h-[40vh]" : "max-h-fit"
        )}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <DialogHeader className="flex-shrink-0 sticky top-0 bg-white z-10">
          <DialogTitle className="text-lg sm:text-xl font-semibold p-3 sm:p-4 pb-2 sm:pb-3">Upload Documents</DialogTitle>
        </DialogHeader>

        {uploadedDocuments.length === 0 && (
          <div className="flex-shrink-0 -my-2 sm:-my-4">
            <div
              className="border-2 border-dashed border-gray-300 rounded-md p-4 sm:p-8 mx-2 sm:mx-4 my-4 sm:my-8 text-center cursor-pointer flex flex-col items-center justify-center bg-gray-50"
              onClick={() => fileInputRef.current?.click()}
            >
              <CloudUpload className="h-8 w-8 sm:h-10 sm:w-10 text-gray-400 mb-2 sm:mb-3" />
              <p className="text-sm sm:text-md font-bold text-gray-500">
                Drag files here
              </p>
              <p className="text-xs sm:text-sm text-gray-500 mt-1">
                Click here or use the Add attachments button to upload
              </p>
            </div>
          </div>
        )}

        {uploadedDocuments.length > 0 && (
          <div className="flex-1 overflow-hidden">
            <ScrollArea className="h-[25vh] sm:h-[35vh] rounded-md" type="always">
              <div className="px-2 sm:px-4 py-2 space-y-0">
                {uploadedDocuments.map((document) => (
                  <div
                    key={document._id}
                    className="flex items-center justify-between py-1 sm:py-2 px-2 hover:bg-gray-50 rounded-md"
                  >
                    <div className="flex items-center space-x-2 sm:space-x-3">
                      <div className="w-7 h-7 sm:w-8 sm:h-8 rounded-md bg-purple-50 flex items-center justify-center">
                        <FileText className="w-3 h-3 sm:w-4 sm:h-4 text-purple-600" />
                      </div>
                      <span className="text-xs sm:text-sm text-gray-900 truncate max-w-[180px] sm:max-w-[300px]">
                        {document.filename}
                      </span>
                    </div>
                    <button
                      onClick={() => removeFile(document._id)}
                      className="h-7 w-7 sm:h-8 sm:w-8 rounded-md group ml-2 text-gray-500 hover:text-gray-900"
                    >
                      <X className="h-4 w-4" />
                    </button>
                  </div>
                ))}
              </div>
            </ScrollArea>
          </div>
        )}

        <input
          type="file"
          ref={fileInputRef}
          className="hidden"
          onChange={handleFileUpload}
          multiple
          accept=".jpg,.jpeg,.png,.msg,.eml,.pdf,.xlsx,.docx,.zip"
        />

        <DialogFooter className="flex-shrink-0 bg-white p-2 sm:p-3 sticky bottom-0 z-10">
          <div className="flex justify-between items-center w-full">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  variant="outline"
                  size="sm"
                  className={cn(
                    "flex-shrink-0 relative",
                    "disabled:opacity-100",
                    "disabled:cursor-not-allowed"
                  )}
                  disabled={isUploading || isSharePointLoading}
                >
                  {isUploading ? (
                    <>
                      <span className="h-4 w-4 mr-2 animate-spin rounded-full border-2 border-gray-300 border-t-blue-600" />
                      <span className="text-gray-900">Uploading...</span>
                    </>
                  ) : (
                    <>
                      <PlusCircle className="h-4 w-4 mr-2" />
                      Add attachments
                    </>
                  )}
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="start" className="w-[240px] p-1 bg-white">
                <DropdownMenuItem onClick={() => fileInputRef.current?.click()}>
                  <div className="flex items-center">
                    <CloudUpload className="h-4 w-4 mr-2" />
                    <span>Upload from computer</span>
                  </div>
                </DropdownMenuItem>
                <DropdownMenuSeparator />
                <DropdownMenuItem
                  onSelect={selectAndUploadFile}
                  disabled={isSharePointLoading || isUploading}
                >
                  <div className="flex items-center">
                    <img src={sharepointimg} alt="SharePoint" className="h-4 w-4 mr-2" />
                    <span>
                      {isSharePointLoading
                        ? 'Uploading...'
                        : 'Upload from SharePoint'
                      }
                    </span>
                  </div>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>

            <Button
              onClick={handleSend}
              disabled={isSending || uploadedDocuments.length === 0 || isUploading}
              size="icon"
              className="rounded-full h-10 w-10 flex-shrink-0"
            >
              <ArrowRight className="h-4 w-4" />
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
