import React, { useMemo, useCallback, useEffect, useState } from 'react';
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { highlightPlugin, Trigger } from '@react-pdf-viewer/highlight';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/highlight/lib/styles/index.css';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import { CitationWithState } from '../../../services/api';
import { ChevronLeft, ChevronRight } from 'lucide-react';

export interface PDFPreviewProps {
  pdfUrl: string;
  onClear: () => void;
  citations?: CitationWithState[] | null;
}

interface HighlightArea {
  pageIndex: number;
  left: number;
  top: number;
  width: number;
  height: number;
  color: string;
}

export const PDFPreview: React.FC<PDFPreviewProps> = ({
  pdfUrl,
  onClear,
  citations,
}) => {
  const [currentHighlightIndex, setCurrentHighlightIndex] = useState(0);

  const highlights: HighlightArea[] = useMemo(() => {
    if (!citations || !Array.isArray(citations)) return [];
    return citations.map((citationWithState) => ({
      pageIndex: citationWithState.citation.bbox.page - 1,
      left: citationWithState.citation.bbox.left * 100,
      top: citationWithState.citation.bbox.top * 100,
      width: citationWithState.citation.bbox.width * 100,
      height: citationWithState.citation.bbox.height * 100,
      color:
        citationWithState.state === 'match'
          ? 'rgba(0, 255, 0, 0.3)'
          : 'rgba(255, 0, 0, 0.3)',
    }));
  }, [citations]);

  const sortedHighlights = useMemo(
    () =>
      [...highlights].sort((a, b) => a.pageIndex - b.pageIndex || a.top - b.top),
    [highlights]
  );

  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { jumpToPage } = pageNavigationPluginInstance;

  const renderHighlights = useCallback(
    (props: { pageIndex: number }) => {
      const pageHighlights = sortedHighlights.filter(
        (area) => area.pageIndex === props.pageIndex
      );
      return (
        <>
          {pageHighlights.map((area, idx) => (
            <div
              key={idx}
              style={{
                position: 'absolute',
                left: `${area.left}%`,
                top: `${area.top}%`,
                width: `${area.width}%`,
                height: `${area.height}%`,
                background: area.color,
                pointerEvents: 'none',
                border: '2px solid transparent',
              }}
            />
          ))}
        </>
      );
    },
    [sortedHighlights]
  );

  const highlightPluginInstance = highlightPlugin({
    renderHighlights,
    trigger: Trigger.None,
  });

  const handleDocumentLoad = useCallback(() => {
    if (sortedHighlights.length > 0) {
      jumpToPage(sortedHighlights[0].pageIndex);
    }
  }, [sortedHighlights, jumpToPage]);

  useEffect(() => {
    if (citations && citations.length > 0) {
      jumpToPage(sortedHighlights[0].pageIndex);
      setCurrentHighlightIndex(0);
    }
  }, [citations, sortedHighlights, jumpToPage]);

  const navigateHighlight = useCallback(
    (direction: 'next' | 'prev') => {
      if (sortedHighlights.length === 0) return;
      const newIndex =
        direction === 'next'
          ? (currentHighlightIndex + 1) % sortedHighlights.length
          : (currentHighlightIndex - 1 + sortedHighlights.length) % sortedHighlights.length;

      setCurrentHighlightIndex(newIndex);
      jumpToPage(sortedHighlights[newIndex].pageIndex);
    },
    [currentHighlightIndex, sortedHighlights, jumpToPage]
  );

  return (
    <div className="relative h-full bg-white rounded-lg">
      {/* keep it optional for now */}
      <button
        onClick={onClear}
        className="absolute top-3 right-3 z-10 p-1 bg-black/10 rounded-full"
        aria-label="Remove PDF"
      >
      </button>

      {sortedHighlights.length > 0 && (
        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex items-center space-x-2 bg-black/30 backdrop-blur-sm rounded-full px-4 py-2">
          <button
            onClick={() => navigateHighlight('prev')}
            className="text-white hover:text-gray-300 focus:outline-none transition-colors"
            aria-label="Previous highlight"
          >
            <ChevronLeft className="h-5 w-5" />
            <span className="sr-only">Previous</span>
          </button>
          <div className="text-sm text-white">
            Highlight {sortedHighlights.length > 0 ? currentHighlightIndex + 1 : 0} of {sortedHighlights.length}
          </div>
          <button
            onClick={() => navigateHighlight('next')}
            className="text-white hover:text-gray-300 focus:outline-none transition-colors"
            aria-label="Next highlight"
          >
            <ChevronRight className="h-5 w-5" />
            <span className="sr-only">Next</span>
          </button>
        </div>
      )}

      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
        <div className="h-full w-full relative overflow-auto">
          <Viewer
            fileUrl={pdfUrl}
            defaultScale={SpecialZoomLevel.PageWidth}
            plugins={[highlightPluginInstance, pageNavigationPluginInstance]}
            onDocumentLoad={handleDocumentLoad}
          />
        </div>
      </Worker>
    </div>
  );
};
